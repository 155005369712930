import axios, { AxiosResponse } from 'axios';
import { appInsights, logger } from 'services/logging/appInsights';
import { AuthResponse, ResetResponse } from 'types/AuthResponse';
import { http } from '../BaseApi';
import config from '../requestConfig';
import { decodeToken } from 'utils/tokenHelper';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

const login = async ({ emailAddress, password }) => {
  return http
    .post<any>('/auth/v2/login', { emailAddress, password })
    .then(response => ({ response }))
    .catch(error => logger.error(error, 'login', 'authenticationService.ts'));
};

const session = async ({ emailAddress, password }): Promise<any> => {
  return await http.post<any>('/auth/session', { emailAddress, password });
};

const sessionFlags = async (userid, userType): Promise<any> => {
  return await await http.post<any>(
    `/auth/${userid}/session-flags`,
    undefined,
    {
      headers: {
        'user-id': userid,
        'x-userType': userType,
      },
    },
  );
};

const oneLoginSession = async ({ url, sessionToken }): Promise<any> => {
  try {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    let method = 'POST';
    xhr.open(method, url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    let body = { session_token: sessionToken };
    let res = xhr.send(JSON.stringify(body));
    return res;
  } catch (ex) {
    logger.error(ex, 'oneLoginSession', 'authenticationService.ts');
    return null;
  }
};

const logout = async (token: string): Promise<AuthResponse> => {
  let res: AxiosResponse<AuthResponse>;
  config.method = 'POST';
  config.url = `${baseUrl}auth/logout`;
  config.headers!.authorization = `Bearer ${token}`;
  res = await axios(config);
  appInsights.clearAuthenticatedUserContext();
  return res.data;
};

const versionCheck = async ({
  version = '0.1.0',
}: {
  version: string;
}): Promise<any> => {
  return await http.get<any>(`/auth/version-check/${version}`);
};

const sendPasswordResetCode = async ({
  emailAddress,
}): Promise<ResetResponse> => {
  return await http.post<any>('auth/password/sendcode', { emailAddress });
};

const passwordReset = async ({
  emailAddress,
  newPassword,
  newPasswordConfirmation,
  code,
}): Promise<ResetResponse> => {
  return await http.put<any>('auth/password/reset', {
    emailAddress,
    newPassword,
    newPasswordConfirmation,
    code,
  });
};

const passwordUpdate = async ({
  emailAddress,
  newPassword,
  newPasswordConfirmation,
  currentPassword,
}): Promise<ResetResponse> => {
  return await http.put<any>('auth/password/change', {
    emailAddress,
    newPassword,
    newPasswordConfirmation,
    currentPassword,
  });
};

const refreshAccessToken = async ({
  refreshToken,
  correlationId,
}: {
  refreshToken: string;
  correlationId: string;
}): Promise<{
  accessToken: string;
  refreshToken: string;
  idToken: string;
  correlationId: string;
  featureToggles: [];
  featureFlags: [];
}> => {
  return await http.post<any>('/auth/v2/refresh', undefined, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
      'x-correlationId': correlationId ?? '',
      'x-source': 'PassportWeb',
    },
    additional: {
      useRefreshToken: true,
    },
  });
};

const refreshAccessTokenSSO = async ({
  refreshToken,
  correlationId,
}: {
  refreshToken: string;
  correlationId: string;
}): Promise<{ data: AuthResponse }> => {
  const params = new URLSearchParams();
  params.append('refresh_token', refreshToken);
  params.append('client_id', `${process.env.REACT_APP_SSO_CLIENT_ID}`);
  params.append('grant_type', 'refresh_token');

  const res = await axios.post(
    `${process.env.REACT_APP_SSO_BASE_URL}oidc/2/token`,
    params,
  );

  const idToken = decodeToken(res.data.id_token);

  const sessionRes = await sessionFlags(
    idToken.params.PassportMobileAppUserId,
    idToken.params.PassportMobileAppUserType,
  );

  return {
    data: {
      accessToken: res.data.access_token,
      refreshToken: res.data.refresh_token,
      idToken: res.data.id_token,
      correlationId: correlationId,
      featureToggles: sessionRes.featureToggles,
      featureFlags: sessionRes.featureFlags,
      isSSO: true,
    },
  };
};

const validatePasswordReset = async ({
  emailAddress,
  code,
}): Promise<ResetResponse> => {
  return await http.put<any>('auth/password/validatecode', {
    emailAddress,
    code,
  });
};

export {
  login,
  logout,
  session,
  sessionFlags,
  oneLoginSession,
  versionCheck,
  sendPasswordResetCode,
  passwordReset,
  refreshAccessToken,
  refreshAccessTokenSSO,
  validatePasswordReset,
  passwordUpdate,
};
