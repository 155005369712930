import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { TOAST_MESSAGE } from 'constants/helperText';
import { EmployeeRoleId } from 'enums/employeeRoleId';
import { httpSuccess } from 'helpers/serviceHelper';
import { IActionPayloadType } from 'interfaces/Common/IActionPayloadType';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IContactRatingDetails } from 'interfaces/Ratings/IContactRatingDetails';
import { IContactRatingSaveRequest } from 'interfaces/Ratings/IContactRatingSaveRequest';
import { IRatingRequest } from 'interfaces/Ratings/IRatingRequest';
import { IContact } from 'interfaces/User/Contact/IContact';
import { IPlacementContact } from 'interfaces/User/Contact/IPlacementContact';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getUserContacts } from 'services/contacts/contactService';
import { logger } from 'services/logging/appInsights';
import {
  fetchContactRatings,
  postContactRatings,
} from 'services/ratings/ratingsService';

import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { setError } from 'store/slices/error/errorSlice';
import { contactActions } from 'store/slices/user/userContact/userContactSlice';
import { IContactResponse } from 'types/ContactResponse';

const trackedUserContacts = (fn, ...args) =>
  trackPromise(fn(...args), 'get-user-contacts');
const trackedSaveContactRatings = (fn, ...args) =>
  trackPromise(fn(...args), 'save-user-contacts-ratings');

export const userIdSelector = (state: RootState) => state.auth.userId;

function* getRecruiter(action: IActionPayloadType) {
  const userId = action.payload as string;
  try {
    const recruiterData = yield call(() => getUserContacts(userId));
    const { data } = recruiterData;
    const result = data?.userContacts.find(
      item => item.employeeRoleId === EmployeeRoleId.RECRUITER,
    );
    yield put(contactActions.setContactRecruiter(result));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getRecruiter', 'userContactSaga.ts');
  }
}

function* getContacts(action: any) {
  try {
    let userContactData: IContactResponse = {
      userContacts: [],
      placementContacts: [],
      strikeContacts: [],
    };
    const { data }: AxiosResponse<IContactResponse> = yield call(
      trackedUserContacts,
      getUserContacts,
      action.payload,
    );

    userContactData.placementContacts = yield getContactRatings(
      data.placementContacts,
    );
    userContactData.userContacts = yield getContactRatings(data.userContacts);
    userContactData.strikeContacts = data.strikeContacts;
    yield put(contactActions.setUserContacts(userContactData));
  } catch (e) {
    logger.error(e, 'getContacts', 'userContactSaga.ts');
  }
}

function* getContactRatings(contactArray: IContact[] | IPlacementContact[]) {
  const userId = yield select(userIdSelector);
  let contacts = contactArray.slice();
  try {
    for (const contact of contacts) {
      if (contact.employeeId) {
        let res = yield fetchContactRatings(
          userId,
          contact.employeeId.toString(),
        );
        contact.ratingsData = res.data[0];
      }
    }
    return contacts;
  } catch (e) {
    logger.error(e, 'getContactRatings', 'userContactSaga.ts');
  }
}

function* saveContactRatings(action: PayloadAction<IContactRatingDetails>) {
  const userId = yield select(userIdSelector);
  let requestRatings = action.payload.currentRatings.map(r => {
    let reqRating: IRatingRequest = {
      ratingTypeId: r.ratingTypeId,
      ratingAttributeTypeId: r.ratingAttributeTypeId,
      value: r.value,
    };
    return reqRating;
  });
  let request: IContactRatingSaveRequest = {
    currentRatings: requestRatings,
    isRecommended: action.payload.isRecommended,
    comments: action.payload.comments,
    employeeId: action.payload.employeeId,
  };
  try {
    let res = yield call(
      trackedSaveContactRatings,
      postContactRatings,
      userId,
      request,
    );
    if (res && httpSuccess(res?.status)) {
      yield put(contactActions.updateContactRatings(action.payload));
      yield put(contactActions.setUpdateContactSuccess(true));
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
    }
  } catch (e) {
    logger.error(e, 'saveContactRatings', 'userContactSaga.ts');
  }
}

/**
 *  Watcher functions
 */
function* userContactWatcher() {
  yield takeLatest(contactActions.getContactRecruiter.type, getRecruiter);
}

function* getUserContactsWatcher() {
  yield takeLatest(contactActions.fetchUserContacts.type, getContacts);
}

function* postContactRatingsWatcher() {
  yield takeLatest(
    contactActions.postContactRatingsAction.type,
    saveContactRatings,
  );
}

export function* userContactSaga() {
  yield all([
    userContactWatcher(),
    getUserContactsWatcher(),
    postContactRatingsWatcher(),
  ]);
}
