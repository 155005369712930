const mapMenuIconSection = (section: string): string => {
  const sectionsDictionary = {
    Contacts: '/contacts',
    Home: '/home',
    Jobs: '/jobs',
    Assignments: '/assignments',
    'Credential Center': '/credentials',
    'Task Center': '/task-center',
    Credentials: '/credentials',
    'Skills Checklists': '/skills-checklists',
    'Privacy Policy / Terms of Use': '/privacy-policy-terms-of-use',
    // {/* Hiding for Minimum Viable Product requirements for US 31532*/}
    // Feedback: '/feedback',
    'Help Center': '/help-center',
    'Reimbursement Request': '/reimbursements',
    'Time Entry': '/time-entry',
    Payroll: '/payroll',
    'Pay Statements': '/pay-statements',
    Resources: '/resources',
    Preferences: '/preferences',
    'Share & Refer': '/refer-a-clinician',
    Notifications: '/notifications',
    Menu: '/menu',
    'AMN Badge': '/amn-badge',
    'Your Profile': '/profile',
    'Housing & Travel': '/housing-&-travel',
    'Rewards & Recognition': '/rewards-&-recognition',
    'Education, Training, & Community': '/education-training-&-community',
    Cases: '/cases',
    Community: '/community',
    'Hear From Your Community': '/hear-from-your-community',
    Promotions: '/promotions',
    'Content Not Found': '/content-not-found',
  };
  return sectionsDictionary[section];
};

export default mapMenuIconSection;
