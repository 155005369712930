import { Grid, SxProps } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';
import { createDetailFeatureStyles } from './PageLayout.styles';

export const DetailFeaturePage = ({
  featureContent,
  subHeaderBarContent,
  styles,
}: {
  featureContent?: any;
  subHeaderBarContent?: any;
  styles?: {
    detailContainer?: { xs?: number; md?: number; lg?: number };
    sx?: SxProps;
  };
}) => {
  const classes = createDetailFeatureStyles();
  return (
    <>
      <Grid container id="detail-feature-page">
        {subHeaderBarContent && (
          <Grid
            container
            spacing={3}
            className={classes?.subHeaderBar}
            sx={{
              borderBottom: `1px solid ${theme.palette.system.border}`,
              height: '60px',
              backgroundColor: 'system.white',
            }}
          >
            {subHeaderBarContent}
          </Grid>
        )}
        <Grid container item className={classes?.detailFeatureContainer}>
          <Grid
            item
            xs={styles?.detailContainer?.xs ?? 12}
            md={styles?.detailContainer?.md ?? 8}
            lg={styles?.detailContainer?.lg ?? 9}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              ...styles?.sx,
            }}
          >
            {featureContent}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
