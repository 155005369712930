import { PayloadAction } from '@reduxjs/toolkit';
import { TOAST_MESSAGE } from 'constants/helperText';
import { StatusCode } from 'enums/StatusCode';
import { JobSortType } from 'enums/jobSortType';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IJobRequest } from 'interfaces/Jobs/IJobsRequest';
import { trackPromise } from 'react-promise-tracker';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { jobSearchPageResultTransformer } from 'services/jobs/jobSearchResponseTransformer';
import * as service from 'services/jobs/jobService';
import { logger, trackEvent } from 'services/logging/appInsights';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { setError } from 'store/slices/error/errorSlice';
import {
  deleteFavorite,
  fetchJobs,
  fetchStrikeJobs,
  postFavorite,
  resetJobs,
  setDeleteFavorite,
  setHasOnlyRecommended,
  setHasRecommended,
  setJobSearchFilter,
  setJobs,
  setPostFavorite,
  setSelectedJob,
} from 'store/slices/jobs/jobsSlice';
import { IJobState } from 'store/slices/jobs/jobsState';
import { getJobDetailSaga } from './jobDetailSaga';

const getJobFilter = (state: RootState) => state.jobs.jobSearchFilter;
const userId = (state: RootState) => state.auth.userId;
const hasRecommended = (state: RootState) => state.jobs.hasRecommended;

const trackedJobsData = (fn, ...args) => trackPromise(fn(...args), 'get-jobs');

export function* fetchJobsSaga(
  action: PayloadAction<
    { request: IJobRequest; resetSelected?: boolean } | undefined
  >,
) {
  let currentFilter = yield select(getJobFilter);
  let currentUserId = yield select(userId);
  let hasRecommendedValue = yield select(hasRecommended);

  const emptyResponse: IJobState = {
    items: [],
    paging: {
      total: '0',
      offset: currentFilter.offset,
      limit: currentFilter.limit,
    },
    hasRecommended: false,
    hasOnlyRecommended: false,
  };

  if (!currentUserId) return;

  const jobSearchRequest: IJobRequest = {
    limit: action.payload?.request.limit ?? currentFilter.limit,
    sort: currentFilter.sort,
    userId: currentUserId,
    isFavorite: currentFilter.isFavorite,
    ...action.payload?.request,
  };

  try {
    const response = yield call(
      trackedJobsData,
      service.fetchJobs,
      jobSearchRequest,
    );

    // Trigger anytime we get jobs on the jobs page
    trackEvent('JobSearchExecuted');

    const { data, status } = response;
    switch (status) {
      case StatusCode.NoContent:
        if (currentFilter.isFavorite) {
          yield put(setJobs(emptyResponse));
          yield put(setSelectedJob(undefined));
        } else {
          if (currentFilter.sort !== JobSortType.Recommended) {
            if (hasRecommendedValue === true) {
              yield put(setHasOnlyRecommended(true));
              yield put(
                setJobSearchFilter({
                  ...currentFilter,
                  limit: currentFilter.limit,
                  sort: JobSortType.Recommended,
                }),
              );
              yield put(
                fetchJobs({
                  request: {
                    userId: jobSearchRequest.userId,
                    limit: jobSearchRequest.limit,
                    sort: JobSortType.Recommended,
                  },
                }),
              );
            } else {
              yield put(setHasOnlyRecommended(false));
            }
            yield put(resetJobs());
          } else {
            yield put(setHasRecommended(false));
            yield put(setHasOnlyRecommended(false));
            yield put(
              setJobSearchFilter({
                ...currentFilter,
                limit: currentFilter.limit,
                sort: JobSortType.WeeklyPay,
              }),
            );
            yield put(
              fetchJobs({
                request: {
                  userId: jobSearchRequest.userId,
                  limit: jobSearchRequest.limit,
                  sort: JobSortType.WeeklyPay,
                },
              }),
            );
          }
        }
        break;
      case StatusCode.OK:
        if (currentFilter.sort === JobSortType.Recommended) {
          if (hasRecommendedValue === false) {
            yield put(setHasRecommended(true));
            if (window.location.pathname === '/jobs') {
              yield put(
                setJobSearchFilter({
                  ...currentFilter,
                  limit: currentFilter.limit,
                  sort: JobSortType.Recommended,
                }),
              );
              yield put(
                fetchJobs({
                  request: {
                    userId: jobSearchRequest.userId,
                    limit: jobSearchRequest.limit,
                    sort: JobSortType.Recommended,
                  },
                }),
              );
            }
          }
        }
        let jobs = data.items;
        for (const item of jobs) {
          // coordinates are sent as long, lat instead of expected lat, long
          item.address.location.coordinates =
            item?.address?.location?.coordinates.reverse();
        }
        data.items = jobSearchPageResultTransformer(jobs);
        yield put(setJobs({ ...data }));
        if (action.payload?.resetSelected) {
          yield put(setSelectedJob(data.items[0].jobId));
        }
        yield put(
          dataLayerActions.dataLayerJobSearchAction(
            data?.paging?.total.toString() || '0',
          ),
        );
        break;
      default:
        yield put(setJobs(emptyResponse));
    }
  } catch (error: any) {
    let err = error.data as IErrorResponse;
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(error, 'fetchJobsSaga', 'jobSaga.ts');
  }
}

export function* fetchStrikeJobsSaga(
  action: PayloadAction<string | undefined>,
) {
  let currentFilter = yield select(getJobFilter);

  const emptyResponse: IJobState = {
    items: [],
    paging: {
      total: '0',
      offset: currentFilter.offset, // should we get from filter?
      limit: currentFilter.limit, // should we get from filter?
    },
    hasRecommended: false,
    hasOnlyRecommended: false,
  };

  let currentUserId = yield select(userId);

  if (!currentUserId) return;

  // Trigger anytime we get jobs on the jobs page
  trackEvent('JobSearchExecuted', { isStrikeJobs: true });

  try {
    const response = yield call(
      trackedJobsData,
      service.fetchStrikeJobs,
      currentUserId,
    );

    const { data, status } = response;

    switch (status) {
      case StatusCode.NoContent:
        yield put(resetJobs());
        break;
      case StatusCode.OK:
        yield put(setJobs({ ...data }));
        if (data.items.length > 0) {
          yield put(setSelectedJob(data.items[0].jobId));
        }
        yield put(
          dataLayerActions.dataLayerJobSearchAction(
            data?.paging?.total.toString() || '0',
          ),
        );
        break;
      default:
        yield put(setJobs(emptyResponse));
    }
  } catch (error: any) {
    // yield put(setJobs(emptyResponse));
    // alert doesn't seem to be showing up
    let err = error.data as IErrorResponse;
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(error, 'fetchStrikeJobsSaga', 'jobSaga.ts');
  }
}

export function* postFavoriteSaga(action) {
  const postFavouriteRequest = action.payload;
  try {
    yield call(service.postFavorite, postFavouriteRequest);
    yield put(setPostFavorite(postFavouriteRequest.jobId));
    const details: PayloadAction<number | undefined> = {
      payload: postFavouriteRequest.jobId,
      type: '',
    };
    yield call(getJobDetailSaga, details);
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'postFavoriteSaga', 'jobSaga.ts');
  }
}

export function* deleteFavoriteSaga(action) {
  const deleteFavouriteRequest = action.payload;
  try {
    yield call(service.deleteFavorite, deleteFavouriteRequest);
    yield put(setDeleteFavorite(deleteFavouriteRequest.jobId));
    const details: PayloadAction<number | undefined> = {
      payload: deleteFavouriteRequest.jobId,
      type: '',
    };
    yield call(getJobDetailSaga, details);
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'deleteFavoriteSaga', 'jobSaga.ts');
  }
}

export function* jobSaga() {
  yield all([
    takeEvery(fetchJobs.type, fetchJobsSaga),
    takeLatest(postFavorite.type, postFavoriteSaga),
    takeLatest(deleteFavorite.type, deleteFavoriteSaga),
    takeLatest(fetchStrikeJobs.type, fetchStrikeJobsSaga),
  ]);
}
