import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  generateUserProfilePdf,
  setUserProfilePdf,
} from 'store/slices/user/userProfile/userProfilePdfSlice';
import * as service from 'services/user/userService';
import { setError } from 'store/slices/error/errorSlice';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { logger } from 'services/logging/appInsights';

// Worker Sagas
function* getUserProfilePdfSaga(action) {
  try {
    const response = yield call(
      service.generateUserProfilePdf,
      action.payload.userProfilePdfRequest,
      action.payload.userId,
    );
    const { data } = response;
    const pdfRequest = { ...action.payload, userProfilePdfResponse: data };

    yield put(setUserProfilePdf(pdfRequest));
  } catch (error) {
    yield put(setError(error as IErrorResponse));
    logger.error(error, 'getUserProfilePdfSaga', 'userProfilePdfSaga.ts');
  }
}

// Watcher Sagas
function* watchGetUserProfilePdf() {
  yield takeLatest(generateUserProfilePdf.type, getUserProfilePdfSaga);
}

export function* userProfilePdfSaga() {
  yield all([watchGetUserProfilePdf()]);
}
