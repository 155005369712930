import { TOAST_MESSAGE } from 'constants/helperText';
import { httpSuccess } from 'helpers/serviceHelper';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchAssignments } from 'services/assignments/assignmentService';
import { logger } from 'services/logging/appInsights';
import {
  getExistingTicketsCountService,
  getExistingTicketsService,
  patchCloseSupportTicketService,
  postSupportTicketService,
} from 'services/supportCenter/supportCenterService';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { setError } from 'store/slices/error/errorSlice';
import { supportCenterActions } from 'store/slices/supportCenter/supportCenterSlice';

const trackedExistingTickesGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-existing-ticket');

const trackedSupportCenterSubmit = (fn, ...args) =>
  trackPromise(fn(...args), 'submit-support-ticket');

const trackedSupportCenterClose = (fn, ...args) =>
  trackPromise(fn(...args), 'close-support-ticket');

const userId = (state: RootState) => state.auth.userId;

function* getExistingTicketsCount() {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(() =>
      getExistingTicketsCountService(currentUserId),
    );
    const { data } = response;

    if (response && httpSuccess(response?.status) && data) {
      yield put(supportCenterActions.setExistingTicketsCount(data.count));
    }
  } catch (error: any) {
    logger.error(error, 'getExistingTicketsCount', 'supportCenterSaga.ts');
  }
}

function* getExistingTickets(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedExistingTickesGet,
      getExistingTicketsService,
      {
        userId: currentUserId,
        limit: action.payload?.limit,
        offset: action.payload?.offset,
        status: action.payload?.status,
      },
    );
    const { data } = response;

    if (response && httpSuccess(response?.status) && data) {
      yield put(supportCenterActions.setExistingTickets(data));
    }
  } catch (error: any) {
    logger.error(error, 'getExistingTickets', 'supportCenterSaga.ts');
  }
}

function* postSubmitSupportTicket(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedSupportCenterSubmit,
      postSupportTicketService,
      {
        userId: currentUserId,
        ticketDescription: action.payload?.ticketDescription,
        ticketTypeId: action.payload?.ticketTypeId,
        placementId: action.payload?.placementId,
      },
    );
    const { data } = response;
    if (response) {
      if (httpSuccess(response?.status) && data) {
        yield put(supportCenterActions.setSubmitTicketStatus(true));
      } else if (!httpSuccess(response?.status)) {
        yield put(
          setError({
            errorCode: response.statusCode,
            errorData: {},
            errorDetails: '',
            errorMessage: TOAST_MESSAGE.SomethingWentWrongTryReloading,
            operationId: '',
            correlationId: '',
          }),
        );
      }
    }
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(error, 'postSubmitSupportTicket', 'supportCenterSaga.ts');
  }
}

function* patchCloseOrResolveTicket(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedSupportCenterClose,
      patchCloseSupportTicketService,
      {
        userId: currentUserId,
        caseNumber: action.payload?.cancelRequest?.caseNumber,
      },
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(
        supportCenterActions.setCloseOrResolveTicketStatus(data?.success),
      );
    }
  } catch (error: any) {
    logger.error(error, 'patchCloseOrResolveTicket', 'supportCenterSaga.ts');
  }
}

/**
 *  Watcher function to get assignments detail by user id and status
 *
 */
function* getAssignmentByStatusSaga(action) {
  let currentUserId = yield select(userId);
  try {
    const assignmentData = yield call(
      p => fetchAssignments(currentUserId, p.status),
      action.payload,
    );

    let { data } = assignmentData;
    data = data !== '' ? data.items : [];
    yield put(supportCenterActions.setAssignments(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getAssignmentByStatusSaga', 'supportCenterSaga.ts');
  }
}

export function* supportCenterSaga() {
  yield all([
    takeLatest(
      supportCenterActions.getExistingTicketsCount.type,
      getExistingTicketsCount,
    ),
    takeLatest(
      supportCenterActions.getExistingTickets.type,
      getExistingTickets,
    ),
    takeLatest(
      supportCenterActions.postSubmitSupportTicket.type,
      postSubmitSupportTicket,
    ),
    takeLatest(
      supportCenterActions.patchCloseOrResolveTicket.type,
      patchCloseOrResolveTicket,
    ),
    takeLatest(
      supportCenterActions.getAssignmentByStatus.type,
      getAssignmentByStatusSaga,
    ),
  ]);
}
