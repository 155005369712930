export enum MenuIcons {
  Contacts = 'Contacts',
  Home = 'Home',
  Jobs = 'Jobs',
  RecommendedJobs = 'Job Matches For You',
  Assignments = 'Assignments',
  Credentials = 'Credential Center',
  TaskCenter = 'Task Center',
  SkillsChecklist = 'Skills Checklists',
  PrivacyPolicy = 'Privacy Policy / Terms of Use',
  Edit = 'Edit',
  // {/* Hiding for Minimum Viable Product requirements for US 31532*/}
  // Feedback = 'Feedback',
  Faq = 'Help Center',
  FaqNav = 'Help Center',
  Reimbursements = 'Reimbursement Request',
  ReimbursementsNav = 'Reimbursements',
  Expenses = 'Expenses',
  TimeEntry = 'Time Entry',
  Pay = 'Paystubs',
  PayStatements = 'Pay Statements',
  Resources = 'Resources',
  Preferences = 'Preferences',
  ShareAndRefer = 'Share & Refer',
  Notifications = 'Notifications',
  Menu = 'Menu',
  Profile = 'Your Profile',
  AMNBadge = 'AMN Badge',
  Signout = 'Sign Out',
  HousingTravel = 'Housing & Travel',
  RewardsRecognition = 'Rewards & Recognition',
  EducationTrainingCommunity = 'Education, Training, & Community',
  Cases = 'Cases',
  Community = 'Community',
  HearFromClinician = 'Hear From Your Community',
  Promotions = 'Promotions',
  ContentNotFound = 'Content Not Found',
  StrikeJobs = 'Strike Jobs',
  ScreenShare = 'Screen Share',
}
