import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';

export const fetchAuthImgUrl = async (imgUrl: string): Promise<any> => {
  try {
    return await http.get<string>(`${imgUrl}/url`);
  } catch (error) {
    logger.error(error, 'fetchAuthImgUrl', 'ArbitrationAgreementService.ts');
  }
};

export const signArbitrationAgreement = async ({
  userId,
  actionItemId,
  signature,
}: {
  userId: string;
  actionItemId: string;
  signature: string;
}) => {
  try {
    return await http.put(`v2/arbitration/${userId}/${actionItemId}/sign`, {
      signature,
    });
  } catch (error) {
    logger.error(
      error,
      'signArbitrationAgreement',
      'ArbitrationAgreementService.ts',
    );
    return error;
  }
};
