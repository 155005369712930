import { Box, Divider, Grid, Typography } from '@mui/material';
import { ArrowRightIcon } from 'assets/icons/Arrows/Arrow-Right';
import EditIcon from 'assets/icons/Edit/EditIcon';
import { AMNSnackbar } from 'components/common/Alert/AMNSnackbar';
import { AppButton } from 'components/common/Button/Button';
import CheckBox from 'components/common/CheckBox/CheckBox';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { ContractStatusIdStates, ContractTypeIds } from 'enums/contracts';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { guidGenerator } from 'helpers/guidGenerator';
import {
  IContractPanelProps,
  IContractsPanelType,
} from 'interfaces/Contracts/Contract';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { logger } from 'services/logging/appInsights';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { contractSliceActions } from 'store/slices/contracts/contractSlice';
import { theme } from 'styles/theme';
import useTrackPageView from 'utils/hooks/useTrackPageView';

const ContractsPanel = React.forwardRef<
  IContractsPanelType,
  IContractPanelProps
>((props, ref) => {
  const dispatch = useAppDispatch();
  const isMobileDevice = useMobileMediaQuery();

  const { contracts, facilityName, openReadyToSign, placementId } = props;
  const [isDownloadActive, setIsDownloadActive] = React.useState(false);
  const [downloadList, setDownloadList] = React.useState<string[]>([]);

  // Expose the handleHeaderIconClick method to the parent component
  React.useImperativeHandle(ref, () => ({
    handleHeaderIconClick: handleHeaderIconClick,
  }));

  const handleHeaderIconClick = () => {
    setIsDownloadActive(true);
  };

  const returningFromSingleContract = useAppSelector(
    state => state.contracts.returningFromSingleContract,
  );

  const isContractSaveSuccess = useAppSelector(
    state => state.contracts.isContractSaveSuccess,
  );

  useTrackPageView('ContractsAddendum');

  const AttentionDot = (
    <Box
      sx={{
        ml: 2,
        backgroundColor: 'system.error',
        borderRadius: '50%',
        height: '12px',
        width: '12px',
      }}
    />
  );

  useEffect(() => {
    return () => {
      dispatch(contractSliceActions.setIsContractSaveSuccess(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contracts?.length === 1 && returningFromSingleContract === false) {
      openReadyToSign(contracts[0]);
      dispatch(contractSliceActions.setReturningFromSingleContract(true));
    } else if (
      contracts?.length === 1 &&
      returningFromSingleContract === true &&
      isMobileDevice
    ) {
      dispatch(
        assignmentActions.setDrawerState(DrawerContentNames.ASSIGNMENT_MOBILE),
      );
    }
    dispatch(contractSliceActions.setReturningFromSingleContract(false));
  }, [
    contracts,
    dispatch,
    isMobileDevice,
    openReadyToSign,
    returningFromSingleContract,
  ]);

  const onDownloadCancelClick = e => {
    e.preventDefault();
    setIsDownloadActive(false);
    setDownloadList([]);
  };

  const toggleDownloads = React.useCallback(
    id => {
      if (downloadList.includes(id)) {
        const updatedList = downloadList.filter(item => item !== id);
        setDownloadList(updatedList);
      } else {
        setDownloadList(prevList => [...prevList, id]);
      }
    },
    [downloadList],
  );

  const downloadAsPdf = async () => {
    try {
      let payload = {
        placementId,
        contractIds: downloadList,
        facilityName: facilityName,
      };
      dispatch(contractSliceActions.getContractDocument(payload));
      setDownloadList([]);
      setIsDownloadActive(false);
    } catch (error) {
      logger.error(error, 'downloadAsPdf', 'ContractsPanel.tsx');
    }
  };

  const DrawerButtonFooter = (
    <Box
      position={'absolute'}
      display="flex"
      justifyContent="space-between"
      width={'100%'}
      bottom={0}
      right={0}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      padding={'12px 24px'}
    >
      <AppButton
        id={'contracts-download-cancel-button'}
        onClick={e => onDownloadCancelClick(e)}
        onKeyDown={e => onDownloadCancelClick(e)}
        type="button"
        size="auto"
        variant="action-link"
      >
        {'CANCEL'}
      </AppButton>
      <AppButton
        id={'contracts-download-button'}
        type="button"
        size={'md'}
        variant="primary"
        onKeyDown={downloadAsPdf}
        disabled={!downloadList?.length}
        onClick={downloadAsPdf}
      >
        {'DOWNLOAD'}
      </AppButton>
    </Box>
  );

  return (
    <>
      <AMNSnackbar
        onClose={() =>
          dispatch(contractSliceActions.setIsContractSaveSuccess(false))
        }
        autoHideDuration={3000}
        open={isContractSaveSuccess}
        variant="success"
        message="Contract(s) Downloaded"
        sx={{
          margin: '10px 0px',

          '& .MuiSnackbarContent-root': {
            position: 'absolute',
            maxWidth: 'auto',
            zIndex: 2500,
            width: '98%',
            left: '5px',
          },
        }}
      />
      <Grid container id="contract-drawer" pb={isDownloadActive ? 10 : 0}>
        <Grid item pb={isContractSaveSuccess ? 0 : 4} xs={12}>
          <Typography
            color={isContractSaveSuccess ? 'transparent' : 'system.darkGray'}
            variant="subtitle1"
          >
            {facilityName}
          </Typography>
        </Grid>

        {contracts &&
          contracts?.length > 1 &&
          [...(contracts || [])]
            ?.sort((a, b) => {
              const diff = moment(a.readyForSigningDate).diff(
                b.readyForSigningDate,
              );
              if (diff > 0) return -1;
              if (diff < 0) return 1;
              return 0;
            })
            ?.map((c, i) => (
              <>
                <Fragment key={i}>
                  <Grid container spacing={2} py={2} alignItems={'center'}>
                    {isDownloadActive && (
                      <Grid item xs={1}>
                        <CheckBox
                          id={c?.contractId}
                          disabled={false}
                          checked={downloadList?.includes(c?.contractId)}
                          onClick={e => {
                            e?.stopPropagation?.();
                            toggleDownloads(c?.contractId);
                          }}
                          sx={{
                            label: {
                              marginRight: '0px',
                              width: '20px',
                              height: '20px',
                            },
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      key={guidGenerator()}
                      item
                      xs={isDownloadActive ? 11 : 12}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => openReadyToSign(c)}
                    >
                      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        {EditIcon(
                          theme.palette.system.midnightBlue,
                          '24',
                          '24',
                        )}

                        <Typography pl={2} color="system.midnightBlue">
                          {c?.typeId === ContractTypeIds.ADDENDUM
                            ? 'Addendum'
                            : 'Contract'}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        {c?.statusId ===
                          ContractStatusIdStates.READY_TO_SIGN && (
                          <>
                            <Box
                              sx={{
                                backgroundColor: 'system.midnightBlue',
                                borderRadius: '25px',
                                px: 2,
                                py: 1,
                              }}
                            >
                              <Typography
                                variant="body1"
                                color="system.white"
                                fontWeight="600"
                                textTransform={'uppercase'}
                              >
                                {c?.status}
                              </Typography>
                            </Box>
                            {AttentionDot}
                          </>
                        )}

                        {c?.statusId === ContractStatusIdStates.SIGNED &&
                          c?.signedDate && (
                            <Typography
                              variant="body1"
                              color="system.coolGray"
                              fontWeight="400"
                            >
                              {`Signed ${moment(c?.signedDate).format(
                                'MMM DD, YYYY',
                              )}`}
                            </Typography>
                          )}

                        <ArrowRightIcon
                          htmlColor={theme.palette.system.coolGray}
                          sx={{ ml: 4, width: '16px', height: '16px' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {contracts && i < contracts?.length - 1 && (
                    <Divider
                      sx={{
                        width: '100%',
                        height: '1px',
                      }}
                    />
                  )}
                </Fragment>
              </>
            ))}
      </Grid>
      {isDownloadActive && DrawerButtonFooter}
    </>
  );
});
export { ContractsPanel };
