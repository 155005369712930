import { all } from 'redux-saga/effects';
import { actionItemSaga } from './actionItemSaga';
import { arbitrationAgreementSaga } from './ArbitrationAgreement/ArbitrationAgreementSaga';
import { assignmentSaga } from './assignments/assignmentSaga';
import { authSaga } from './authSaga';
import { bannersSaga } from './banners/bannersSaga';
import { communitySaga } from './community/communitySaga';
import { contractSaga } from './contracts/contractSaga';
import { credentialSaga } from './credentials/credentialSaga';
import { credentialStatusSaga } from './credentials/credentialStatusSaga';
import { dataLayerSaga } from './dataLayer/dataLayerSaga';
import { domainSaga } from './domain/domainSaga';
import { faqSaga } from './faq/faqSaga';
import { homeSaga } from './home/homeSaga';
import { impactTrackerSaga } from './impactTracker/impactTrackerSaga';
import { applyJobSaga } from './jobs/applyJobSaga';
import { cognitiveJobSaga } from './jobs/cognitiveJobSaga';
import { jobDetailSaga } from './jobs/jobDetailSaga';
import { jobSaga } from './jobs/jobSaga';
import { similarJobsSaga } from './jobs/similarJobsSaga';
import { lookupSaga } from './lookupSaga';
import { notificationsSaga } from './notifications/notificationsSaga';
import { educationSaga } from './profile/education/educationSaga';
import { workHistorySaga } from './profile/workhistory/workHistorySaga';
import { promotionsSaga } from './promotions/promotionsSaga';
import { assignmentRatingSaga } from './rating/assignmentRatingSaga';
import { referralSaga } from './referral/referralSaga';
import { reimbursementSaga } from './reimbursements/reimbursementSaga';
import { resourcesSaga } from './resources/resourcesSaga';
import { searchLocationSaga } from './searchLocation/searchLocationSaga';
import { skillsChecklistsSaga } from './skillsChecklistsSaga';
import { ssoSaga } from './sso/ssoSaga';
import { supportCenterSaga } from './supportCenter/supportCenterSaga';
import { termsSaga } from './termsSaga';
import { timeEntrySaga } from './timeEntry/timeEntrySaga';
import { userContactSaga } from './user/userContactSaga';
import { userLoginSaga } from './user/userLoginSaga';
import { userPreferenceSaga } from './user/userPreferenceSaga';
import { userProfilePdfSaga } from './user/userProfilePdfSaga';
import { userProfileSaga } from './user/userProfileSaga';
import { verificationCodeSaga } from './verificationSaga';

function* rootSaga() {
  yield all([
    actionItemSaga(),
    applyJobSaga(),
    assignmentRatingSaga(),
    assignmentSaga(),
    authSaga(),
    bannersSaga(),
    cognitiveJobSaga(),
    communitySaga(),
    contractSaga(),
    arbitrationAgreementSaga(),
    credentialSaga(),
    credentialStatusSaga(),
    dataLayerSaga(),
    domainSaga(),
    educationSaga(),
    faqSaga(),
    homeSaga(),
    impactTrackerSaga(),
    jobDetailSaga(),
    jobSaga(),
    lookupSaga(),
    notificationsSaga(),
    promotionsSaga(),
    referralSaga(),
    reimbursementSaga(),
    resourcesSaga(),
    searchLocationSaga(),
    similarJobsSaga(),
    skillsChecklistsSaga(),
    ssoSaga(),
    supportCenterSaga(),
    termsSaga(),
    timeEntrySaga(),
    userContactSaga(),
    userLoginSaga(),
    userProfilePdfSaga(),
    userProfileSaga(),
    userPreferenceSaga(),
    verificationCodeSaga(),
    workHistorySaga(),
  ]);
}

export default rootSaga;
