import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';

export const fetchContract = async (contractUrl: string): Promise<any> => {
  try {
    return await http.get(`${contractUrl}/url`);
  } catch (error) {
    logger.error(error, 'fetchContract', 'contractService.ts');
    return error;
  }
};

export const fetchAuthImgUrl = async (imgUrl: string): Promise<any> => {
  try {
    return await http.get<string>(`${imgUrl}/url`);
  } catch (error) {
    logger.error(error, 'fetchAuthImgUrl', 'contractService.ts');
  }
};

export const signContract = async ({
  userId,
  placementId,
  contractId,
  signature,
}: {
  userId: string;
  placementId: number;
  contractId: string;
  signature: string;
}) => {
  try {
    return await http.put(
      `contracts/${userId}/${placementId}/${contractId}/sign`,
      { signature },
    );
  } catch (error) {
    logger.error(error, 'signContract', 'contractService.ts');
    return error;
  }
};

export const generateContractsPdf = async (
  userId: string,
  placementId: number,
  contractId: string,
): Promise<any> => {
  return await http.get(`contract/${userId}/${placementId}/${contractId}/pdf`);
};
