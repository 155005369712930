import { PayloadAction } from '@reduxjs/toolkit';
import { TOAST_MESSAGE } from 'constants/helperText';
import { EmployeeRoleId } from 'enums/employeeRoleId';
import { ReimbursementType } from 'enums/reimbursement';
import { httpSuccess } from 'helpers/serviceHelper';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { ICalculateReimbursement } from 'interfaces/Reimbursement/ICalculateReimbursement';
import {
  IBaggageExpense,
  IJobRequirementExpense,
  IMileageExpense,
  IReimbursementRequest,
  ITravelExpense,
} from 'interfaces/Reimbursement/IReimbursementForm';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { LocumsAssignmentSummaryItem } from 'interfaces/Assignment/AssignmentSummaryItem';
import {
  fetchAssignments,
  fetchLocumsAssignments,
  fetchLocumsAssignmentExpenseTypes,
} from 'services/assignments/assignmentService';
import { getUserContacts } from 'services/contacts/contactService';
import { logger } from 'services/logging/appInsights';
import { AppUserType } from 'enums/AppUserType';
import store from '../../../store/configureStore';
import {
  fetchMileageReimbursement,
  getReimbursementService,
  postReimbursementDocumentRequest,
  postReimbursementRequest,
} from 'services/reimbursement/reimbursementService';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { setError } from 'store/slices/error/errorSlice';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import {
  calculateMileageReimbursement,
  getAssignments,
  getExpenseTypes,
  getReimbursements,
  getUserContact,
  postAirlineTravelAction,
  postAutomotiveTravelAction,
  postBaggageRequirements,
  postJobReqsAction,
  postMileageRequirements,
  setAssignments,
  setCalculatedMileage,
  setExpenseTypes,
  setReimbursements,
  setReimbursementSubmission,
  setUserContact,
} from 'store/slices/reimbursement/reimbursementSlice';
import { placementId } from '../actionItemSaga';

const TrackUploadJobReqReimbursement = (fn, ...args) =>
  trackPromise(fn(...args), 'save-job-req-reimbursement');

const TrackUploadAirlineTravelReimbursement = (fn, ...args) =>
  trackPromise(fn(...args), 'save-airline-travel-reimbursement');

const TrackUploadAutomotiveTravelReimbursement = (fn, ...args) =>
  trackPromise(fn(...args), 'save-automotive-travel-reimbursement');
const TrackUploadBaggageReimbursement = (fn, ...args) =>
  trackPromise(fn(...args), 'save-baggage-req-reimbursement');

const TrackUploadMileageReimbursement = (fn, ...args) =>
  trackPromise(fn(...args), 'save-mileage-req-reimbursement');

const TrackMileageCalculation = (fn, ...args) =>
  trackPromise(fn(...args), 'calculate-mileage-req-reimbursement');

const userIdSelector = (state: RootState) => state.auth.userId;

export function* reimbursementSaga() {
  yield all([
    takeLatest(getReimbursements.type, getReimbursementData),
    takeLatest(getAssignments.type, getAssignmentsData),
    takeLatest(getExpenseTypes.type, getExpenseTypesData),
    takeLatest(getUserContact.type, getCustomerSupportContact),
    takeLatest(postJobReqsAction.type, submitJobRequirementsReimbursement),
    takeLatest(postAirlineTravelAction.type, submitAirlineTravelReimbursement),
    takeLatest(
      postAutomotiveTravelAction.type,
      submitAutomotiveTravelReimbursement,
    ),
    takeLatest(
      postBaggageRequirements.type,
      submitBaggageRequirementsReimbursement,
    ),
    takeLatest(
      postMileageRequirements.type,
      submitMileageRequirementsReimbursement,
    ),
    takeLatest(calculateMileageReimbursement.type, getMileageReimbursement),
  ]);
}

/**
 *  Watcher function to get reimbursement detail by user id, offset and limit
 *
 */
function* getReimbursementData(action) {
  let currentUserId = yield select(userIdSelector);
  try {
    const reimbursementData = yield call(
      p => getReimbursementService(currentUserId, p.offset, p.limit),
      action.payload,
    );
    let { data } = reimbursementData;
    data = data !== '' ? data : [];
    yield put(setReimbursements(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getReimbursementData', 'reimbursementSaga.ts');
  }
}

/**
 *  Watcher function to get assignments detail by user id and status
 *
 */
function* getAssignmentsData(action) {
  let currentUserId = yield select(userIdSelector);
  const userType = store.getState()?.auth?.userType;
  const isLocums = userType === AppUserType.LOCUMS ? true : false;
  try {
    const reimbursementData = isLocums
      ? yield call(p => fetchLocumsAssignments(currentUserId), action.payload)
      : yield call(
          p => fetchAssignments(currentUserId, p.status),
          action.payload,
        );
    let { data } = reimbursementData;
    //Remap locums data
    if (isLocums) {
      var dataSummaryLocums: Array<LocumsAssignmentSummaryItem> =
        data.longTermOrders;

      data = dataSummaryLocums.map(a => {
        return {
          placementId: a.ltOrderId,
          facilityName: a.facility.name,
          address: { city: a.facility.city, state: a.facility.state },
          startDate: a.startDate,
          endDate: a.endDate,
        };
      });
    } else {
      data = data !== '' ? data.items : [];
    }

    yield put(setAssignments(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getAssignmentsData', 'reimbursementSaga.ts');
  }
}

/**
 *  Watcher function to get assignments detail by user id and status
 *
 */
function* getExpenseTypesData(action) {
  let currentUserId = yield select(userIdSelector);
  const placementId = action.payload.placementId;
  const expenseDate = action.payload.expenseDate;
  const userType = store.getState()?.auth?.userType;
  const isLocums = userType === AppUserType.LOCUMS ? true : false;
  try {
    const expenseTypeData = yield call(
      p =>
        fetchLocumsAssignmentExpenseTypes(
          currentUserId,
          placementId,
          expenseDate,
          isLocums,
        ),
      action.payload,
    );
    let { data } = expenseTypeData;
    data = data !== '' ? data : [];

    yield put(setExpenseTypes(data));
  } catch (error: any) {
    yield put(
      setExpenseTypes({
        startDate: '',
        endDate: '',
        shiftTypes: [],
      }),
    );
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getExpenseTypesData', 'reimbursementSaga.ts');
  }
}

/**
 *  Watcher function to get customer support contact detail by user id and status
 *
 */
function* getCustomerSupportContact(action) {
  let currentUserId = yield select(userIdSelector);
  try {
    const recruiterData = yield call(() => getUserContacts(currentUserId));
    const { data } = recruiterData;
    const result = data?.userContacts.find(
      item => item.employeeRoleId === EmployeeRoleId.CUSTOMER_SUPPORT_PAYROLL,
    );
    yield put(setUserContact(result));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getCustomerSupportContact', 'reimbursementSaga.ts');
  }
}

/**
 * Watcher fxn to post reimbursement for job details
 */

function* submitJobRequirementsReimbursement(
  action: PayloadAction<IJobRequirementExpense>,
) {
  try {
    yield put(setReimbursementSubmission(false));
    const userId = yield select(userIdSelector);
    let docUrls = [] as string[];
    for (const attachment of action.payload?.attachments) {
      const docUrl = yield call(
        TrackUploadJobReqReimbursement,
        postReimbursementDocumentRequest,
        userId,
        attachment,
      );
      if (docUrl && httpSuccess(docUrl?.status))
        docUrls.push(docUrl.data.filePath);
    }
    const body = {
      reimbursementTypeId: ReimbursementType.Job_Requirements,
      reimbursementType: 'Job Requirements',
      jobRequirementExpense: {
        ...action.payload,
        attachments: [...docUrls],
      },
    } as IReimbursementRequest;
    const response = yield call(
      TrackUploadJobReqReimbursement,
      postReimbursementRequest,
      userId,
      body,
    );
    if (response && httpSuccess(response?.status)) {
      yield put(setReimbursementSubmission(true));
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.ReimbursementSubmitted,
        }),
      );
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
      yield put(setReimbursementSubmission(false));
    }
    yield put(fileUploadsActions.reset());
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    yield put(fileUploadsActions.reset());
    logger.error(
      error,
      'submitJobRequirementsReimbursement',
      'reimbursementSaga.ts',
    );
  }
}

/**
 * Watcher function to post reimbursement for airline travel
 */

function* submitAirlineTravelReimbursement(
  action: PayloadAction<ITravelExpense>,
) {
  try {
    yield put(setReimbursementSubmission(false));
    const userId = yield select(userIdSelector);
    let docUrls = [] as string[];
    for (const attachment of action.payload?.airlineExpense?.attachments ??
      []) {
      const docUrl = yield call(
        TrackUploadAirlineTravelReimbursement,
        postReimbursementDocumentRequest,
        userId,
        attachment,
      );
      if (docUrl && httpSuccess(docUrl?.status))
        docUrls.push(docUrl.data.filePath);
    }
    const body = {
      reimbursementTypeId: ReimbursementType.Travel,
      reimbursementType: 'Travel',
      travelExpense: {
        travelExpenseType: action.payload.travelExpenseType,
        travelExpenseTypeId: action.payload.travelExpenseTypeId,
        airlineExpense: {
          ...action.payload.airlineExpense,
          attachments: [...docUrls],
        },
      },
    } as IReimbursementRequest;
    const response = yield call(
      TrackUploadAirlineTravelReimbursement,
      postReimbursementRequest,
      userId,
      body,
    );
    if (response && httpSuccess(response?.status)) {
      yield put(setReimbursementSubmission(true));
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.ReimbursementSubmitted,
        }),
      );
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
      yield put(setReimbursementSubmission(false));
    }
    yield put(fileUploadsActions.reset());
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    yield put(fileUploadsActions.reset());
    logger.error(
      error,
      'submitAirlineTravelReimbursement',
      'reimbursementSaga.ts',
    );
  }
}

/**
 * Watcher function to post reimbursement for automotive travel
 */

function* submitAutomotiveTravelReimbursement(
  action: PayloadAction<ITravelExpense>,
) {
  try {
    yield put(setReimbursementSubmission(false));
    const userId = yield select(userIdSelector);
    let docUrls = [] as string[];
    for (const attachment of action.payload?.automotiveExpense?.attachments ??
      []) {
      const docUrl = yield call(
        TrackUploadAutomotiveTravelReimbursement,
        postReimbursementDocumentRequest,
        userId,
        attachment,
      );
      if (docUrl && httpSuccess(docUrl?.status))
        docUrls.push(docUrl.data.filePath);
    }
    const body = {
      reimbursementTypeId: ReimbursementType.Travel,
      reimbursementType: 'Travel',
      travelExpense: {
        travelExpenseType: action.payload.travelExpenseType,
        travelExpenseTypeId: action.payload.travelExpenseTypeId,
        automotiveExpense: {
          ...action.payload.automotiveExpense,
          attachments: [...docUrls],
        },
      },
    } as IReimbursementRequest;
    const response = yield call(
      TrackUploadAutomotiveTravelReimbursement,
      postReimbursementRequest,
      userId,
      body,
    );
    if (response && httpSuccess(response?.status)) {
      yield put(setReimbursementSubmission(true));
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.ReimbursementSubmitted,
        }),
      );
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
      yield put(setReimbursementSubmission(false));
    }
    yield put(fileUploadsActions.reset());
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    yield put(fileUploadsActions.reset());
    logger.error(
      error,
      'submitAutomotiveTravelReimbursement',
      'reimbursementSaga.ts',
    );
  }
}

/**
 * Watcher function to post reimbursement for baggage reimbursement
 */
function* submitBaggageRequirementsReimbursement(
  action: PayloadAction<IBaggageExpense>,
) {
  try {
    yield put(setReimbursementSubmission(false));
    const userId = yield select(userIdSelector);
    let docUrls = [] as string[];
    for (const attachment of action.payload?.attachments) {
      const docUrl = yield call(
        TrackUploadBaggageReimbursement,
        postReimbursementDocumentRequest,
        userId,
        attachment,
      );
      if (docUrl && httpSuccess(docUrl?.status))
        docUrls.push(docUrl.data.filePath);
    }
    const body = {
      reimbursementTypeId: ReimbursementType.Baggage,
      reimbursementType: 'Baggage',
      baggageExpense: {
        ...action.payload,
        attachments: [...docUrls],
      },
    } as IReimbursementRequest;
    const response = yield call(
      TrackUploadBaggageReimbursement,
      postReimbursementRequest,
      userId,
      body,
    );
    if (response && httpSuccess(response?.status)) {
      yield put(setReimbursementSubmission(true));
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.ReimbursementSubmitted,
        }),
      );
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
      yield put(setReimbursementSubmission(false));
    }
    yield put(fileUploadsActions.reset());
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    yield put(fileUploadsActions.reset());
    logger.error(
      error,
      'submitBaggageRequirementsReimbursement',
      'reimbursementSaga.ts',
    );
  }
}

/**
 * Watcher function to post reimbursement for mileage reimbursement
 */
function* submitMileageRequirementsReimbursement(
  action: PayloadAction<IMileageExpense>,
) {
  try {
    yield put(setReimbursementSubmission(false));
    const userId = yield select(userIdSelector);
    const body = {
      reimbursementTypeId: ReimbursementType.Mileage,
      reimbursementType: 'Mileage',
      mileageExpense: {
        ...action.payload,
      },
    } as IReimbursementRequest;
    const response = yield call(
      TrackUploadMileageReimbursement,
      postReimbursementRequest,
      userId,
      body,
    );
    if (response && httpSuccess(response?.status)) {
      yield put(setReimbursementSubmission(true));
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.ReimbursementSubmitted,
        }),
      );
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
      yield put(setReimbursementSubmission(false));
    }
    yield put(fileUploadsActions.reset());
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    yield put(fileUploadsActions.reset());
    logger.error(
      error,
      'submitMileageRequirementsReimbursement',
      'reimbursementSaga.ts',
    );
  }
}

/**
 * Watcher function to calculate mileage reimbursement
 */
function* getMileageReimbursement(
  action: PayloadAction<ICalculateReimbursement>,
) {
  const userType = store.getState()?.auth?.userType;
  const isLocums = userType === AppUserType.LOCUMS ? true : false;
  const requestBody = action.payload;
  const userId = yield select(userIdSelector);
  try {
    if (
      requestBody.addressFrom?.length > 0 &&
      requestBody.addressTo?.length > 0
    ) {
      const getMileageData = yield call(
        TrackMileageCalculation,
        fetchMileageReimbursement,
        userId,
        requestBody,
        isLocums,
      );
      const { data } = getMileageData;
      yield put(setCalculatedMileage(data));
    }
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(error, 'getMileageReimbursement', 'reimbursementSaga.ts');
  }
}
