import { useCallback, useEffect, useRef, useState } from 'react';
import CobrowseIO from 'cobrowse-sdk-js';
import { showConsent } from 'components/FaqHelpCenter/ScreenShare/Consent';
import { logger } from 'services/logging/appInsights';

interface UseCobrowseReturn {
  cobrowsing: boolean;
  start: (options?: {
    customData?: any;
    customSessionControls?: boolean;
  }) => void;
  endSession: () => void;
  createSessionCode: () => Promise<number>;
  present: (code: number) => void;
  setCustomData: (data: any) => void;
  stopCobrowseSession: () => void;
}

export const useCobrowse = (): UseCobrowseReturn => {
  const [cobrowsing, setCobrowsing] = useState(
    CobrowseIO.currentSession?.isActive() || false,
  );
  const cobrowseStarted = useRef(false);
  const consentDialog = useRef<(() => Promise<boolean>) | null>(null);

  useEffect(() => {
    const onCobrowseStart = (session: any) => {
      if (session.isActive()) {
        setCobrowsing(true);
      } else if (session?.isAuthorizing()) {
        CobrowseIO.confirmSession = async () => {
          const allowed = await (consentDialog.current?.() ??
            Promise.resolve(false));
          if (allowed) {
            return true;
          } else {
            return false;
          }
        };
      } else {
        CobrowseIO.confirmSession = () => Promise.reject();
      }
    };

    const onCobrowseEnd = () => {
      setCobrowsing(false);
    };

    window.CobrowseIO = CobrowseIO;

    CobrowseIO.on('session.updated', onCobrowseStart);
    CobrowseIO.on('session.ended', onCobrowseEnd);

    return () => {
      CobrowseIO.off('session.updated', onCobrowseStart);
      CobrowseIO.off('session.ended', onCobrowseEnd);
    };
  }, []);

  const start = useCallback(
    ({ customData, customSessionControls = false } = {}) => {
      if (cobrowseStarted.current) {
        return;
      }
      CobrowseIO.registration = false;
      CobrowseIO.license = process.env.REACT_APP_COBROWSE_LICENSE_KEY as string;
      CobrowseIO.customData = customData || {};

      if (customSessionControls) {
        CobrowseIO.showSessionControls = () => true;
        CobrowseIO.hideSessionControls = () => true;
      } else {
        // Do nothing:
        // Here we can apply styles to the default button provided by cobrowse-sdk.
      }

      CobrowseIO.start({ allowIFrameStart: true })
        .then(() => {
          cobrowseStarted.current = true;
        })
        .catch((error: any) => {
          // Do nothing - may be we can handle these errors in future
          // Cobrowse start error
          logger.error(error, 'Cobrowse start error', 'useCobrowse.ts');
        });
    },
    [],
  );

  const stop = useCallback(() => {
    CobrowseIO.stop();
    cobrowseStarted.current = false;
  }, []);

  const stopCobrowseSession = useCallback(() => {
    showConsent(
      'End Session?',
      'You’re sharing screens from this app with a support agent',
      'CANCEL',
      'Confirm',
    )
      .then(confirmed => {
        if (confirmed) {
          stop();
        }
      })
      .catch(() => {
        // Do nothing - if the user denied the request
        logger.error(
          'User denied the request',
          'stopCobrowseSession',
          'useCobrowse.ts',
        );
      });
  }, [stop]);

  const endSession = useCallback(() => {
    CobrowseIO.currentSession?.end();
  }, []);

  const createSessionCode = useCallback(async (): Promise<number> => {
    const code: number = await CobrowseIO.createSessionCode();
    // Initialize the consent dialog
    consentDialog.current = () =>
      showConsent(
        'Support Request',
        'A support agent would like to use this app with you. Do you wish to allow this.',
        'DENY',
        'Allow',
      );

    return code;
  }, []);

  const present = useCallback((code: number) => {
    CobrowseIO.getSession(code);
  }, []);

  const setCustomData = useCallback((data: any) => {
    CobrowseIO.customData = data;
  }, []);

  return {
    cobrowsing,
    start,
    endSession,
    createSessionCode,
    present,
    setCustomData,
    stopCobrowseSession,
  };
};
