import { combineReducers, configureStore } from '@reduxjs/toolkit';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { decodeToken } from 'utils/tokenHelper';
import rootSaga from './sagas/rootSaga';
import impactTrackerReducer from './slices/ImpactTracker/impactTrackerSlice';
import actionItemReducer from './slices/actionItem/actionItemSlice';
import alertbarReducer from './slices/alertbar/alertbarSlice';
import arbitrationAgreementReducer from './slices/arbitrationAgreement/arbitrationAgreementSlice';
import assignmentReducer from './slices/assignments/assignmentSlice';
import authReducer from './slices/authentication/authenticationSlice';
import resetPasswordReducer from './slices/authentication/resetPasswordSlice';
import bannersReducer from './slices/banners/bannersSlice';
import communityReducer from './slices/community/communitySlice';
import contractsReducer from './slices/contracts/contractSlice';
import CredentialStatusReducer from './slices/credentialStatus/credentialStatusSlice';
import credentialReducer from './slices/credentials/credentialSlice';
import dataLayerReducer from './slices/dataLayer/dataLayerSlice';
import domainReducer from './slices/domain/domainSlice';
import educationReducer from './slices/education/educationSlice';
import errorReducer from './slices/error/errorSlice';
import faqReducer from './slices/faq/faqSlice';
import fileUploadReducer from './slices/fileUpload/fileUploadSlice';
import filterReducer from './slices/filter/filterSlice';
import homeReducer from './slices/home/homeSlice';
import applyJobReducer from './slices/jobs/ApplyJobsSlice';
import cognitiveJobsReducer from './slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import jobDetailsReducer from './slices/jobs/jobDetailSlice';
import jobShareReducer from './slices/jobs/jobShareSlice';
import jobsReducer from './slices/jobs/jobsSlice';
import similarJobsReducer from './slices/jobs/similarJobsSlice';
import lookupReducer from './slices/lookups/lookupsSlice';
import navigationReducer from './slices/navigation/navigationSlice';
import notificationsReducer from './slices/notifications/notificationsSlice';
import photoEditingReducer from './slices/photo/photoSlice';
import profileMenuReducer from './slices/profileMenu/profileMenuSlice';
import promotionsReducer from './slices/promotions/promotionsSlice';
import assignmentRatingReducer from './slices/rating/assignmentRatingSlice';
import referralReducer from './slices/referral/referralSlice';
import reimbursementReducer from './slices/reimbursement/reimbursementSlice';
import resourcesReducer from './slices/resources/resourcesSlice';
import searchLocationReducer from './slices/searchLocation/searchLocationSlice';
import skillsChecklistsReducer from './slices/skillsChecklists/skillsChecklistsSlice';
import ssoReducer from './slices/sso/ssoSlice';
import supportCenterReducer from './slices/supportCenter/supportCenterSlice';
import termsReducer from './slices/termsAndConditions/termsSlice';
import timesheetReducer from './slices/timeEntry/timeEntrySlice';
import userContactReducer from './slices/user/userContact/userContactSlice';
import userLoginReducer from './slices/user/userLogin/userLoginSlice';
import userPreferenceReducer from './slices/user/userPreferenceSlice';
import userProfilepdfReducer from './slices/user/userProfile/userProfilePdfSlice';
import userProfileReducer from './slices/user/userProfile/userProfileSlice';
import verificationCodeReducer from './slices/verificationCode/verificationCodeSlice';
import workHistoryReducer from './slices/workHistory/workHistorySlice';
import { logger } from 'services/logging/appInsights';

const sagaMiddleware = createSagaMiddleware();

const saveToLocalStorage = state => {
  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch (e) {
    logger.error(e, 'saveToLocalStorage', 'configureStore.ts');
  }
};

const deleteFromLocalStorage = () => {
  try {
    window.localStorage.removeItem('state');
  } catch (e) {
    logger.error(e, 'deleteFromLocalStorage', 'configureStore.ts');
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem('state');
    const json = stateStr ? JSON.parse(stateStr) : undefined;

    if (json?.auth?.isLoggedIn) {
      const token = decodeToken(json?.auth?.loginResponse?.accessToken);

      if (token?.exp) {
        const expires = moment.unix(token?.exp);

        if (expires.isAfter(moment())) {
          return json;
        }
      }
    }
    return undefined;
  } catch (e) {
    logger.error(e, 'loadFromLocalStorage', 'configureStore.ts');
    return undefined;
  }
};

/* please maintain alphabetical order when adding new reducer*/
const combinedReducers = combineReducers({
  actionItem: actionItemReducer,
  alertbarComponent: alertbarReducer,
  applyJob: applyJobReducer,
  assignmentRating: assignmentRatingReducer,
  assignmentSummary: assignmentReducer,
  auth: authReducer,
  banners: bannersReducer,
  community: communityReducer,
  contracts: contractsReducer,
  arbitrationAgreement: arbitrationAgreementReducer,
  credential: credentialReducer,
  credentialStatus: CredentialStatusReducer,
  dataLayer: dataLayerReducer,
  domain: domainReducer,
  education: educationReducer,
  error: errorReducer,
  fileUpload: fileUploadReducer,
  filter: filterReducer,
  homePageData: homeReducer,
  impactTracker: impactTrackerReducer,
  jobDetail: jobDetailsReducer,
  jobs: jobsReducer,
  // this will replace jobs after launch
  // I think 'jobs' is a more logical name for the store rather than 'cognitiveJobs'
  jobsV2: cognitiveJobsReducer,
  jobShare: jobShareReducer,
  lookup: lookupReducer,
  nav: navigationReducer,
  notifications: notificationsReducer,
  photoEditing: photoEditingReducer,
  profileMenu: profileMenuReducer,
  promotions: promotionsReducer,
  referrals: referralReducer,
  reimbursement: reimbursementReducer,
  resetPassword: resetPasswordReducer,
  resources: resourcesReducer,
  searchLocation: searchLocationReducer,
  similarJobs: similarJobsReducer,
  skillsChecklist: skillsChecklistsReducer,
  sso: ssoReducer,
  supportCenter: supportCenterReducer,
  terms: termsReducer,
  timesheet: timesheetReducer,
  userContact: userContactReducer,
  userExistStatus: userLoginReducer,
  userFaq: faqReducer,
  userPreference: userPreferenceReducer,
  userProfile: userProfileReducer,
  userProfilePdf: userProfilepdfReducer,
  verificationCode: verificationCodeReducer,
  workHistory: workHistoryReducer,
});

const rootReducer = (state, action) => {
  if (action?.type === 'reset-redux-store') {
    state = undefined;
  }

  return combinedReducers(state, action);
};

const resetState = () => {
  try {
    store.dispatch({ type: 'reset-redux-store' });
    deleteFromLocalStorage();
  } catch (e) {
    logger.error(e, 'resetState', 'configureStore.ts');
  }
};

const persistedStore = loadFromLocalStorage();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  preloadedState: persistedStore,
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
export { resetState };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
