import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { logger } from 'services/logging/appInsights';
import { fetchSearchLocation } from 'services/user/userService';
import { RootState } from 'store/configureStore';
import {
  searchLocation,
  setError,
  setSearchLocation,
} from 'store/slices/searchLocation/searchLocationSlice';

export const userId = (state: RootState) => state.auth.userId;

// Worker Sagas

export function* postSearchLocation(action) {
  const currentUserId = yield select(userId);
  try {
    const response = yield call(
      f => fetchSearchLocation(currentUserId, f.limit, f.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSearchLocation(data));
  } catch (error: any) {
    yield put(setError(error));
    yield put(setSearchLocation([]));
    logger.error(error, 'postSearchLocation', 'searchLocationSaga.ts');
  }
}

function* watchSearchLocation() {
  yield takeLatest(searchLocation.type, postSearchLocation);
}

export function* searchLocationSaga() {
  yield all([watchSearchLocation()]);
}
