import { TOAST_MESSAGE } from 'constants/helperText';
import { StatusCode } from 'enums/StatusCode';
import {
  isSafariOrFirefox,
  openInCurrentTab,
  openInNewTab,
} from 'helpers/openInNewTab';
import { IErrorAuthResponse } from 'interfaces/Common/ICommonContract';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as domainService from 'services/domain/domainService';
import { logger } from 'services/logging/appInsights';
import { RootState } from 'store/configureStore';
import {
  downloadPDF,
  getFeaturedContent,
  openPDF,
  setError,
  setFeaturedContent,
} from 'store/slices/domain/domainSlice';
import { IFeatureContentSuccessResponse } from 'types/FeaturedContentResponse';

const userId = (state: RootState) => state.auth.userId;
//Watcher Saga
function* getFeatured(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      f => domainService.fetchFeaturedContent(currentUserId, f.offset, f.limit),
      action.payload,
    );

    switch (response.status) {
      case StatusCode.OK:
        const { data } = response;
        yield put(setFeaturedContent(data));
        break;
      case StatusCode.NoContent:
        const emptyResponse: IFeatureContentSuccessResponse = {
          paging: {
            limit: '10',
            offset: '0',
            total: '10',
          },
          items: [],
        };
        yield put(setFeaturedContent(emptyResponse));
        break;
      default:
        break;
    }
  } catch (error: any) {
    let err: IErrorAuthResponse | any = {};
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    logger.error(error, 'getFeatured', 'domainSaga.ts');
  }
}

export function* openPDFSaga(action) {
  try {
    const fileUrl = yield call(domainService.fetchPdf, action.payload);
    isSafariOrFirefox ? openInCurrentTab(fileUrl) : openInNewTab(fileUrl);
  } catch (e) {
    logger.error(e, 'openPDFSaga', 'domainSaga.ts');
  }
}

export function* downloadPDFSaga(action) {
  const fileUrl = yield call(
    domainService.downloadPdfFromBlobUrl,
    action.payload?.url,
  );

  if (window) {
    let a = window.document.createElement('a');
    window.document.body.appendChild(a);
    a.href = fileUrl;
    const filename = action.payload?.fileName;
    a.download = (filename ? `${filename}.pdf` : '') || 'form.pdf';
    a.click();
    setTimeout(() => {
      a.remove();
    }, 50);
  }
}

export function* domainSaga() {
  yield all([
    yield takeLatest(getFeaturedContent.type, getFeatured),
    takeLatest(openPDF.type, openPDFSaga),
    takeLatest(downloadPDF.type, downloadPDFSaga),
  ]);
}
