import { Box, Dialog, DialogActions, Grid, Typography } from '@mui/material';
import { guidGenerator } from 'helpers/guidGenerator';
import React, { useCallback, useEffect, useState } from 'react';
import { LeadButton } from './LeadButton';
import JobSearchIcon from 'assets/icons/Lead/JobSearch.svg';
import { WebTravelerApplication } from './WebTravelerApplication';
import { AppButton } from 'components/common/Button/Button';
import { useAppSelector } from 'store/hooks';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { RootState } from 'store/configureStore';
import { IJobDetailsData } from 'store/slices/jobs/jobsState';
import ProgressTrackerButton from 'components/ProgressTrackerButton/ProgressTrackerButton';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';

export const LeadDialogWrapper = props => {
  const { children, sx } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [mount, setMount] = useState(false);
  const [componentKey] = useState(guidGenerator());

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const userStatusTypeId = useAppSelector(
    (state: RootState) => state.userProfile?.userStatusTypeId,
  );
  const { jobDetailsData } = useAppSelector(
    state => state.jobDetail,
  ) as IJobDetailsData;
  const applicationStatus = useAppSelector(state => state.applyJob);

  const onClick = useCallback(e => {
    setShowDialog(true);
  }, []);

  useEffect(() => {
    setMount(true);

    return () => {
      setMount(false);
      setDrawerOpen(false);
      setShowDialog(false);
    };
  }, []);

  useEffect(() => {
    if (userStatusTypeId === UserStatusTypeId.LEAD) {
      const el = document.getElementById(componentKey);
      const handler = e => {
        e?.stopPropagation?.();
        onClick(e);
      };

      el?.addEventListener('click', handler);

      return () => {
        el?.removeEventListener('click', handler);
      };
    }
  }, [mount, componentKey, onClick, userStatusTypeId]);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(prevState => !prevState);
  }, []);

  const onDialogActionClick = useCallback(() => {
    toggleDrawer();
    setShowDialog(false);
  }, [toggleDrawer]);

  const user: IUserProfile | undefined = useAppSelector(
    (state: RootState) => !!state.userProfile && state.userProfile,
  );

  const isPersonalInformationComplete = React.useMemo(
    () => !!user?.travelerApplicationStatus?.personalInformation,
    [user?.travelerApplicationStatus],
  );

  const isCredentialsAndEducationComplete = React.useMemo(
    () =>
      !!user?.travelerApplicationStatus?.certifications &&
      !!user?.travelerApplicationStatus?.education,
    [user?.travelerApplicationStatus],
  );

  const isWorkHistoryComplete = React.useMemo(
    () => !!user?.travelerApplicationStatus?.employmentHistory,
    [user?.travelerApplicationStatus],
  );

  return (
    <>
      <Dialog
        open={showDialog}
        PaperProps={{
          style: {
            maxWidth: '327px',
            padding: '36px 24px 36px 24px',
            borderRadius: '4px',
            overflow: 'hidden',
            gap: '24px',
          },
        }}
      >
        <Grid container justifyContent="center" gap="24px">
          <Box
            flexDirection="column"
            alignItems="center"
            display="flex"
            gap="12px"
          >
            <Grid item>
              <img src={JobSearchIcon} alt="Job Search" />
            </Grid>
            <Grid item textAlign="center" xs={12} marginTop={'3px'}>
              <Typography
                color="system.midnightBlue"
                sx={{
                  fontSize: '20px',
                  lineHeight: '20px',
                  fontWeight: '600',
                }}
              >
                Job Search
              </Typography>
            </Grid>
            <Grid item textAlign="center" xs={12}>
              <Typography
                variant="body1"
                color="system.coolGray"
                sx={{ fontSize: '16px', lineHeight: '22px' }}
              >
                With a complete profile you can easily search and apply for your
                first assignment.
              </Typography>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <ProgressTrackerButton
              isSequenceBased={true}
              hoverEffects={false}
              isSlantedView={false}
              contentTextSize={'14px'}
              barHeight={'29px'}
              barContentMargin={'9.5px 0 0'}
              tabBackgroundStyle={{
                marginBottom: '0px',
                borderRadius: '6px',
                backgroundColor: 'transparent',
                flexDirection: 'column',
                width: '100%',
                padding: '2px',
              }}
              buttonTabs={[
                {
                  key: 0,
                  name: '',
                  id: [],
                  message: '',
                  isSelected: isPersonalInformationComplete,
                },
                {
                  key: 1,
                  name: '',
                  id: [],
                  message: '',
                  isSelected: isCredentialsAndEducationComplete,
                },
                {
                  key: 2,
                  name: '',
                  id: [],
                  message: '',
                  isSelected: isWorkHistoryComplete,
                },
              ]}
              expandedProgressBarWidth={'40%'}
              activeTabId={0}
            />
          </Grid>
        </Grid>

        <DialogActions
          sx={{
            padding: '0',
            justifyContent: 'left',
            gap: '0',
            flexDirection: 'column',
          }}
        >
          <LeadButton
            onClick={onDialogActionClick}
            preventDrawerToggle
            sxProps={{
              container: {
                width: '100%',
              },
              button: {
                width: '100%',
              },
            }}
          />
          <Box
            sx={{
              width: '100%',
              margin: user?.travelerApplicationStatus?.hasSubmittedApp
                ? '24px 0px 0px 0px !important'
                : '6px 0px 0px 0px !important',
            }}
          >
            <AppButton
              onClick={() => setShowDialog(false)}
              type="button"
              variant="secondary"
              width="100%"
              sx={{ margin: '0px !important' }}
            >
              Cancel
            </AppButton>
          </Box>
        </DialogActions>
      </Dialog>

      <Box
        id={componentKey}
        sx={{
          width:
            (applicationStatus.applyJob.jobId.toString() ===
              jobDetailsData?.jobId.toString() &&
              applicationStatus.hasApplied) ||
            (jobDetailsData?.appliedDate ?? '') !== ''
              ? 'auto'
              : userStatusTypeId === UserStatusTypeId.LEAD
              ? '100%'
              : 'auto',
          ...sx,
        }}
        data-testid="children"
      >
        {children}
      </Box>
      <WebTravelerApplication
        open={drawerOpen}
        toggleDrawer={(e, open) => !open && toggleDrawer()}
      />
    </>
  );
};
