import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CheckIconFill } from 'assets/icons/Check/CheckIcon-fill';
import AutoSubIndicator from 'components/AutoSubIndicator/AutoSubIndicator';
import { PreferenceTypes } from 'enums/preferences';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { IApplyJobMessageProps } from 'interfaces/Props/IApplyJobMessageProps';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { useAppSelector } from 'store/hooks';
import { theme } from 'styles/theme';

export const ApplyJobStatusMessageContentNonStrike = (
  props: IApplyJobMessageProps,
) => {
  const { id, description, iconSize = '24px', isExpanded } = props;

  const history = useHistory();
  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );
  const handleLinkClick = useCallback(() => {
    history.push(
      `/${AppRouteNames.PREFERENCES}?categoryId=${PreferenceTypes.AUTO_SUBMISSION}`,
    );
  }, [history]);

  return (
    <Box
      id={id}
      sx={{
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CheckIconFill
          sx={{
            color: theme.palette.system.skyBlue,
            width: iconSize,
            height: iconSize,
            marginTop: { xs: '13px', sm: '13px' },
            marginRight: '13px',
          }}
        />
        {description && (
          <Typography
            sx={{
              color: theme.palette.system.midnightBlue,
              font: 'normal normal normal 14px/20px Open Sans',
              fontSize: '14px',
              textAlign: 'center',
              marginTop: { xs: '12px', sm: '12px' },
              variant: 'body1',
              fontWeight: '600',
              whiteSpace: 'nowrap',
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
      {!userStatusIsLead && isExpanded && (
        <>
          <Divider
            variant="fullWidth"
            sx={{
              mt: 2,
              mb: 2,
              width: '100%',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              lineHeight: '18px',
            }}
          >
            <AutoSubIndicator
              iconOnly
              margin="0px"
              color={theme.palette.system.navIconGray}
              iconHeight="30px"
              iconWidth="30px"
            />
            <Typography
              sx={{
                color: theme.palette.system.midnightBlue,
                font: 'normal normal normal 14px/20px Open Sans',
                fontSize: '14px',
                variant: 'body1',
                fontWeight: '600',
              }}
            >
              <span>Get Ahead</span> <br />
              <span>Next Time?</span>
            </Typography>
          </Box>

          <Typography
            onClick={handleLinkClick}
            sx={{
              cursor: 'pointer',
              color: theme.palette.system.skyBlue,
              font: 'body1',
              fontSize: '14px',
              textAlign: 'center',
              marginTop: '6px',
            }}
          >
            <span>Update preferences </span> <br />
            <span>to auto-apply.</span>
          </Typography>
        </>
      )}
    </Box>
  );
};
