import { ICredential } from 'interfaces/Credential/IUploadCredential';
import { IResourcesRequest } from 'interfaces/Resources/IResources';
import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';
import { ILicenseVerificationRequest } from 'store/slices/credentials/credentialState';
import { IDocumentUpload } from 'store/slices/fileUpload/fileUploadState';
import { CredentialStatusResponse } from 'types/CredentialStatus';

/**
 * Method to get credentials data based on user id (certifications=1,licenses=6)
 *
 * @param userId - user Id of the logged in user
 */
export const fetchCredentialData = async (userId: string): Promise<any> => {
  try {
    return await http.get<any>(`/v2/task-center/${userId}`);
  } catch (error) {
    logger.error(error, 'fetchCredentialData', 'credentialService.ts');
    return error;
  }
};

export const refreshCredentialData = async (userId: string): Promise<any> => {
  try {
    return await http.get<any>(`/v2/task-center/${userId}?refresh=true`);
  } catch (error) {
    logger.error(error, 'refreshCredentialData', 'credentialService.ts');
    return error;
  }
};

export const fetchCredentialCount = async (userId: string): Promise<any> => {
  try {
    return await http.get<any>(`/users/credentials/${userId}/document/count`);
  } catch (error) {
    logger.error(error, 'fetchCredentialCount', 'credentialService.ts');
    return error;
  }
};

export async function getAssignmentCredentialSubmissions({
  userId,
}: {
  userId: string;
}) {
  try {
    return await http.get<any>(`/credentials/${userId}/submissions`);
  } catch (error) {
    logger.error(
      error,
      'getAssignmentCredentialSubmissions',
      'credentialService.ts',
    );
    return error;
  }
}

export async function deleteCredentialDocument({
  userId,
  placementId,
  fileName,
}: ICredential) {
  try {
    const url = `/credentials/${userId}/${placementId}/${fileName}/document`;
    return await http.delete<any>(url);
  } catch (error) {
    logger.error(error, 'deleteCredentialDocument', 'credentialService.ts');
    return error;
  }
}

export async function uploadDocumentV2({ userId, placementId, docFile }: any) {
  try {
    const url = `v2/credentials/${userId}/${placementId}/document`;
    return await http.post<any>(url, docFile);
  } catch (error) {
    logger.error(error, 'uploadDocumentV2', 'credentialService.ts');
    return error;
  }
}

export async function uploadCredentialDocumentV2({
  userId,
  docFile,
}: {
  userId: string;
  docFile: IDocumentUpload;
}) {
  try {
    const url = `v2/users/credentials/${userId}/document`;
    return await http.post<IDocumentUpload>(url, docFile);
  } catch (error) {
    logger.error(error, 'uploadCredentialDocumentV2', 'credentialService.ts');
    return error;
  }
}

export async function submitCredentials({
  userId,
  filePaths,
  credentialId,
}: {
  userId: string;
  filePaths: string[];
  credentialId?: string;
}) {
  try {
    const url = `/users/credentials/${userId}/submit`;
    return await http.post<any>(url, { filePaths, credentialId });
  } catch (error) {
    logger.error(error, 'submitCredentials', 'credentialService.ts');
    return error;
  }
}

export async function saveCredentialChecklist({
  userId,
  placementId,
  credential,
}: {
  userId: string;
  placementId: number;
  credential: any;
}) {
  try {
    const url = `/credentials/${userId}/${placementId}/submit`;
    return await http.post<any>(url, credential);
  } catch (error) {
    logger.error(error, 'saveCredentialChecklist', 'credentialService.ts');
    return error;
  }
}

export const getCredentialDetailDocument = async (
  userId: string,
  id: string,
): Promise<any> => {
  try {
    return await http.get<any>(`/users/credentials/${userId}/${id}`);
  } catch (error) {
    logger.error(error, 'getCredentialDetailDocument', 'credentialService.ts');
    return error;
  }
};

export const getCredentialDocumentsList = async (
  userId: string,
  id: string,
): Promise<any> => {
  try {
    let credentialQuery = `/users/credentials/${userId}/document`;
    if (id) credentialQuery += `?credentialId=${id}`;

    return await http.get<any>(credentialQuery);
  } catch (error) {
    logger.error(error, 'getCredentialDocumentsList', 'credentialService.ts');
    return error;
  }
};

export async function fetchUserSubmissions(request: IResourcesRequest) {
  let credentialQuery = `/v2/users/credentials/${request.userId}/submissions`;
  if (request.limit) credentialQuery += `?limit=${request.limit}`;

  if (request.offset) credentialQuery += `&offset=${request.offset}`;

  return await http.get<string>(credentialQuery);
}

export const fetchDocEnvUrl = async (
  userId: string,
  envelopeId: string,
): Promise<any> => {
  try {
    return await http.get<any>(
      `/docusign-envelopes/${userId}/${envelopeId}/url?redirectUrl=${window.location.origin}/task-center?credentialType=4`,
    );
  } catch (error) {
    logger.error(error, 'fetchDocEnvUrl', 'credentialService.ts');
    return error;
  }
};

export const fetchFormStackData = async (uri: string): Promise<any> => {
  try {
    return await http.get<any>(`/${uri}`);
  } catch (error) {
    logger.error(error, 'fetchFormStackData', 'credentialService.ts');
    return error;
  }
};

export const fetchCredentialStatus = async (
  userId: number,
  jobId: string,
): Promise<CredentialStatusResponse> => {
  return await http.get(`/jobs/${userId}/${jobId}/credentials`);
};

export async function updateCredentialStatus({
  userId,
  placementId,
  credentialId,
}: {
  userId: string;
  placementId: number;
  credentialId: number;
}) {
  try {
    const url = `/credentials/${userId}/${placementId}/${credentialId}/status`;
    return await http.post<any>(url);
  } catch (error) {
    logger.error(error, 'updateCredentialStatus', 'credentialService.ts');
    return error;
  }
}

export const saveLicenseData = async (
  userId: number,
  licenseData: ILicenseVerificationRequest,
): Promise<any> => {
  return await http.post(
    `/users/credentials/${userId}/license-verification`,
    licenseData,
  );
};
