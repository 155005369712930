/* eslint-disable no-unused-vars */
export enum NotificationInternalRedirectTypes {
  ASSIGNMENT_DETAILS = 'assignmentdetailspage',
  CONTRACT_ADDENDUM_PAGE = 'contractaddendumdetailpage',
  CONTACTS_PAGE = 'contactspage',
  CREDENTIALS_ITEM = 'credentialitem',
  CREDENTIALS_PAGE = 'credentialspage',
  INTERVIEW_TIPS = 'interviewtips',
  JOB_PAGE = 'jobspage',
  JOB_SEARCH_PAGE = 'jobsearchpage',
  REIMBURSEMENTS_PAGE = 'reimbursements',
  RESOURCES_PAGE = 'resources',
  TIME_ENTRY = 'timesheetpage',
  RECOMMENDED_JOBS_PAGE = 'recommendedjobspage',
  SAVED_SEARCH_ITEM = 'savedsearches',
}
