import { Box, Typography } from '@mui/material';
import { IJobTag } from 'interfaces/Jobs/IJobTag';
import React from 'react';
import { theme } from 'styles/theme';

export const JobTag = (props: IJobTag) => {
  const { id, tagText, isSelected = false, sx } = props;

  return (
    <Box
      id={id}
      sx={{
        height: '27px',
        width: 'auto',
        textAlign: 'center',
        padding: isSelected ? '3px 12px 3px 12px' : '0px',
        border: isSelected
          ? `1px solid ${theme?.palette?.system?.midnightBlue}`
          : 'none',
        borderRadius: isSelected ? '5px' : 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontSize: isSelected ? '14px' : '12px',
          lineHeight: isSelected ? '21px' : '18px',
          color: theme?.palette?.system?.midnightBlue,
          fontWeight: isSelected ? '600' : '400',
        }}
      >
        {tagText}
      </Typography>
    </Box>
  );
};
