import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MyContactsIcon from 'assets/icons/Assignments/MyContacts/MyContacts';
import { CheckIconFill } from 'assets/icons/Check/CheckIcon-fill';
import ArrowRightIcon from 'assets/icons/Controls/ArrowRight';
import { AssignmentLink } from 'components/AssignmentDetail/AssignmentLink';
import AutoSubIndicator from 'components/AutoSubIndicator/AutoSubIndicator';
import { PreferenceTypes } from 'enums/preferences';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { IAssignmentLinkStackItem } from 'interfaces/Assignment/IAssignmentLinkStackItem';
import { IApplyJobMessageProps } from 'interfaces/Props/IApplyJobMessageProps';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { useAppSelector } from 'store/hooks';
import { theme } from 'styles/theme';

export const ApplyJobStatusMessageContentMobile = (
  props: IApplyJobMessageProps,
) => {
  const {
    id,
    description,
    message,
    iconSize = '24px',
    messageSx,
    handleMobileQuestionClick,
  } = props;

  const history = useHistory();
  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );
  const selectedJobId = useAppSelector(state => state.jobs.selectedJobId);

  const handleLinkClick = useCallback(() => {
    history.push(
      `/${AppRouteNames.PREFERENCES}?categoryId=${PreferenceTypes.AUTO_SUBMISSION}`,
      {
        from: 'autoSubmitShortcutJob',
        selectedId: selectedJobId,
      },
    );
  }, [history, selectedJobId]);

  const listItems: IAssignmentLinkStackItem[] = React.useMemo(() => {
    let items: IAssignmentLinkStackItem[] = [];
    if (!userStatusIsLead) {
      items.push({
        leftIcon: (
          <AutoSubIndicator
            iconOnly
            margin="0px"
            color={theme.palette.system.skyBlue}
          />
        ),
        description: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              lineHeight: '18px',
            }}
          >
            <Typography
              sx={{
                color: theme.palette.system.skyBlue,
                font: 'normal normal normal 14px/20px Open Sans',
                fontSize: '14px',
                variant: 'body1',
                fontWeight: '600',
              }}
            >
              Want to Get Ahead Next Time?
            </Typography>
            <Typography
              sx={{
                color: theme.palette.system.coolGray,
                font: 'normal normal normal 14px/20px Open Sans',
                fontSize: '14px',
                variant: 'body1',
              }}
            >
              Update preferences to auto-apply.
            </Typography>
          </Box>
        ),
        onClick: handleLinkClick,
        sortPriority: 1,
      });
    }
    if (handleMobileQuestionClick) {
      items.push({
        leftIcon: MyContactsIcon({
          color: theme.palette.system.skyBlue,
          height: '17',
          width: '17',
        }),
        description: 'Contacts',
        onClick: handleMobileQuestionClick,
        sortPriority: 2,
      });
    }

    return items
      .filter(item => {
        return item.sortPriority >= 0;
      })
      .sort((a, b) => {
        if (a.sortPriority < b.sortPriority) return -1;
        if (a.sortPriority > b.sortPriority) return 1;
        else return 0;
      });
  }, [handleLinkClick, handleMobileQuestionClick, userStatusIsLead]);

  return (
    <Box
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CheckIconFill
          sx={{
            color: theme.palette.system.skyBlue,
            width: iconSize,
            height: iconSize,
            margin: '0px 10px',
          }}
        />
        <Typography
          sx={{
            color: theme.palette.system.midnightBlue,
            font: 'body1',
            fontSize: { xs: '14px', sm: '14px' },
            textAlign: 'center',
            padding: { xs: 0, sm: '0 4px' },
            fontWeight: { xs: 600, sm: 400 },
            lineHeight: { xs: '24px', sm: 'auto' },
            ...messageSx,
          }}
        >
          {message}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: theme.palette.system.coolGray,
          font: 'body1',
          fontSize: '14px',
          textAlign: 'center',
          [theme.breakpoints.up('sm')]: {
            width: '100px',
          },
          marginTop: {
            xs: '0px',
            sm: '0px',
          },
        }}
      >
        {description}
      </Typography>
      <Divider
        variant="fullWidth"
        sx={{
          mt: 4,
          mb: 4,
          width: '100%',
        }}
      />
      {listItems.map((item, idx) => (
        <>
          <AssignmentLink
            id={`link`}
            justifyContent="start"
            leftIcon={item.leftIcon}
            rightIcon={
              item.rightIcon ??
              ArrowRightIcon({
                color: theme.palette.system.skyBlue,
                height: '17',
                width: '17',
              })
            }
            underline="hover"
            onClick={item.onClick}
          >
            {item.description}
          </AssignmentLink>
          {++idx !== listItems.length && (
            <Divider
              variant="fullWidth"
              sx={{
                mt: 4,
                mb: 2,
                width: '100%',
              }}
            />
          )}
        </>
      ))}
    </Box>
  );
};
