import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as service from 'services/termsAndConditions/termsService';
import {
  acceptTermsAndConditions,
  fetchTerms,
  setTerms,
  termsAccepted,
} from 'store/slices/termsAndConditions/termsSlice';
import { IAcceptTerms } from 'store/slices/termsAndConditions/termsState';
import { setTermsAndConditons } from 'store/slices/user/userProfile/userProfileSlice';
import store from 'store/configureStore';
import { AppUserType } from 'enums/AppUserType';
import { logger } from 'services/logging/appInsights';

// Worker Sagas
export function* getTermsSaga(action) {
  try {
    const response = yield call(service.fetchTerms);
    const { data } = response;
    yield put(setTerms({ ...data }));
  } catch (error) {
    logger.error(error, 'getTermsSaga', 'termsSaga.ts');
  }
}

export function* acceptTermsAndConditionsSaga(action) {
  const termsAcceptedRequest = action.payload as IAcceptTerms;
  const userType = store.getState()?.auth?.userType;
  const isLocums = userType === AppUserType.LOCUMS ? true : false;

  try {
    const response = yield call(
      service.acceptTerms,
      termsAcceptedRequest,
      isLocums,
    );
    const { isUpdated } = response.data;
    if (isUpdated) {
      yield put(termsAccepted());
      yield put(setTermsAndConditons(isUpdated));
    }
  } catch (error) {
    logger.error(error, 'acceptTermsAndConditionsSaga', 'termsSaga.ts');
  }
}

// Watcher Sagas
function* watchGetTerms() {
  yield takeLatest(fetchTerms.type, getTermsSaga);
}

function* watchAcceptTermsAndConditions() {
  yield takeLatest(acceptTermsAndConditions.type, acceptTermsAndConditionsSaga);
}

// Root Terms Saga
export function* termsSaga() {
  yield all([watchGetTerms(), watchAcceptTermsAndConditions()]);
}
