import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as service from 'services/lookup/lookupService';
import { setIsPasswordResetCodeSent } from 'store/slices/authentication/resetPasswordSlice';
import { setError } from 'store/slices/error/errorSlice';
import {
  createUserCode,
  setLoadingAction,
  setVerification,
} from '../slices/verificationCode/verificationCodeSlice';
import { logger } from 'services/logging/appInsights';

function* sendVerificationCode(action) {
  yield put(setLoadingAction(true));
  try {
    const response = yield call(() =>
      service.createUserCode(action.payload.request),
    );
    const { data } = response;
    yield put(setVerification(data));
    if (action.payload.isReset) {
      yield put(setIsPasswordResetCodeSent(data.success));
    }
    yield put(setLoadingAction(false));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoadingAction(false));
    logger.error(error, 'sendVerificationCode', 'verificationSaga.ts');
  }
}

function* watchVerificationCode() {
  yield takeEvery(createUserCode.type, sendVerificationCode);
}

export function* verificationCodeSaga() {
  yield all([watchVerificationCode()]);
}
