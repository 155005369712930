export enum StrikeAssignmentSummaryStatus {
  APPLIED = 'Applied',
  SUBMITTED = 'Submitted',
  CONFIRMED = 'Confirmed',
  ASSIGNMENT_UPDATE = 'Assignment Update',
  ON_ASSIGNMENT = 'On Assignment',
  ASSIGNMENT_COMPLETE = 'Assignment Complete',
  ASSIGNMENT_ENDED = 'Assignment Ended',
}

export enum TravelAssignmentSummaryStatus {
  SUBMITTED = 'Submitted',
  BOOKED = 'Booked',
  ON_ASSIGNMENT = 'On Assignment',
  PAST_ASSIGNMENT = 'Past Assignments',
}

export enum AssignmentSummaryStatusCategory {
  APPLIED = 'Applied',
  CURRENT_SUBMISSIONS = 'Submitted',
  NOMINATED = 'Nominated',
  ASSIGNED = 'Assigned',
  BOOKED = 'Booked',
  ON_ASSIGNMENT = 'On Assignment',
  PAST_ASSIGNMENT = 'Past Assignments',
}

export enum TravelAssignmentSubStatus {
  APPLIED = 'Applied',
  PROFILE_SENT = 'Profile Sent',
  INTERVIEWED = 'Interviewed',
  OFFERED = 'Offered',
  AWAITING_CONTRACT = 'Awaiting Contract',
  CONTRACT_READY = 'Contract Ready',
  IN_CREDENTIALING = 'In Credentialing',
  CREDENTIALING_COMPLETE = 'Credentials Complete',
  CLEAR_TO_START = 'Clear to Start',
}

export enum StrikeAssignmentSubStatus {
  NONE = '',
  POST_NOTICE = 'Strike Notice Received',
  TRAVEL_BOOKED = 'Travel Booked',
  TRAVEL_CONFIRMED = 'Travel Confirmed',
  CLEARED_TO_TRAVEL = 'Cleared To Travel',
  ARRIVED = 'Arrived',
  PENDING_REQUIREMENTS = 'Pending Requirements',
  PENDING_NOTICE = 'Pending Strike Notice',
  PENDING_TRAVEL_CONFIRMATION = 'Pending Travel Confirmation',
}
