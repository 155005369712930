import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { setError } from 'store/slices/error/errorSlice';
import {
  fetchSkillsChecklistsData,
  fetchSkillsChecklist,
} from 'services/skillsChecklists/skillsChecklistsService';
import { RootState } from 'store/configureStore';
import {
  getSkillsChecklists,
  setSkillsChecklists,
  getNurseSkillList,
  setNurseSkillsList,
  getAlliedSkillList,
  setAlliedSkillsList,
} from 'store/slices/skillsChecklists/skillsChecklistsSlice';
import { logger } from 'services/logging/appInsights';

const userId = (state: RootState) => state.auth.userId;

export function* skillsChecklistsSaga() {
  yield all([
    skillsChecklistsWatcherSaga(),
    skillListWatcherSage(),
    alliedSkillListWatcherSaga(),
  ]);
}

/**
 *  Generator function triggered from rootSaga which yield on take latest for the action type
 */
function* skillsChecklistsWatcherSaga() {
  yield takeLatest(getSkillsChecklists.type, getSkillsChecklistsData);
}

function* skillListWatcherSage() {
  yield takeLatest(getNurseSkillList.type, getNurseSkillsList);
}

function* alliedSkillListWatcherSaga() {
  yield takeLatest(getAlliedSkillList.type, getAlliedSkillsList);
}

/**
 *  Watcher function to fetch skills checklists detail by user id
 *
 */
function* getSkillsChecklistsData() {
  let currentUserId = yield select(userId);
  try {
    const skillsChecklistsData = yield call(() =>
      fetchSkillsChecklistsData(currentUserId),
    );
    let { data } = skillsChecklistsData;
    data = data !== '' ? data : [];
    yield put(setSkillsChecklists(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getSkillsChecklistsData', 'skillsChecklistsSaga.ts');
  }
}

function* getNurseSkillsList() {
  let currentUserId = yield select(userId);
  try {
    const skillList = yield call(() =>
      fetchSkillsChecklist(currentUserId, 'travel'),
    );
    let { data } = skillList;
    data = data !== '' ? data : [];
    yield put(setNurseSkillsList(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getNurseSkillsList', 'skillsChecklistsSaga.ts');
  }
}

function* getAlliedSkillsList() {
  let currentUserId = yield select(userId);
  try {
    const skillList = yield call(() =>
      fetchSkillsChecklist(currentUserId, 'allied'),
    );
    let { data } = skillList;
    data = data !== '' ? data : [];
    yield put(setAlliedSkillsList(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getAlliedSkillsList', 'skillsChecklistsSaga.ts');
  }
}
