import {
  INotificationInfo,
  INotificationReadInfo,
  INotificationsRequest,
} from 'interfaces/Notifications/INotifications';
import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';

export async function fetchNotifications(notifReq: INotificationsRequest) {
  try {
    let notifQuery = `notifications/${notifReq.userId}`;

    if (notifReq.limit) notifQuery += `?limit=${notifReq.limit}`;
    if (notifReq.offset) notifQuery += `&offset=${notifReq.offset}`;

    return await http.get<string>(notifQuery);
  } catch (error) {
    logger.error(error, 'fetchNotifications', 'notificationsService.ts');
    return error;
  }
}

export async function fetchUnreadNotificationsCount(userId: string) {
  try {
    let notifQuery = `notifications/${userId}/count`;
    return await http.get<string>(notifQuery);
  } catch (error) {
    logger.error(
      error,
      'fetchUnreadNotificationsCount',
      'notificationsService.ts',
    );
    return error;
  }
}

export async function markNotificationAsReadService(
  userId: string,
  notifications: INotificationReadInfo[],
  markAllRead: boolean = false,
) {
  let notifQuery = `notifications/${userId}`;
  return await http.patch(notifQuery, {
    notifications,
    markAllRead,
  });
}

export async function markNotificationAsDeleteService(
  userId: string,
  notifications: INotificationInfo[],
) {
  let deleteNotificationQuery = `notifications/${userId}/delete`;
  return await http.patch(deleteNotificationQuery, {
    notifications,
  });
}

export async function markNotificationAsUnReadService(
  userId: string,
  notifications: INotificationReadInfo[],
) {
  let notifQuery = `notifications/${userId}/unread`;
  return await http.patch(notifQuery, {
    notifications,
  });
}
