import { createSvgIcon } from '@mui/material';
import React from 'react';

export const PinpointFilledIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <g clipPath="url(#clip0_4168_24)">
      <path
        d="M9.00019 0.974121C7.59054 0.988193 6.21626 1.41713 5.04888 2.2074C3.8815 2.99767 2.97272 4.11427 2.436 5.41781C1.89927 6.72136 1.75836 8.15412 2.03086 9.53725C2.30335 10.9204 2.97718 12.1926 3.96825 13.1952L3.98198 13.2084L8.5914 17.8562C8.64488 17.9103 8.70854 17.9532 8.77871 17.9825C8.84888 18.0118 8.92416 18.0268 9.00019 18.0268C9.07623 18.0268 9.15151 18.0118 9.22168 17.9825C9.29184 17.9532 9.35551 17.9103 9.40898 17.8562L13.9933 13.2326C14.0018 13.225 14.0212 13.2061 14.0293 13.1975C15.0214 12.1955 15.6963 10.9232 15.9696 9.53981C16.2429 8.15639 16.1025 6.72311 15.566 5.41902C15.0295 4.11492 14.1205 2.99784 12.9528 2.20731C11.7851 1.41678 10.4103 0.987854 9.00019 0.974121ZM9.00019 10.8035C8.47202 10.8079 7.95447 10.6552 7.51321 10.3649C7.07195 10.0746 6.72687 9.65976 6.52175 9.17303C6.31663 8.6863 6.26072 8.1496 6.36111 7.63104C6.4615 7.11248 6.71366 6.63543 7.08561 6.26042C7.45756 5.8854 7.93253 5.62933 8.45025 5.52468C8.96797 5.42004 9.5051 5.47155 9.9935 5.67267C10.4819 5.87379 10.8995 6.21545 11.1935 6.65432C11.4874 7.09318 11.6443 7.60946 11.6443 8.13765C11.6462 8.84145 11.3689 9.51728 10.8732 10.0169C10.3776 10.5166 9.70399 10.7998 9.00019 10.8035Z"
        fill="#75787B"
      />
    </g>
    <defs>
      <clipPath id="clip0_4168_24">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>,
  'PinpointFilledIcon',
);
