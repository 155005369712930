import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { httpSuccess } from 'helpers/serviceHelper';
import { getBannersSvc } from 'services/banners/bannersService';
import { RootState } from 'store/configureStore';
import { bannersActions } from 'store/slices/banners/bannersSlice';
import { logger } from 'services/logging/appInsights';

const trackedBannersGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-banners');

const userIdSelector = (state: RootState) => state.auth.userId;

function* fetchBanners() {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(trackedBannersGet, getBannersSvc, userId);
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(bannersActions.setBanners(data?.banners));
    }
  } catch (error: any) {
    logger.error(error, 'fetchBanners', 'bannersSaga.ts');
  }
}

export function* bannersSaga() {
  yield all([takeLatest(bannersActions.getBanners.type, fetchBanners)]);
}
