import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { StatusCode } from 'enums/StatusCode';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { retrieveCredentialStatus } from 'store/slices/credentialStatus/credentialStatusSlice';
import { theme } from 'styles/theme';
import CredentialPercentage from './CredentialPercentage';
import CredentialStatusContent from './CredentialStatusContent';
import CredentialStatusSkeleton from './CredentialStatusSkeleton';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { FeatureToggles } from 'enums/featureToggles';

interface ICredentialStatusProps {
  jobId: string | undefined;
  styles?: SxProps;
}

const CredentialStatus: FC<ICredentialStatusProps> = ({ jobId, styles }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const error = useAppSelector(state => {
    return state.credentialStatus.error;
  });
  const credentialStatus = useAppSelector(state => {
    return state.credentialStatus;
  });

  const isNewJobDetailsPageEnabled = useFeatureToggle(
    FeatureToggles.ENABLE_NEW_JOB_DETAILS_PAGE,
  );

  const toggleDrawer = (
    event: React.KeyboardEvent | React.MouseEvent,
    open: boolean,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    event && event.stopPropagation();
    setOpen(open);
  };

  useEffect(
    () => {
      jobId && dispatch(retrieveCredentialStatus(jobId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return credentialStatus?.isloading ? (
    <Box
      gap={1}
      sx={{
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row',
        },
        paddingBottom: isNewJobDetailsPageEnabled ? '0px' : '6px',
        height: isNewJobDetailsPageEnabled ? '100%' : 'auto',
        ...styles?.['skeleton'],
      }}
    >
      <CredentialStatusSkeleton />
    </Box>
  ) : (
    <>
      <Box
        id="credential-status"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          cursor: 'pointer',
          padding: '12px 0px',
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
          },
          ...styles,
        }}
        onClick={e => toggleDrawer(e, true)}
      >
        <CredentialPercentage
          width={styles?.['progress']?.width ?? '30px'}
          height={styles?.['progress']?.height ?? '30px'}
          marginRight="0px"
          fontSize={
            credentialStatus?.credentialStatus?.compliantPercentage === 100
              ? '8px'
              : '10px'
          }
          credentialPercentage={
            credentialStatus?.credentialStatus?.compliantPercentage ?? 0
          }
        ></CredentialPercentage>

        <Box
          sx={{
            display: 'inline-flex',
            width: '100%',
            paddingLeft: '10px',
            [theme.breakpoints.down('lg')]: {
              paddingLeft: '0px',
              marginTop: '10px',
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '0px',
              paddingLeft: '10px',
            },
            ...styles?.['text'],
          }}
        >
          <Button
            sx={{
              width: '100% !important',
              height: 'auto',
              textTransform: 'none',
              color: 'system.skyBlue',
              font: 'normal normal normal 14px/20px Open Sans',
              letterSpacing: '0px',
              padding: '0px !important',
              '&:hover': {
                background: theme.palette.system.white,
              },
              '& .MuiButton-endIcon': {
                marginLeft: 'auto',
              },
            }}
            variant="text"
            endIcon={<ArrowForwardIosIcon fontSize="small" />}
          >
            <Typography
              color="system.skyblue"
              variant="body1"
              sx={{
                color: 'system.skyBlue',
                font: 'normal normal normal 14px/20px Open Sans',
                letterSpacing: '0px',
              }}
            >
              Credential Status
            </Typography>
          </Button>
        </Box>
        <DrawerPanel
          contentName="Credential Status"
          open={open}
          toggleDrawer={toggleDrawer}
          showFooter={false}
          alertId={
            error && error?.errorCode >= StatusCode.InternalServerError
              ? 'credential-status'
              : undefined
          }
        >
          <CredentialStatusContent />
        </DrawerPanel>
      </Box>
    </>
  );
};

export default CredentialStatus;
