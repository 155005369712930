import lookup from 'country-code-lookup';
import { AddressType } from 'enums/addressType';
import { PhoneNumberType } from 'enums/phoneNumberType';
import { SocialMediaSite } from 'enums/socialMediaSite';
import { IAddress } from 'interfaces/Common/IAddress';
import { defaultCountry } from 'interfaces/Props/Default/defaultCountry';
import { IPhoneNumber } from 'interfaces/User/Contact/IPhoneNumber';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { IUserProfileRequest } from 'interfaces/User/UserProfile/IUserProfileRequest';
import moment from 'moment';
import { logger } from 'services/logging/appInsights';

export const extractServiceData = (userProfile: IUserProfile) => ({
  sameAddress: false,
  userId: userProfile.userId,
  emailAddress: userProfile.emailAddress,
  emailAddress2: userProfile.emailAddress2,
  mobilePhone: exctractPhoneField(
    PhoneNumberType.CELLPHONE,
    userProfile.phoneNumbers,
  ),
  homePhone: exctractPhoneField(
    PhoneNumberType.PERMANENT,
    userProfile.phoneNumbers,
  ),
  fax: exctractPhoneField(PhoneNumberType.FAX, userProfile.phoneNumbers),
  primarySocialMedia: userProfile.socialMedia.preferredId ?? null,
  linkedInUrl: extractLink(
    SocialMediaSite.LinkedIn,
    userProfile.socialMedia.links,
  ),
  facebookUrl: extractLink(
    SocialMediaSite.Facebook,
    userProfile.socialMedia.links,
  ),
  instagramUrl: extractLink(
    SocialMediaSite.Instagram,
    userProfile.socialMedia.links,
  ),
  twitterUrl: extractLink(
    SocialMediaSite.Twitter,
    userProfile.socialMedia.links,
  ),
  tiktokUrl: extractLink(SocialMediaSite.TikTok, userProfile.socialMedia.links),
  permanentAddress: extractAddressField(
    AddressType.PERMANENT,
    userProfile.addresses,
  ),
  taxAddress: extractAddressField(AddressType.TAXHOME, userProfile.addresses),
  emergencyContact: !!userProfile?.emergencyContact
    ? extractEmergencyContactField(userProfile?.emergencyContact)
    : null,
  birthDate: userProfile.birthDate
    ? moment(userProfile.birthDate).startOf('day')
    : null,
});

export const extractServiceDataWithDefaultEmergencyContact = (
  userProfile: IUserProfile,
) => ({
  sameAddress: false,
  userId: userProfile.userId,
  emailAddress: userProfile.emailAddress,
  emailAddress2: userProfile.emailAddress2,
  mobilePhone: exctractPhoneField(
    PhoneNumberType.CELLPHONE,
    userProfile.phoneNumbers,
  ),
  homePhone: exctractPhoneField(
    PhoneNumberType.PERMANENT,
    userProfile.phoneNumbers,
  ),
  fax: exctractPhoneField(PhoneNumberType.FAX, userProfile.phoneNumbers),
  primarySocialMedia: userProfile.socialMedia.preferredId ?? null,
  linkedInUrl: extractLink(
    SocialMediaSite.LinkedIn,
    userProfile.socialMedia.links,
  ),
  facebookUrl: extractLink(
    SocialMediaSite.Facebook,
    userProfile.socialMedia.links,
  ),
  instagramUrl: extractLink(
    SocialMediaSite.Instagram,
    userProfile.socialMedia.links,
  ),
  twitterUrl: extractLink(
    SocialMediaSite.Twitter,
    userProfile.socialMedia.links,
  ),
  tiktokUrl: extractLink(SocialMediaSite.TikTok, userProfile.socialMedia.links),
  permanentAddress: extractAddressField(
    AddressType.PERMANENT,
    userProfile.addresses,
  ),
  taxAddress: extractAddressField(AddressType.TAXHOME, userProfile.addresses),
  emergencyContact: extractEmergencyContactField(userProfile?.emergencyContact),
  birthDate: userProfile.birthDate
    ? moment(userProfile.birthDate).startOf('day')
    : null,
});

export const dirtyValues = (
  dirtyFields: object | boolean,
  allValues: object,
): object => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};
const exctractPhoneField = (type: PhoneNumberType, sourceData) =>
  sourceData.find(item => item.phoneNumberType === type);

const extractAddressField = (type: AddressType, sourceData) => {
  let address: IAddress;
  address = sourceData.find(item => item.addressType === type);

  if (!address)
    address = {
      addressType: type,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: defaultCountry.countryCode,
      iso2CountryCode: defaultCountry.countryCode,
    };

  const iso2 = getIso2CountryCode(address.country);

  return {
    addressType: type,
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    city: address?.city || '',
    state: address?.state || '',
    zipCode: address?.zipCode || '',
    country: !!iso2
      ? address.country
      : type === AddressType.COMPANYASSIGNMENT
      ? ''
      : defaultCountry.countryCode,
    iso2CountryCode: !!iso2
      ? iso2
      : type === AddressType.COMPANYASSIGNMENT
      ? ''
      : 'US',
  };
};

const extractEmergencyContactField = contact => {
  const iso2 = getIso2CountryCode(contact?.country);
  return {
    name: contact?.name || '',
    relationship: contact?.relationship || '',
    phoneNumber: contact?.phoneNumber,
    address1: contact?.address1 || '',
    address2: contact?.address2 || '',
    city: contact?.city || '',
    state: contact?.state || '',
    zipCode: contact?.zipCode || '',
    country: !!iso2 ? contact?.country : '',
    iso2CountryCode: !!iso2 ? iso2 : '',
  };
};

/**
 * getIso2CountryCode, Swallow the exception when invalid country code is supplied
 * @param country The country name or iso code
 * @returns the iso2value or undefined or '/
 */
const getIso2CountryCode = (country: string | undefined) => {
  if (!country) return '';

  let iso2CountryCode;

  try {
    iso2CountryCode = lookup.byIso(country)?.iso2;
    return iso2CountryCode;
  } catch (error) {
    logger.error(error, 'getIso2CountryCode', 'UserProfileHelper.ts');
    return '';
  }
};

const extractLink = (type: SocialMediaSite, sourceData) => {
  return sourceData.find(item => item.id === type)?.['url'];
};

function assignNullOrValue(value) {
  return value !== undefined && value !== null && value !== '' ? value : null;
}

export const getPostData = (userProfile: any): IUserProfileRequest =>
  ({
    userId: userProfile.userId,
    birthDate: userProfile.birthDate
      ? moment(userProfile.birthDate).startOf('day')
      : null,
    emailAddress: userProfile.emailAddress,
    emailAddress2: assignNullOrValue(userProfile.emailAddress2),
    socialMedia: {
      preferredId:
        userProfile.primarySocialMedia === '0'
          ? undefined
          : userProfile.primarySocialMedia,
      links: extractLinksArray(userProfile) ?? [],
    },
    phoneNumbers: extractPhoneArray(userProfile),
    addresses: extractAddressArray(userProfile),
    emergencyContact:
      userProfile?.emergencyContact != null
        ? {
            ...userProfile.emergencyContact,
            phoneNumber: userProfile?.emergencyContact?.phoneNumber,
            iso2CountryCode: getIso2CountryCode(
              userProfile?.emergencyContact?.country,
            ),
          }
        : null,
  } as IUserProfileRequest);

const extractAddressArray = (userProfile: any): IAddress[] => {
  const result = (({ permanentAddress, taxAddress }) => ({
    permanentAddress,
    taxAddress,
  }))(userProfile);

  return Object.keys(result)
    .map(key => userProfile[key])
    .filter(
      address =>
        address &&
        (address.address1 || address.city || address.state || address.zipCode),
    );
};

const extractPhoneArray = (userProfile: any): IPhoneNumber[] => {
  const result = (({ mobilePhone, homePhone, fax }) => ({
    mobilePhone,
    homePhone,
    fax,
  }))(userProfile);
  return Object.keys(result)
    .filter(a => userProfile[a] !== undefined)
    .filter(a => result[a]?.phoneNumber !== '')
    .map(key => userProfile[key]);
};

const extractLinksArray = (userProfile: any) => {
  const result = (({
    linkedInUrl,
    twitterUrl,
    facebookUrl,
    instagramUrl,
    tiktokUrl,
  }) => ({
    linkedInUrl,
    twitterUrl,
    facebookUrl,
    instagramUrl,
    tiktokUrl,
  }))(userProfile);
  return Object.keys(result).map(key => {
    switch (key) {
      case 'linkedInUrl':
        return {
          id: SocialMediaSite.LinkedIn,
          name: SocialMediaSite[SocialMediaSite.LinkedIn],
          url: userProfile[key],
        };
      case 'twitterUrl':
        return {
          id: SocialMediaSite.Twitter,
          name: SocialMediaSite[SocialMediaSite.Twitter],
          url: userProfile[key],
        };
      case 'facebookUrl':
        return {
          id: SocialMediaSite.Facebook,
          name: SocialMediaSite[SocialMediaSite.Facebook],
          url: userProfile[key],
        };
      case 'instagramUrl':
        return {
          id: SocialMediaSite.Instagram,
          name: SocialMediaSite[SocialMediaSite.Instagram],
          url: userProfile[key],
        };
      case 'tiktokUrl':
        return {
          id: SocialMediaSite.TikTok,
          name: SocialMediaSite[SocialMediaSite.TikTok],
          url: userProfile[key],
        };
    }
  });
};
