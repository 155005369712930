import { defaultStrikeRecruiter } from 'components/AssignmentDetail/AssignmentContacts/constants';
import { CredentialStatusOptions } from 'constants/assignmentLinkStackOptions';
import { IActionItemStatusType } from 'enums/actionItemStatusType';
import { IActionItemTaskCenterCategoryTypeId } from 'enums/actionItemTaskCenterCategoryTypeId';
import { actionItemTypeId } from 'enums/actionType';
import { ActivityStatusId } from 'enums/activityStatusId';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import {
  StrikeAssignmentSubStatus,
  StrikeAssignmentSummaryStatus,
  TravelAssignmentSubStatus,
  TravelAssignmentSummaryStatus,
} from 'enums/assignmentStatus';
import { AssignmentType } from 'enums/assignmentType';
import { ContractStatusIdStates } from 'enums/contracts';
import { EmployeeRoleId } from 'enums/employeeRoleId';
import { ScheduleRank } from 'enums/scheduleRank';
import { TransportationTypeId } from 'enums/transportationTypeId';
import { getAssignmentStatus } from 'helpers/getAssignmentStatus';
import { IActionItem } from 'interfaces/ActionItem/IActionItem';
import { IAssignmentContactsMap } from 'interfaces/Assignment/AssignmentContactsMap';
import {
  IAssignmentDetail,
  ICredentialSummary,
  IScheduleShift,
  IStrikeDetails,
  IWaves,
} from 'interfaces/Assignment/AssignmentDetail';
import {
  AssignmentSummaryItem,
  IAssignmentContacts,
} from 'interfaces/Assignment/AssignmentSummaryItem';
import { IContract } from 'interfaces/Contracts/Contract';
import { IContact } from 'interfaces/User/Contact/IContact';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { IContactResponse } from 'types/ContactResponse';

export const assignmentContactsMap: IAssignmentContactsMap[] = [
  {
    assignmentSummaryStatus: TravelAssignmentSummaryStatus.SUBMITTED,
    assignmentRoles: [AssignmentRoleId.RECRUITER],
  },
  {
    assignmentSummaryStatus: TravelAssignmentSummaryStatus.BOOKED,
    assignmentRoles: [
      AssignmentRoleId.CLINICAL_MANAGER,
      AssignmentRoleId.CREDENTIALING_ANALYST,
      AssignmentRoleId.RECRUITER,
    ],
  },
  {
    assignmentSummaryStatus: TravelAssignmentSummaryStatus.ON_ASSIGNMENT,
    assignmentRoles: [
      AssignmentRoleId.CUSTOMER_SUPPORT_PAYROLL,
      AssignmentRoleId.RECRUITER,
    ],
  },
  {
    assignmentSummaryStatus: TravelAssignmentSummaryStatus.PAST_ASSIGNMENT,
    assignmentRoles: [AssignmentRoleId.RECRUITER],
  },

  {
    assignmentSummaryStatus: StrikeAssignmentSummaryStatus.APPLIED,
    assignmentRoles: [
      AssignmentRoleId.CLINICAL_MANAGER,
      AssignmentRoleId.CREDENTIALING_ANALYST,
      AssignmentRoleId.RECRUITER,
    ],
  },
  {
    assignmentSummaryStatus: StrikeAssignmentSummaryStatus.SUBMITTED,
    assignmentRoles: [
      AssignmentRoleId.CLINICAL_MANAGER,
      AssignmentRoleId.CREDENTIALING_ANALYST,
      AssignmentRoleId.RECRUITER,
    ],
  },
  {
    assignmentSummaryStatus: StrikeAssignmentSummaryStatus.CONFIRMED,
    assignmentRoles: [
      AssignmentRoleId.CLINICAL_MANAGER,
      AssignmentRoleId.CREDENTIALING_ANALYST,
      AssignmentRoleId.RECRUITER,
    ],
  },
  {
    assignmentSummaryStatus: StrikeAssignmentSummaryStatus.ON_ASSIGNMENT,
    assignmentRoles: [
      AssignmentRoleId.CUSTOMER_SUPPORT_PAYROLL,
      AssignmentRoleId.RECRUITER,
    ],
  },
  {
    assignmentSummaryStatus: StrikeAssignmentSummaryStatus.ASSIGNMENT_UPDATE,
    assignmentRoles: [AssignmentRoleId.RECRUITER],
  },
  {
    assignmentSummaryStatus: StrikeAssignmentSummaryStatus.ASSIGNMENT_COMPLETE,
    assignmentRoles: [AssignmentRoleId.RECRUITER],
  },
  {
    assignmentSummaryStatus: StrikeAssignmentSummaryStatus.ASSIGNMENT_ENDED,
    assignmentRoles: [AssignmentRoleId.RECRUITER],
  },
];

export const getAssignmentContacts = (
  assignmentActivityStatus: string,
  assignmentActivityStatusId: number,
  contacts: IAssignmentContacts[] | undefined,
  isStrike: boolean,
  userContacts: IContactResponse,
) => {
  let defaultContactArray: IContact[] = [];

  let onAssignmentRolesToShow = assignmentContactsMap
    .filter(e => e?.assignmentSummaryStatus === assignmentActivityStatus)
    .map(e => e?.assignmentRoles)
    .flat();

  let assignmentContacts =
    contacts?.filter(e => onAssignmentRolesToShow.includes(e.employeeRoleId)) ||
    [];

  if (isStrike) {
    defaultContactArray.push(defaultStrikeRecruiter);
    let strikeCredAnalyst = (userContacts?.strikeContacts || [])?.filter(
      e =>
        assignmentActivityStatusId &&
        assignmentActivityStatusId >= ActivityStatusId.TravelerAccepts &&
        assignmentActivityStatusId <= ActivityStatusId.AssignmentComplete &&
        e.employeeRoleId === EmployeeRoleId.CREDENTIALING_ANALYST,
    );
    defaultContactArray = [...defaultContactArray, ...strikeCredAnalyst];
  } else {
    if (assignmentContacts?.length === 0) {
      defaultContactArray = (userContacts?.userContacts || [])?.filter(
        e => e.employeeRoleId === EmployeeRoleId.RECRUITER,
      );
    }
  }

  return {
    assignmentContact: contacts,
    defaultContact: defaultContactArray,
  };
};

export const getOutstandingContractsAmount = (
  contracts: IContract[] | null | undefined,
): number => {
  return (
    (contracts || [])?.filter(
      c => c.statusId === ContractStatusIdStates.READY_TO_SIGN,
    )?.length ?? 0
  );
};

export const getRemainingCredentialsForAssignment = (
  credentialSummary: ICredentialSummary | null | undefined,
): number => {
  let remainingCreds = 0;
  if (!credentialSummary || _isEmpty(credentialSummary)) return remainingCreds;
  if (!credentialSummary.isCompliant) {
    remainingCreds =
      credentialSummary.totalCredentials -
      (credentialSummary.compliantCredentials +
        credentialSummary.pendingCredentials);
  }
  return remainingCreds;
};

export const getRemainingCredentialPercentageForAssignment = (
  credentialSummary: ICredentialSummary | null | undefined,
) => {
  let credentialPercentComplete = 0;
  if (
    credentialSummary?.compliantCredentials &&
    credentialSummary?.totalCredentials
  ) {
    credentialPercentComplete = Math.trunc(
      (credentialSummary?.compliantCredentials /
        credentialSummary?.totalCredentials) *
        100,
    );
  }
  return credentialPercentComplete >= 0 ? credentialPercentComplete : 100;
};

export const getCredentialStatusBadgeContent = (
  credentialSummary: ICredentialSummary | null | undefined,
) => {
  if (!credentialSummary || _isEmpty(credentialSummary)) return '';

  return getRemainingCredentialsForAssignment(credentialSummary) > 0
    ? ''
    : Number(credentialSummary.pendingCredentials) > 0
    ? CredentialStatusOptions.PENDING
    : Number(credentialSummary.compliantCredentials) > 0
    ? CredentialStatusOptions.DONE
    : '';
};

export const showTravelDetailsListItem = (
  isStrike: boolean,
  strikeDetails: IStrikeDetails,
  activityStatusId: number,
) => {
  const assignmentStatus = getAssignmentStatus(
    activityStatusId,
    isStrike,
    strikeDetails,
  );
  return (
    isStrike &&
    !!strikeDetails?.noticeDate &&
    (!!strikeDetails?.travelToStrike?.itineraryLink ||
      strikeDetails?.transportationTypeId === TransportationTypeId.DRIVE) &&
    (assignmentStatus === StrikeAssignmentSummaryStatus.SUBMITTED ||
      assignmentStatus === StrikeAssignmentSummaryStatus.CONFIRMED ||
      assignmentStatus === StrikeAssignmentSummaryStatus.ON_ASSIGNMENT ||
      assignmentStatus === StrikeAssignmentSummaryStatus.ASSIGNMENT_COMPLETE)
  );
};

// update this array with any additional qualification item types
// updating this list should add the appropriate items to the drawer, update the count and determine whether 'Qualification Items' link is seen on home page assignment cards and assignment page details
export const qualificationItemTargetTypes = [
  actionItemTypeId.SkillsChecklistUpdateRequest,
  actionItemTypeId.StrikeEducationUpdateRequest,
  actionItemTypeId.WorkHistoryExperienceUpdateRequest,
  actionItemTypeId.WorkHistoryGapUpdate,
  actionItemTypeId.LicenseUpdate,
  actionItemTypeId.CertificationsUpdate,
];

export const ssnDobTargetTypes = [
  actionItemTypeId.SocialSecurityNumber,
  actionItemTypeId.SocialSecurityNumberUpdate,
  actionItemTypeId.DateOfBirth,
  actionItemTypeId.DateOfBirthUpdate,
];

export const showQualificationItemsListItem = (
  actionItems: IActionItem[],
  activityStatusId: number,
  isStrike: boolean,
  taskCenterOpenRequirements?: any,
) => {
  const hasOpenActionItems =
    actionItems &&
    actionItems.length > 0 &&
    actionItems.some(
      actionItem => actionItem.stateTypeId === IActionItemStatusType.Open,
    );

  const hasStrikeActionItems = actionItems.some(
    actionItem =>
      actionItem.taskCenterCategoryTypeId ===
        IActionItemTaskCenterCategoryTypeId.Strike &&
      actionItem.stateTypeId === IActionItemStatusType.Open,
  );

  const hasSsnOrDobActionItem = taskCenterOpenRequirements?.some(
    item =>
      item?.actionItemTypeId &&
      ssnDobTargetTypes.includes(item?.actionItemTypeId),
  );

  return (
    activityStatusId < ActivityStatusId.GoodToGo &&
    (hasOpenActionItems ||
      (isStrike && hasStrikeActionItems) ||
      hasSsnOrDobActionItem)
  );
};

export const getOutstandingQualificationItemsAmount = (
  actionItems: IActionItem[],
  taskCenterOpenRequirements?: any,
): number => {
  let totalCount = 0;
  totalCount =
    actionItems?.filter(
      actionItem => actionItem.stateTypeId === IActionItemStatusType.Open,
    ).length ?? 0;
  if (Array.isArray(taskCenterOpenRequirements)) {
    totalCount +=
      taskCenterOpenRequirements?.filter(
        item =>
          item?.actionItemTypeId &&
          ssnDobTargetTypes.includes(item?.actionItemTypeId),
      ).length ?? 0;
  }
  return totalCount;
};

export const showProgressTracker = (
  isStrike: boolean,
  assignmentDetails: IAssignmentDetail,
  showSubmissionSentWidget?: boolean,
) => {
  const idsToCheck = [3, 8, 6, 9, 10];
  return (
    !isStrike &&
    showSubmissionSentWidget &&
    assignmentDetails.sendDate &&
    assignmentDetails.assignmentActivityStatus ===
      TravelAssignmentSummaryStatus.SUBMITTED &&
    assignmentDetails?.activityStatusId &&
    idsToCheck.includes(assignmentDetails.activityStatusId)
  );
};

export const showStrikeEventSchedule = (
  assignmentActivityStatus,
  activityStatusId,
  strike,
  isStrike,
) => {
  const isOnAssignment =
    assignmentActivityStatus === StrikeAssignmentSummaryStatus.ON_ASSIGNMENT;

  const isAssignmentComplete =
    activityStatusId === ActivityStatusId.AssignmentComplete;

  const isAssignmentConfirmed =
    assignmentActivityStatus === StrikeAssignmentSummaryStatus.CONFIRMED;

  return (
    isStrike &&
    (isOnAssignment ||
      isAssignmentComplete ||
      (isAssignmentConfirmed && strike?.arrived)) &&
    strike?.waves &&
    strike.waves.length > 0
  );
};

export const showScheduleRankForAssignment = (
  assignment: AssignmentSummaryItem | IAssignmentDetail,
) => {
  const currentWaveDetails = findCurrentWave(assignment?.strike?.waves);
  return (
    currentWaveDetails &&
    currentWaveDetails.rank !== ScheduleRank.Unscheduled &&
    assignment.assignmentActivityStatus ===
      StrikeAssignmentSummaryStatus.CONFIRMED &&
    assignment?.typeId === AssignmentType.STRIKE &&
    (assignment.strike.travelConfirmed ||
      assignment.strike.okayToTravel ||
      assignment.strike.arrived)
  );
};

// Function to find the current wave or return null
export const findCurrentWave = waves => {
  if (waves && waves.length > 0) {
    const today = new Date().setHours(0, 0, 0, 0);
    let currentWaveObj;
    currentWaveObj = waves.find(x => {
      if (x?.startDate && x?.endDate) {
        const startDate = new Date(x.startDate).setHours(0, 0, 0, 0);
        const endDate = new Date(x.endDate).setHours(0, 0, 0, 0);
        return today >= startDate && today <= endDate;
      }
      return false;
    });

    return currentWaveObj;
  }
  return null;
};

// Function to find the upcoming wave or return null
export const findUpcomingWave = (waves: IWaves[] | null) => {
  if (waves && waves.length > 0) {
    const currentWave = findCurrentWave(waves);

    if (currentWave && currentWave.endDate) {
      const currentWaveEndDate = new Date(currentWave.endDate);
      currentWaveEndDate.setUTCHours(0, 0, 0, 0);
      // upcoming wave can be  within 5 days from current wave startdate
      const fiveDaysAfterCurrentWave = new Date(currentWaveEndDate);
      fiveDaysAfterCurrentWave.setUTCHours(0, 0, 0, 0);
      fiveDaysAfterCurrentWave.setDate(fiveDaysAfterCurrentWave.getDate() + 5);

      const upcomingWave = waves.find(x => {
        if (x.startDate) {
          const startDate = new Date(x.startDate);
          startDate.setUTCHours(0, 0, 0, 0);
          return (
            startDate > currentWaveEndDate &&
            startDate <= fiveDaysAfterCurrentWave
          );
        }
        return false;
      });

      return upcomingWave || null;
    }
  }
  return null;
};

export const showTravelDetailsAlert = (
  isStrike: boolean,
  strikeDetails: IStrikeDetails,
) => {
  return (
    isStrike &&
    !!strikeDetails?.datesConfirmed &&
    !strikeDetails.travelConfirmed
  );
};

export const showReimbursementsListItem = (
  assignment: AssignmentSummaryItem | IAssignmentDetail | undefined,
  isStrike: boolean,
): boolean => {
  let showReimbursements = false;
  if (!!assignment) {
    const isArrived = assignment?.strike?.arrived;

    let showByStatus = isStrike
      ? assignment.activityStatusId === ActivityStatusId.TravelerAccepts ||
        assignment.activityStatusId === ActivityStatusId.Booked ||
        assignment.activityStatusId === ActivityStatusId.WaitingGoodToGo ||
        assignment.activityStatusId === ActivityStatusId.PostponementWarning ||
        assignment.activityStatusId === ActivityStatusId.GoodToGo ||
        assignment.activityStatusId === ActivityStatusId.OnAssignment ||
        assignment.activityStatusId === ActivityStatusId.AssignmentComplete ||
        assignment.activityStatusId ===
          ActivityStatusId.AssignmentCanceledByTraveler ||
        assignment.activityStatusId ===
          ActivityStatusId.AssignmentCanceledByFacility
      : assignment.activityStatusId === ActivityStatusId.OnAssignment ||
        assignment.activityStatusId === ActivityStatusId.AssignmentComplete ||
        assignment.activityStatusId ===
          ActivityStatusId.AssignmentCanceledByTraveler ||
        assignment.activityStatusId ===
          ActivityStatusId.AssignmentCanceledByFacility;

    if (showByStatus) {
      let a = moment(assignment?.endDate).add(10, 'days');
      showByStatus = moment().isBefore(a);
    }
    showReimbursements = showByStatus && isArrived;
  }
  return showReimbursements;
};

export const isPastAssignment = (
  assignment: AssignmentSummaryItem | IAssignmentDetail | undefined,
) => {
  let isPastAssignment = false;
  if (!!assignment) {
    isPastAssignment =
      assignment.assignmentActivityStatus ===
        TravelAssignmentSummaryStatus.PAST_ASSIGNMENT ||
      ((assignment.assignmentActivityStatus ===
        StrikeAssignmentSummaryStatus.ASSIGNMENT_ENDED ||
        assignment.assignmentActivityStatus ===
          StrikeAssignmentSummaryStatus.ASSIGNMENT_COMPLETE) &&
        assignment.activityStatusId !==
          ActivityStatusId.PostAssignmentCanceledbySettlement &&
        assignment.activityStatusId !==
          ActivityStatusId.BookingCanceledByTraveler &&
        assignment.activityStatusId !==
          ActivityStatusId.BookingCanceledByFacility);
  }
  return isPastAssignment;
};

export const showTimeEntryListItem = (
  assignment: AssignmentSummaryItem | IAssignmentDetail | undefined,
  isStrike: boolean,
): boolean => {
  if (!assignment?.isTimeEntryEligible) return false;
  const { assignmentActivityStatus, endDate } = assignment;
  const isEndDateValid = !!endDate && moment(endDate).isValid();
  return isStrike
    ? [
        StrikeAssignmentSummaryStatus.ON_ASSIGNMENT,
        StrikeAssignmentSummaryStatus.ASSIGNMENT_COMPLETE,
      ].includes(assignmentActivityStatus as StrikeAssignmentSummaryStatus) &&
        isEndDateValid &&
        Math.abs(moment().diff(endDate, 'days')) < 10
    : [
        TravelAssignmentSummaryStatus.ON_ASSIGNMENT,
        TravelAssignmentSummaryStatus.PAST_ASSIGNMENT,
      ].includes(assignmentActivityStatus as TravelAssignmentSummaryStatus);
};

export const showContractsListItem = (
  isStrike: boolean,
  assignment: AssignmentSummaryItem | IAssignmentDetail | undefined,
) => {
  let showContracts = false;
  if (!!assignment) {
    showContracts = isStrike
      ? assignment.assignmentActivityStatus ===
          StrikeAssignmentSummaryStatus.SUBMITTED ||
        assignment.assignmentActivityStatus ===
          StrikeAssignmentSummaryStatus.CONFIRMED ||
        (assignment.assignmentActivityStatus ===
          StrikeAssignmentSummaryStatus.ON_ASSIGNMENT &&
          assignment.contracts?.length > 0)
      : assignment?.contracts?.length > 0;
  }
  return showContracts;
};

export const categorizeAssignments = (waves: IWaves[]) => {
  const currentDate = new Date();
  const combinedPastShifts: IScheduleShift[] = [];
  const combinedUpcomingShifts: IScheduleShift[] = [];

  function calculateDateDifferenceInDays(date1, date2) {
    const oneDay = 1000 * 3600 * 24;
    return Math.floor((date1 - date2) / oneDay);
  }
  //============ Iteration to categorize shifts ==============//
  waves?.forEach(wave => {
    const startDateTime = wave.startDate ? new Date(wave.startDate) : null;
    const endDateTime = wave.endDate ? new Date(wave.endDate) : null;
    startDateTime?.setHours(0, 0, 0, 0);
    endDateTime?.setHours(0, 0, 0, 0);
    currentDate?.setHours(0, 0, 0, 0);
    if (startDateTime && endDateTime) {
      if (calculateDateDifferenceInDays(endDateTime, currentDate) < 0) {
        combinedPastShifts.push(...wave.shifts);
      } else if (
        //upcoming wave's Strike Event Schedule 2 days before the current wave's end date
        calculateDateDifferenceInDays(startDateTime, currentDate) <= 2
      ) {
        wave.shifts?.forEach(shift => {
          const shiftEndDateTime = shift.endDateTime
            ? new Date(shift.endDateTime)
            : null;
          const shiftStartDateTime = shift.startDateTime
            ? new Date(shift.startDateTime)
            : null;
          shiftEndDateTime?.setHours(0, 0, 0, 0);
          shiftStartDateTime?.setHours(0, 0, 0, 0);
          if (
            (shiftEndDateTime && shiftEndDateTime < currentDate) ||
            (shiftStartDateTime && shiftStartDateTime < currentDate)
          ) {
            combinedPastShifts.push(shift);
          } else {
            combinedUpcomingShifts.push(shift);
          }
        });
      }
    }
  });
  //================================================================//
  // Return categorized shifts
  return {
    pastShifts: combinedPastShifts,
    upcomingShifts: combinedUpcomingShifts,
  };
};

export const formatDateRange = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const startMonth = startDate.toLocaleString('default', { month: 'short' });
  const endMonth = endDate.toLocaleString('default', { month: 'short' });

  if (startMonth === endMonth) {
    return `${startMonth} ${startDate.getDate()} - ${endDate.getDate()}, ${startDate.getFullYear()}`;
  } else {
    return `${startMonth} ${startDate.getDate()}, ${startDate.getFullYear()} - ${endMonth} ${endDate.getDate()}, ${endDate.getFullYear()}`;
  }
};

export const extractDatefromDateTime = dateTime => {
  return dateTime?.length ? dateTime.split('T')[0] : null;
};

export const editOrApplyConsecutiveExtensionEnabled = (
  waves?: IWaves[] | null,
) => {
  if (waves) {
    const upcomingWave = findUpcomingWave(waves);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const travelDateFormatted = upcomingWave?.travelDates?.map(
      dateString => new Date(dateString),
    );
    if (!travelDateFormatted || travelDateFormatted.length === 0) {
      return false;
    }
    travelDateFormatted.sort((a, b) => a.getTime() - b.getTime());
    const firstTravelDate = travelDateFormatted[0];
    firstTravelDate.setHours(0, 0, 0, 0);
    return today < firstTravelDate;
  }
  return false;
};

export const isContractReady = (contracts: IContract[] | null | undefined) => {
  return (contracts || [])?.some(
    contract => contract.statusId === ContractStatusIdStates.READY_TO_SIGN,
  );
};

const isContractSigned = (contracts: IContract[] | null | undefined) => {
  return (contracts || [])?.every(
    contract => contract.statusId === ContractStatusIdStates.SIGNED,
  );
};

export const getBookedSubstatus = (
  assignment: AssignmentSummaryItem | IAssignmentDetail,
) => {
  let status = '';
  const { contracts, credentialSummary } = assignment || {};
  const contractAvailable = contracts?.length > 0;
  const hasCredentialing = !!credentialSummary;
  const contractReady = isContractReady(contracts);
  const contractSigned = isContractSigned(contracts);

  const {
    isCompliant,
    pendingCredentials,
    compliantCredentials,
    totalCredentials,
  } = credentialSummary || {};

  const credentialsComplete =
    isCompliant ||
    pendingCredentials + compliantCredentials === totalCredentials;

  switch (true) {
    case !contractAvailable:
      status = TravelAssignmentSubStatus.AWAITING_CONTRACT;
      break;
    case contractAvailable && contractReady:
      status = TravelAssignmentSubStatus.CONTRACT_READY;
      break;
    case contractSigned && hasCredentialing && !credentialsComplete:
      status = TravelAssignmentSubStatus.IN_CREDENTIALING;
      break;
    case contractSigned && credentialsComplete:
      status = TravelAssignmentSubStatus.CREDENTIALING_COMPLETE;
      break;
    default:
      break;
  }
  return status;
};

export const getAssignmentSubstatus = (
  assignment: AssignmentSummaryItem | IAssignmentDetail | undefined,
): string => {
  let subStatus = '';
  const isStrike = assignment?.typeId === AssignmentType.STRIKE;

  if (isStrike) {
    const postNotice =
      assignment?.strike?.noticeDate !== null &&
      moment(assignment?.strike?.noticeDate).isSameOrBefore(moment());
    const travelBooked = assignment?.strike?.datesConfirmed;
    const travelConfirmed = assignment?.strike?.travelConfirmed;
    const clearedToTravel = assignment?.strike?.okayToTravel;
    const arrived = assignment?.strike?.arrived;
    const itineraryExists = assignment?.strike?.travelToStrike?.itineraryLink;
    switch (assignment?.assignmentActivityStatus) {
      case StrikeAssignmentSummaryStatus.APPLIED:
        subStatus = StrikeAssignmentSubStatus.PENDING_REQUIREMENTS;
        break;
      case StrikeAssignmentSummaryStatus.SUBMITTED:
        if (!postNotice) subStatus = StrikeAssignmentSubStatus.PENDING_NOTICE;
        if (postNotice) subStatus = StrikeAssignmentSubStatus.POST_NOTICE;
        if (postNotice && travelBooked && !travelConfirmed)
          subStatus = StrikeAssignmentSubStatus.POST_NOTICE;
        if (itineraryExists && postNotice && travelBooked && !travelConfirmed)
          subStatus = StrikeAssignmentSubStatus.PENDING_TRAVEL_CONFIRMATION;
        break;
      case StrikeAssignmentSummaryStatus.CONFIRMED:
        if (travelBooked)
          subStatus = StrikeAssignmentSubStatus.PENDING_TRAVEL_CONFIRMATION;
        if (travelConfirmed)
          subStatus = StrikeAssignmentSubStatus.TRAVEL_CONFIRMED;
        if (clearedToTravel)
          subStatus = StrikeAssignmentSubStatus.CLEARED_TO_TRAVEL;
        if (arrived) subStatus = StrikeAssignmentSubStatus.ARRIVED;
        break;
      default:
        break;
    }
  } else {
    switch (assignment?.activityStatusId) {
      case ActivityStatusId.WaitingForClearance:
      case ActivityStatusId.RequestFileOut:
        subStatus = TravelAssignmentSubStatus.APPLIED;
        break;
      case ActivityStatusId.Sent:
      case ActivityStatusId.OkToSend:
        subStatus = TravelAssignmentSubStatus.PROFILE_SENT;
        break;
      case ActivityStatusId.Interviewed:
        subStatus = TravelAssignmentSubStatus.INTERVIEWED;
        break;
      case ActivityStatusId.OfferByFacility:
        subStatus = TravelAssignmentSubStatus.OFFERED;
        break;
      case ActivityStatusId.OfferBookedByTraveler:
      case ActivityStatusId.TravelerAccepts:
      case ActivityStatusId.FacilityConfirms:
      case ActivityStatusId.Booked:
      case ActivityStatusId.BookingApproved:
      case ActivityStatusId.WaitingGoodToGo:
      case ActivityStatusId.PostponementWarning:
        subStatus = getBookedSubstatus(assignment);
        break;
      case ActivityStatusId.GoodToGo:
        subStatus = TravelAssignmentSubStatus.CLEAR_TO_START;
        break;
      default:
        subStatus = '';
        break;
    }
  }
  return subStatus;
};

export const showAutoSubIndicator = assignmentItem => {
  return (
    assignmentItem &&
    assignmentItem.activityStatusId < ActivityStatusId.Booked &&
    assignmentItem.isAutoSubmitted
  );
};

export const showBookedAssignmentTracker = (
  assignmentItem,
  subStatusBadgeText,
  bookedAssignmentTrackerData,
) => {
  return (
    subStatusBadgeText &&
    assignmentItem?.assignmentActivityStatus ===
      TravelAssignmentSummaryStatus.BOOKED &&
    bookedAssignmentTrackerData?.find(obj => obj.name === subStatusBadgeText)
  );
};

export const showIfGoodToGo = activityStatusId => {
  return !![
    ActivityStatusId.RequestFileOut,
    ActivityStatusId.Sent,
    ActivityStatusId.DeclinedByTraveler,
    ActivityStatusId.DeclinedByFacility,
    ActivityStatusId.Interviewed,
    ActivityStatusId.RequestCancelled,
    ActivityStatusId.OkToSend,
    ActivityStatusId.OfferByFacility,
    ActivityStatusId.OfferBookedByTraveler,
    ActivityStatusId.TravelerAccepts,
    ActivityStatusId.FacilityConfirms,
    ActivityStatusId.Booked,
    ActivityStatusId.BookingApproved,
    ActivityStatusId.WaitingGoodToGo,
    ActivityStatusId.InterviewScheduled,
    ActivityStatusId.PostponementWarning,
    ActivityStatusId.GoodToGo,
  ].find(status => status === activityStatusId);
};
