import {
  createAction,
  createSlice,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';
import { guidGenerator } from 'helpers/guidGenerator';
import { IDropdownOption } from 'interfaces/Props/IDropdownOption';
import { INonPassportTimeEntry } from 'interfaces/TimeEntry/INonPassportTimeEntry';
import { ITimeEntryRequest } from 'interfaces/TimeEntry/ITimeEntry';
import { ITimeEntryResponse } from 'interfaces/TimeEntry/ITimeEntryResponse';
import { ITimeEntryWork } from 'interfaces/TimeEntry/ITimeEntryWork';
import {
  IGuidTimesheetApprover,
  ITimesheetApprover,
} from 'interfaces/TimeEntry/ITimeSheetApprover';
import { ITimesheetApprovers } from 'interfaces/TimeEntry/ITimeSheetApprovers';
import { ITimesheetDetails } from 'interfaces/TimeEntry/ITimesheetDetails';
import {
  ISaveTimesheetAction,
  ITimesheetRequest,
} from 'interfaces/TimeEntry/ITimesheetRequest';
import { RootState } from 'store/configureStore';
import { ITimeEntryListsState, ITimeEntryState } from './timeEntryState';

const initialState: ITimeEntryState = {
  openTimesheets: [],
  closedTimesheets: [],
  isOpenTimesheet: true,
  total: 0,
  timesheetApprovers: [],
  selectedTimesheet: {},
  selectedNonPassportTimesheet: {},
  selectedTimesheetApprovers: [],
  hasSubmissionError: false,
  submissionErrorCode: 0,
  submissionSuccess: false,
  assignmentList: [],
  footerTotal: {
    hh: 0,
    mm: 0,
  },
  kaiserTimeSheetData: {
    isMobileAppEligible: false,
    isKaiserTimeEntryEligible: false,
    kaiserTimeEntryFacilityName: '',
  },
  selectedEntry: undefined,
  isUpdatedSuccessfully: false,
  oldEntry: {
    entryDate: new Date(),
    workEntries: [],
    comments: '',
  },
  previousTimeSheetEntry: [],
  error: undefined,
};

const getTimesheetsAction = createAction<ITimeEntryRequest>('GET_TIMESHEETS');
const getBreakRulePDFAction = createAction('GET_BREAK_RULE_POLICY_PDF');
const getSelectedTimesheetAction = createAction<string>(
  'GET_SELECTED_TIMESHEET',
);
const getSelectedNonPassportTimesheetAction = createAction<{
  placementId: string;
  startDate: string;
  version: string;
}>('GET_SELECTED_NONPASSPORT_TIMESHEET');
const getTimesheetApproversAction = createAction<string[]>(
  'GET_TIMESHEET_APPROVERS',
);
const getMoreTimesheetsAction = createAction<ITimeEntryRequest>(
  'GET_MORE_TIMESHEETS',
);
const postTimesheetApproverAction = createAction(
  'POST_TIMESHEET_APPROVER',
  function prepare(approvers: ITimesheetApprover[], placementId: string) {
    return {
      payload: {
        approvers,
        placementId,
      },
    };
  },
);

const saveTimesheetAction = createAction<PrepareAction<ISaveTimesheetAction>>(
  'SAVE_TIMESHEET',
  function prepare(
    timesheetId: string,
    timesheetRequest: ITimesheetRequest,
    isSubmitting: boolean,
  ) {
    return {
      payload: {
        timesheetId,
        timesheetRequest,
        isSubmitting,
      },
    };
  },
);

const resubmitTimesheetAction = createAction<
  PrepareAction<ISaveTimesheetAction>
>(
  'RESUBMIT_TIMESHEET',
  function prepare(
    timesheetId: string,
    timesheetRequest: ITimesheetRequest,
    isSubmitting: boolean,
    isSubmitBtnClicked: boolean,
    timesheetApproverDTO: {
      approvers: ITimesheetApprover[];
      placementId: string;
    },
    newTimesheet: ITimesheetDetails,
  ) {
    return {
      payload: {
        timesheetId,
        timesheetRequest,
        isSubmitting,
        isSubmitBtnClicked,
        timesheetApproverDTO,
        newTimesheet,
      },
    };
  },
);

const getKaiserTimeSheetAction = createAction('GET_KAISER_TIMESHEET');
const getPreviousTimeSheetAction = createAction<any>('GET_PREVIOUS_TIMESHEET');

const timeEntrySlice = createSlice({
  name: 'timesheetSlice',
  initialState,
  reducers: {
    toggleTimesheetStatus(state, action: PayloadAction<boolean>) {
      state.isOpenTimesheet = action.payload;
    },
    setTotalTimesheetCount(state, action: PayloadAction<number>) {
      state.total = action.payload;
    },
    setTimesheetState(
      state,
      action: PayloadAction<ITimeEntryListsState | undefined>,
    ) {
      return {
        ...state,
        openTimesheets: action.payload?.openTimesheets || [],
        closedTimesheets: action.payload?.closedTimesheets || [],
      };
    },
    setTimesheetAssignments(state, action: PayloadAction<IDropdownOption[]>) {
      state.assignmentList = action.payload;
    },
    updateTimesheetState(
      state,
      action: PayloadAction<ITimeEntryListsState | undefined>,
    ) {
      return {
        ...state,
        openTimesheets: state.openTimesheets.concat(
          action.payload?.openTimesheets || [],
        ),
        closedTimesheets: state.closedTimesheets.concat(
          action.payload?.closedTimesheets || [],
        ),
      };
    },
    setSelectedTimesheet(
      state,
      action: PayloadAction<ITimesheetDetails | undefined>,
    ) {
      return {
        ...state,
        selectedTimesheet: action.payload || {},
      };
    },
    setSelectedNonPassportTimesheet(
      state,
      action: PayloadAction<INonPassportTimeEntry | undefined>,
    ) {
      state.selectedNonPassportTimesheet = action.payload || {};
    },
    setSelectdTimesheetWorkEntries(state, action) {
      return {
        ...state,
        selectedTimesheet: {
          ...state.selectedTimesheet,
          timeEntries: action.payload || [],
        },
      };
    },
    setSelectedTimesheetUnsavedWorkEntries(
      state,
      action: PayloadAction<ITimeEntryResponse[]>,
    ) {
      return {
        ...state,
        selectedTimesheet: {
          ...state.selectedTimesheet,
          unsavedTimeEntries: action.payload || [],
        },
      };
    },
    setResubmitModifiedTimesheets(
      state,
      action: PayloadAction<ITimeEntryResponse[]>,
    ) {
      return {
        ...state,
        selectedTimesheet: {
          ...state.selectedTimesheet,
          resubmitModifiedTimesheets: action.payload || [],
        },
      };
    },
    setTimesheetApprovers(
      state,
      action: PayloadAction<ITimesheetApprovers[] | undefined>,
    ) {
      return {
        ...state,
        timesheetApprovers: action.payload || [],
      };
    },
    setTimesheetApproversForPlacement(
      state,
      action: PayloadAction<ITimesheetApprover[]>,
    ) {
      let approversWithId: IGuidTimesheetApprover[] = [];
      action?.payload?.length > 0
        ? (approversWithId = action.payload.map(approver => {
            let app: IGuidTimesheetApprover = {
              id: guidGenerator(),
              name: approver.name || '',
              title: approver.title || '',
              emailAddress: approver.emailAddress || '',
              phoneNumber: approver.phoneNumber || '',
            };
            return app;
          }))
        : (approversWithId = []);
      return {
        ...state,
        selectedTimesheetApprovers: approversWithId,
      };
    },
    updateTimesheetApproversForPlacement(
      state,
      action: PayloadAction<IGuidTimesheetApprover[]>,
    ) {
      let ids = new Set(action.payload.map(a => a.id));
      let merged = [
        ...state.selectedTimesheetApprovers.filter(a => !ids.has(a.id)),
        ...action.payload,
      ];
      return {
        ...state,
        selectedTimesheetApprovers: merged,
      };
    },
    setSubmissionError(state, action: PayloadAction<number>) {
      return {
        ...state,
        hasSubmissionError: true,
        submissionErrorCode: action.payload,
      };
    },
    unSetSubmissionError(state) {
      return {
        ...state,
        hasSubmissionError: false,
        submissionErrorCode: 0,
      };
    },
    setUpdateSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isUpdatedSuccessfully: action.payload,
      };
    },
    resetUpdateSuccess(state) {
      return {
        ...state,
        isUpdatedSuccessfully: false,
      };
    },
    setSubmissionSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        submissionSuccess: action.payload,
      };
    },
    reset() {
      return { ...initialState };
    },
    setFooterTotal(state, action: PayloadAction<{ hh: number; mm: number }>) {
      state.footerTotal = action.payload;
    },
    setKaiserTimeSheet(state, action) {
      state.kaiserTimeSheetData = action.payload;
    },
    setSelectedEntry(state, action: PayloadAction<ITimeEntryResponse>) {
      state.selectedEntry = action.payload;
    },
    setResetSelectedEntry(state) {
      if (state?.selectedEntry) {
        state.selectedEntry.comments = '';
        state.selectedEntry.entryDate = '';
        state.selectedEntry.isRestPeriodProvided = false;
        state.selectedEntry.workEntries = [];
      }
    },
    setOldEntry(state, action: PayloadAction<ITimeEntryResponse | undefined>) {
      if (state.oldEntry) {
        state.oldEntry.comments = action?.payload?.comments;
        state.oldEntry.entryDate = action?.payload?.entryDate ?? '';
        state.oldEntry.workEntries = action?.payload?.workEntries ?? [];
      }
    },
    setResetOldEntry(state) {
      if (state.oldEntry) {
        state.oldEntry.comments = initialState.oldEntry.comments;
        state.oldEntry.entryDate = initialState.oldEntry.entryDate;
        state.oldEntry.workEntries = initialState.oldEntry.workEntries;
      }
    },
    setPreviousTimeSheetData(state, action: PayloadAction<ITimeEntryWork[]>) {
      state.previousTimeSheetEntry = action.payload;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    setPageRefresh(state, action: PayloadAction<boolean>) {
      state.pageRefresh = action.payload;
    },
  },
});

export const timeEntrysActions = {
  ...timeEntrySlice.actions,
  getTimesheetsAction,
  getBreakRulePDFAction,
  getSelectedTimesheetAction,
  getTimesheetApproversAction,
  postTimesheetApproverAction,
  saveTimesheetAction,
  resubmitTimesheetAction,
  getMoreTimesheetsAction,
  getKaiserTimeSheetAction,
  getSelectedNonPassportTimesheetAction,
  getPreviousTimeSheetAction,
};

export const approverSelector = (state: RootState) =>
  state.timesheet.selectedTimesheetApprovers;
export const selectedTimesheetSelector = (state: RootState) =>
  state.timesheet.selectedTimesheet;
export const isOpenTimesheetSelector = (state: RootState) =>
  state.timesheet.isOpenTimesheet;

export default timeEntrySlice.reducer;
