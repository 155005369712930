import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { TOAST_MESSAGE } from 'constants/helperText';
import { StatusCode } from 'enums/StatusCode';
import { actionItemTypeId } from 'enums/actionType';
import { ExtensionType } from 'enums/assignmentExtension';
import { AssignmentType } from 'enums/assignmentType';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { getMMMddyyyy } from 'helpers/dateFormatter';
import { getAssignmentStatus } from 'helpers/getAssignmentStatus';
import { httpSuccess } from 'helpers/serviceHelper';
import { IActionItems } from 'interfaces/ActionItem/IActionItems';
import { IAssignmentSummary } from 'interfaces/Assignment/AssignmentSummary';
import { IExtendAssignment } from 'interfaces/Assignment/ExtendAssignment';
import { IActionPayloadType } from 'interfaces/Common/IActionPayloadType';
import { IErrorAuthResponse } from 'interfaces/Common/ICommonContract';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { http } from 'services/BaseApi';
import {
  deleteAssignmentItemForStrike,
  dismissCancelledAssigments,
  extensionOfferConfirmationApi,
  getAssignmentDetails,
  getAssignmentsByStatus,
  postAssignmentExtension,
  saveWiselyPaycardInfo,
  strikeTravelAcknowledgement,
  strikeTravelDateConfirm,
} from 'services/assignments/assignmentService';
import { logger } from 'services/logging/appInsights';
import { RootState } from 'store/configureStore';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { setError } from 'store/slices/error/errorSlice';

const userId = (state: RootState) => state.auth.userId;
const placementId = (state: RootState): number =>
  state.assignmentSummary.placementId;
const actionItems = (state: RootState) => state.actionItem.actionItems;

const trackedAssignmentDetails = (fn, ...args) =>
  trackPromise(fn(...args), 'get-assignment-details');
const trackedUserAssignments = (fn, ...args) =>
  trackPromise(fn(...args), 'get-user-assignments');
const trackedConfirmItinerary = (fn, ...args) =>
  trackPromise(fn(...args), 'confirm-itinerary');

function* assignmentExtension(
  action: PayloadAction<{
    extendAssignmentData: IExtendAssignment;
    isMobileDevice: boolean;
  }>,
) {
  const { extendAssignmentData, isMobileDevice } = action.payload;
  const currentUserId = yield select(userId);
  const currentPlacementId = yield select(placementId);
  try {
    const res = yield call(() =>
      postAssignmentExtension(
        currentUserId,
        currentPlacementId,
        extendAssignmentData,
      ),
    );

    if (res && httpSuccess(res?.status)) {
      let drawerState;

      switch (extendAssignmentData.extensionType) {
        case ExtensionType.Interested:
          drawerState = DrawerContentNames.ASSIGNMENT_EXTENSION_YES;
          break;
        case ExtensionType.NotInterested:
          drawerState = DrawerContentNames.ASSIGNMENT_EXTENSION_NO;
          break;
        case ExtensionType.StillConsidering:
          drawerState = DrawerContentNames.ASSIGNMENT_EXTENSION_NO_WORRIES;
          break;
        default:
          drawerState = isMobileDevice
            ? DrawerContentNames.ASSIGNMENT_MOBILE
            : DrawerContentNames.CLOSED_DRAWER;
          yield put(
            openAlert({
              variant: 'success',
              message: TOAST_MESSAGE.SuccessfullySubmitted,
            }),
          );
      }

      yield put(assignmentActions.setDrawerState(drawerState));
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
    }
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(error, 'assignmentExtension', 'assignmentSaga.ts');
  }
}

function* savePaycard(action) {
  try {
    let currentUserId = yield select(userId);
    let placementID = yield select(placementId);
    yield call(() =>
      saveWiselyPaycardInfo(currentUserId, placementID, action.payload),
    );
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(
      openAlert({
        variant: 'error',
        message: `${err.errorMessage}`,
      }),
    );
    logger.error(error, 'savePaycard', 'assignmentSaga.ts');
  }
}

export function* fetchAssignmentDetails(action: IActionPayloadType) {
  const { userId, activePlacementId } = action.payload as {
    userId: string;
    activePlacementId: number;
  };
  try {
    if (userId && activePlacementId) {
      const fetchedDetails = yield call(
        trackedAssignmentDetails,
        getAssignmentDetails,
        userId,
        activePlacementId,
      );
      if (fetchedDetails && httpSuccess(fetchedDetails?.status)) {
        const { data } = fetchedDetails;
        if (data) {
          const transformedAssignments: any = createAssignmentStatusForEachItem(
            data,
            data?.paging,
          );
          yield put(
            assignmentActions.setAssignmentDetails(transformedAssignments),
          );
        }
      } else {
        yield put(
          assignmentActions.setAssignmentDetails({
            items: [],
            paging: {},
          }),
        );
      }
    } else {
      yield put(
        assignmentActions.setAssignmentDetails({
          items: [],
          paging: {},
        }),
      );
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'fetchAssignmentDetails', 'assignmentSaga.ts');
  }
}

/**
 *  Worker function to fetch assignment detail by user id
 */
function* fetchAssignments() {
  let currentUserId = yield select(userId);
  try {
    const response = yield call(
      trackedUserAssignments,
      getAssignmentsByStatus,
      currentUserId,
    );
    const { data } = response;

    if (
      response &&
      httpSuccess(response.status) &&
      response.status !== StatusCode.NoContent
    ) {
      let transformedAssignments = createAssignmentStatusForEachItem(data);
      yield put(
        assignmentActions.setAssignmentSummary(
          transformedAssignments as IAssignmentSummary,
        ),
      );
    }
    yield put(assignmentActions.setLoadedForTagManager(true));
  } catch (error: any) {
    let err = (error.data as IErrorAuthResponse) || { errorMessage: '' };
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: error.errorMessage,
      }),
    );
    logger.error(error, 'fetchAssignments', 'assignmentSaga.ts');
  }
}

/**
 * Method to create assignment status for each assignment item
 *
 * @param assignmentItem
 */
const createAssignmentStatusForEachItem = (
  assignmentItem: IAssignmentSummary,
  paging = {},
) => {
  return {
    items: assignmentItem.items.map(item => ({
      ...item,
      assignmentActivityStatus: getAssignmentStatus(
        item.activityStatusId,
        item.typeId === AssignmentType.STRIKE,
        item.strike,
      ),
      paging: paging,
    })),
  };
};

function* removeAssignmentFromApi(action) {
  const placementId = action.payload as string;
  const currentUserId = yield select(userId);
  const userActionItems: IActionItems[] = yield select(actionItems);

  try {
    let response = yield call(
      deleteAssignmentItemForStrike,
      currentUserId,
      placementId,
    );
    if (response && httpSuccess(response.status)) {
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.SuccessfullySubmitted,
        }),
      );

      let dismissalItems = (userActionItems || [])
        .filter(
          item =>
            item.actionItemTypeId ===
              actionItemTypeId.ConfirmyourTravelItinerary &&
            item.actionItem?.placementId?.toString() === placementId.toString(),
        )
        .map(item => item.id);

      if (dismissalItems && dismissalItems.length > 0) {
        let dismissals: any = { dismissals: dismissalItems };
        yield put(
          actionItemActions.removeActionItem({ dismissals: dismissals }),
        );
      }
      yield put(assignmentActions.setRemoveAssignment(placementId));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'removeAssignmentFromApi', 'assignmentSaga.ts');
  }
}

function* confirmItinerary(action) {
  const currentUserId = yield select(userId);
  const userActionItems: IActionItems[] = yield select(actionItems);

  try {
    if (!action.payload.travelConfirmed) {
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.SuccessfullySubmitted,
        }),
      );
    } else {
      let res = yield call(
        trackedConfirmItinerary,
        strikeTravelDateConfirm,
        currentUserId,
        action.payload.placementId,
      );

      if (res && httpSuccess(res?.status)) {
        yield put(
          openAlert({
            variant: 'success',
            message: TOAST_MESSAGE.SuccessfullySubmitted,
          }),
        );
        yield put(
          assignmentActions.setTravelConfirmedForAssignment(
            action.payload.placementId,
          ),
        );

        yield put(
          assignmentActions.removeActionItemForAssignment(
            action.payload.placementId,
          ),
        );
      } else {
        yield put(
          openAlert({
            variant: 'error',
            message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
          }),
        );
      }
    }

    let dismissalItems = (userActionItems || [])
      .filter(item => {
        return (
          item.actionItemTypeId ===
            actionItemTypeId.ConfirmyourTravelItinerary &&
          item.actionItem?.placementId?.toString() ===
            action.payload.placementId.toString()
        );
      })
      .map(item => item.id);

    if (dismissalItems && dismissalItems.length > 0) {
      let dismissals: any = { dismissals: dismissalItems };
      yield put(actionItemActions.removeActionItem({ dismissals: dismissals }));
    }
    yield put(
      assignmentActions.removeActionItemForAssignment(
        action.payload.placementId,
      ),
    );
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(e, 'confirmItinerary', 'assignmentSaga.ts');
  }
}

function* dismissAssignmentSaga(action) {
  const currentUserId = yield select(userId);
  try {
    let res = yield call(
      trackedConfirmItinerary,
      dismissCancelledAssigments,
      currentUserId,
      action.payload.placementId,
    );
    if (res && httpSuccess(res?.status)) {
      yield put(
        assignmentActions.setAssigmentAfterDismissal(
          action.payload.placementId,
        ),
      );
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(e, 'dismissAssignmentSaga', 'assignmentSaga.ts');
  }
}

function* strikeTravelConfirmation(action) {
  const currentUserId = yield select(userId);
  const { placementId, isHomepageAssignmentCard } = action.payload as {
    placementId: number;
    isHomepageAssignmentCard?: boolean;
  };
  try {
    let res = yield call(
      strikeTravelAcknowledgement,
      currentUserId,
      placementId,
    );
    if (res && httpSuccess(res?.status)) {
      yield put(
        assignmentActions.getAssignmentDetailsAction(
          currentUserId,
          placementId,
        ),
      );
      if (isHomepageAssignmentCard)
        yield put(assignmentActions.getAssignmentSummaryAction());
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(e, 'strikeTravelConfirmation', 'assignmentSaga.ts');
  }
}

function* extensionOfferConfirmation(action) {
  const currentUserId = yield select(userId);
  const { placementId, hasExtensionConfirmed } = action.payload as {
    placementId: number;
    hasExtensionConfirmed: boolean;
  };
  try {
    let res = yield call(
      extensionOfferConfirmationApi,
      currentUserId,
      placementId,
      hasExtensionConfirmed,
    );
    if (res && httpSuccess(res?.status)) {
      yield put(
        assignmentActions.getAssignmentDetailsAction(
          currentUserId,
          placementId,
        ),
      );
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.FormSubmitted,
        }),
      );
    } else {
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(e, 'extensionOfferConfirmation', 'assignmentSaga.ts');
  }
}

function* assignmentWatcherSaga() {
  yield takeLatest(
    assignmentActions.postAssignmentExtensionAction.type,
    assignmentExtension,
  );
  yield takeLatest(
    assignmentActions.getAssignmentSummaryAction.type,
    fetchAssignments,
  );
  yield takeLatest(
    assignmentActions.getAssignmentDetailsAction.type,
    fetchAssignmentDetails,
  );
  yield takeLatest(
    assignmentActions.removeAssignmentForStrike.type,
    removeAssignmentFromApi,
  );
  yield takeLatest(assignmentActions.savePaycardInfo.type, savePaycard);
  yield takeLatest(
    assignmentActions.confirmItineraryAction.type,
    confirmItinerary,
  );
  yield takeLatest(
    assignmentActions.dismissCancelledAssignmentAction.type,
    dismissAssignmentSaga,
  );
  yield takeLatest(
    assignmentActions.strikeAssignmentAcknowledgement.type,
    strikeTravelConfirmation,
  );
  yield takeLatest(
    assignmentActions.setConfirmExtensionOffer.type,
    extensionOfferConfirmation,
  );
}

/**
 *  Generator function triggered from rootSaga which yield on take latest for the action type
 */
export function* assignmentSaga() {
  yield all([assignmentWatcherSaga()]);
}

// used to get send date for nominated assignments on the home page

export const useGetNominatedDateForSummaryItem = (
  userId: string,
  placementId: number,
) => {
  const [sendDate, setSendDate] = useState<string>();

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!source) return;
    http
      .get<string>(`/assignments/${userId}/${placementId}`, {
        cancelToken: source?.token,
      })
      .then(response => {
        setSendDate(getMMMddyyyy(response.data.items[0].sendDate));
      })
      .catch(err => {
        setSendDate(undefined);
        logger.error(
          err,
          'useGetNominatedDateForSummaryItem',
          'assignmentSaga.ts',
        );
      });
    return () => {
      source.cancel();
      setSendDate(undefined);
    };
  }, [placementId, userId]);

  return sendDate;
};
