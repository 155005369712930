import { createSvgIcon } from '@mui/material';
import React from 'react';

export const ClockIcon = createSvgIcon(
  <svg
    id="Controls_icon_Clock"
    data-name="Controls/icon/Clock"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2318"
      data-name="Rectangle 2318"
      width="38"
      height="38"
      fill="none"
      opacity="0"
    />
    <g id="Group_2897" data-name="Group 2897" transform="translate(1 0.999)">
      <path
        id="Path_3772"
        data-name="Path 3772"
        d="M32.61,14.517a18,18,0,1,0,18,18A18.021,18.021,0,0,0,32.61,14.517Zm11.243,28-1-1a.879.879,0,1,0-1.243,1.243l1,1a14.993,14.993,0,0,1-9.122,3.783V46.127a.879.879,0,0,0-1.757,0v1.416a14.987,14.987,0,0,1-9.122-3.783l1-1a.879.879,0,0,0-1.243-1.243l-1,1A14.994,14.994,0,0,1,17.583,33.4H19a.879.879,0,0,0,0-1.757H17.583a15,15,0,0,1,3.784-9.124l1,1a.878.878,0,1,0,1.243-1.242l-1-1a14.987,14.987,0,0,1,9.122-3.783v1.416a.879.879,0,0,0,1.757,0V17.49a14.993,14.993,0,0,1,9.122,3.783l-1,1a.878.878,0,1,0,1.243,1.242l1-1a14.982,14.982,0,0,1,3.784,9.122H46.22a.879.879,0,0,0,0,1.757h1.416A14.985,14.985,0,0,1,43.853,42.518Z"
        transform="translate(-14.61 -14.517)"
        fill="#75787b"
      />
      <path
        id="Path_3773"
        data-name="Path 3773"
        d="M40.608,30.029H31.349l-5.758-5.774A.879.879,0,0,0,24.347,25.5l6.014,6.031c.007.007.015.008.022.015a.855.855,0,0,0,.264.175.887.887,0,0,0,.337.069h9.624a.879.879,0,1,0,0-1.757Z"
        transform="translate(-12.984 -12.891)"
        fill="#75787b"
      />
    </g>
  </svg>,
  'ClockIcon',
);
