import { INewsFeedGetRequest } from 'interfaces/Community/INewsFeedGetRequest';
import { INewsFeedTagsSaveRequest } from 'interfaces/Community/INewsFeedTagsSaveRequest';
import { IUserStoryGetRequest } from 'interfaces/Community/IUserStoryGetRequest';
import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';

export const getNewsFeedsService = async (
  request: INewsFeedGetRequest,
): Promise<any> => {
  try {
    return await http.get(
      `/user/news-feed/${request?.userId}?offset=${
        request?.offset ?? 0
      }&limit=${request?.limit ?? 0}`,
    );
  } catch (error) {
    logger.error(error, 'getNewsFeedsService', 'communityService.ts');
    return error;
  }
};

export const getNewsFeedTagService = async (userId: string): Promise<any> => {
  try {
    return await http.get(`/user/news-feed/${userId}/tags`);
  } catch (error) {
    logger.error(error, 'getNewsFeedTagService', 'communityService.ts');
    return error;
  }
};

export const getUserStoriesService = async (
  request: IUserStoryGetRequest,
): Promise<any> => {
  try {
    return await http.get(
      `users/content/video/${request?.userId}?offset=${
        request?.offset ?? 0
      }&limit=${request?.limit ?? 0}`,
    );
  } catch (error) {
    logger.error(error, 'getUserStoriesService', 'communityService.ts');
    return error;
  }
};

export const saveNewsFeedTagService = async (
  userId: string,
  request: INewsFeedTagsSaveRequest,
): Promise<any> => {
  try {
    return await http.put(`user/news-feed/${userId}/tags`, request);
  } catch (error) {
    logger.error(error, 'saveNewsFeedTagService', 'communityService.ts');
    return error;
  }
};

export const getNewsFeedByIdService = async (
  userId: string,
  newsFeedId: string,
): Promise<any> => {
  try {
    return await http.get(`/user/news-feed/${userId}/${newsFeedId}`);
  } catch (error) {
    logger.error(error, 'getNewsFeedByIdService', 'communityService.ts');
    return error;
  }
};

export const getUserStoryByIdService = async (
  userId: string,
  userStoryId: string,
): Promise<any> => {
  try {
    return await http.get(`/users/content/video/${userId}/${userStoryId}`);
  } catch (error) {
    logger.error(error, 'getUserStoryByIdService', 'communityService.ts');
    return error;
  }
};

export const likeContentService = async (
  userId: string,
  contentId: string,
): Promise<any> => {
  try {
    return await http.post(`users/content/${userId}/${contentId}/like`);
  } catch (error) {
    logger.error(error, 'likeContentService', 'communityService.ts');
    return error;
  }
};
