import { actionItemTypeId } from 'enums/actionType';
import { ChecklistDescription } from 'enums/checklistDescriptionType';
import {
  BadgeStatus,
  CredentialOpenRequirementsStatus,
  CredentialSubmissionStatus,
  DisciplineId,
  userCredentials,
} from 'enums/credential';
import { ICredentialDetails } from 'interfaces/Credential/ICredentialDetails';
import moment from 'moment';

export const filterCredentials = (
  categoryId: userCredentials,
  credentials: ICredentialDetails[],
) => {
  return credentials?.filter(item => item.categoryId === categoryId) || [];
};

// returns whether it has any expired credentials
export const credentialsExpired = (
  categoryId: userCredentials,
  credentials: ICredentialDetails[],
) => {
  return !!credentials?.find(
    item =>
      item.categoryId === categoryId &&
      (moment(item.expirationDate).isBefore(moment()) ||
        moment(item.expirationDate).isBefore(moment().add(60, 'd'))) &&
      item.statusId !== CredentialSubmissionStatus.Pending,
  )
    ? '!'
    : undefined;
};

export const hasExpiredOrExpiring = (
  expirationDate: string,
  isSubtitle: boolean = false,
) => {
  return moment(expirationDate).isBefore(moment())
    ? isSubtitle
      ? 'Expired'
      : BadgeStatus.Expired
    : moment(expirationDate).isBefore(moment().add(60, 'd'))
    ? isSubtitle
      ? 'Expires'
      : BadgeStatus.Expiring
    : isSubtitle
    ? 'Expires'
    : undefined;
};

export const returnBadgeStatus = (
  statusId: number,
  isAssignmentCredential: boolean,
  isUserCredential?: boolean,
  expirationDate?: string,
  rejectedReasonId?: number,
) => {
  const statusMap = new Map([
    [CredentialOpenRequirementsStatus.Pending, BadgeStatus.Pending],
    [CredentialOpenRequirementsStatus.Accepted, BadgeStatus.Pending],
    [CredentialOpenRequirementsStatus.Compliant, BadgeStatus.Processed],
    [
      CredentialOpenRequirementsStatus.Rejected,
      (rejectedReasonId ?? 0) > 1 ? BadgeStatus.Rejected : undefined,
    ],
  ]);

  const userCredentialStatusMap = new Map([
    [CredentialSubmissionStatus.Pending, BadgeStatus.Pending],
    [CredentialSubmissionStatus.Rejected, BadgeStatus.Rejected],
  ]);

  if (statusId) {
    if (isUserCredential) {
      return userCredentialStatusMap.get(statusId);
    }
    if (statusMap.get(statusId)) return statusMap.get(statusId);
  }
  if (moment(expirationDate).isBefore(moment()))
    return isAssignmentCredential ? BadgeStatus.PastDue : BadgeStatus.Expired;
  if (moment(expirationDate).isBefore(moment().add(60, 'd')))
    return BadgeStatus.Expiring;
};

export const returnUrgencyIcon = actionItemTypeIdValue => {
  // TODO: Replace this logic once taskCategoryId is added to task-center API response
  return actionItemTypeIdValue >= actionItemTypeId.SocialSecurityNumber &&
    actionItemTypeIdValue <= actionItemTypeId.MissingEducationInformation
    ? '!'
    : undefined;
};

export const visualIndicatorColor = (badgeStatus: string | undefined) => {
  switch (badgeStatus) {
    case BadgeStatus.Pending:
      return 'system.coolGray';
    case BadgeStatus.Expiring:
      return 'system.yellow';
    case BadgeStatus.Expired:
    case BadgeStatus.PastDue:
    case BadgeStatus.Rejected:
    case BadgeStatus.Duplicate:
      return 'system.error';
    case BadgeStatus.Accepted:
      return 'system.skyBlue';
    case BadgeStatus.Processed:
      return 'system.grassGreen';
    default:
      return '';
  }
};

export const getRnOrLpnContentName = (disciplineId: number) => {
  const disciplineMap = new Map([
    [DisciplineId.RN, ChecklistDescription.REGISTERED_NURSE],
    [DisciplineId.LPN, ChecklistDescription.LICENSED_PRACTICAL_NURSE],
  ]);

  return disciplineMap.get(disciplineId);
};
