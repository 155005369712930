import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as service from 'services/lookup/lookupService';
import { setError } from 'store/slices/error/errorSlice';
import {
  fetchCompanyLookupAction,
  fetchCountries,
  fetchDiscipline,
  fetchState,
  postFriendReferralAction,
  setCountries,
  setDiscipline,
  setShowThanksForReferralModalState,
  setStates,
  setTravelCompanies,
} from '../slices/lookups/lookupsSlice';
import { trackPromise } from 'react-promise-tracker';
import { retrieveUserPreference } from 'store/slices/user/userPreferenceSlice';
import { logger } from 'services/logging/appInsights';

const trackGetDisciplineData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-discipline-data');

// Worker Sagas
function* getCountries(action) {
  try {
    const response = yield call(service.fetchCountries);
    const { data } = response;

    yield put(setCountries(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getCountries', 'lookupSaga.ts');
  }
}

function* getStateAndProvinces(action) {
  try {
    const response = yield call(service.fetchStates);
    const { data } = response;

    yield put(setStates(data));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getStateAndProvinces', 'lookupSaga.ts');
  }
}

function* getDiscipline(action) {
  try {
    const response = yield call(
      trackGetDisciplineData,
      service.fetchDisciplines,
      action.payload.offset,
      action.payload.limit,
      action.payload.disciplineId,
    );
    const { data } = response;
    yield put(setDiscipline(data));
    yield put(retrieveUserPreference());
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getDiscipline', 'lookupSaga.ts');
  }
}

//action: PayloadAction<{candidateId: number}>
function* getTravelCompanies(action) {
  try {
    const response = yield call(service.fetchTravelCompanies);
    yield put(setTravelCompanies([...response.data]));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'getTravelCompanies', 'lookupSaga.ts');
  }
}

function* postReferral(action) {
  try {
    const { userId, referralRequest: postReferralBody } = action.payload;
    const response = yield call(service.postReferral, userId, postReferralBody);
    if (response?.status === 200) {
      yield put(setShowThanksForReferralModalState(true));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'postReferral', 'lookupSaga.ts');
  }
}

function* watchCountries() {
  yield takeEvery(fetchCountries.type, getCountries);
}

function* watchStates() {
  yield takeEvery(fetchState.type, getStateAndProvinces);
}

function* watchDiscipline() {
  yield takeLatest(fetchDiscipline.type, getDiscipline);
}

function* watchTravelCompanies() {
  yield takeLatest(fetchCompanyLookupAction.type, getTravelCompanies);
}

function* watchPostReferral() {
  yield takeLatest(postFriendReferralAction.type, postReferral);
}

export function* lookupSaga() {
  yield all([
    watchCountries(),
    watchDiscipline(),
    watchPostReferral(),
    watchStates(),
    watchTravelCompanies(),
  ]);
}
