import { IFeaturedContentResponse } from 'types/FeaturedContentResponse';
import { http } from 'services/BaseApi';
import { IPromotionsGetRequest } from 'interfaces/Promotions/IPromotionsGetRequest';
import { IPromotionsMarkAsReadRequest } from 'interfaces/Promotions/IPromotionsMarkAsReadRequest';
import { logger } from 'services/logging/appInsights';

export const getPromotionsService = async (
  request: IPromotionsGetRequest,
): Promise<IFeaturedContentResponse> => {
  return await http.get<IFeaturedContentResponse>(
    `/promotions/${request?.userId}?&offset=${request?.offset ?? 0}&limit=${
      request?.limit ?? 0
    }`,
  );
};

export const markPromotionsAsReadService = async (
  userId: string,
  request: IPromotionsMarkAsReadRequest,
): Promise<any> => {
  try {
    return await http.patch(`/promotions/${userId}`, request);
  } catch (error) {
    logger.error(error, 'markPromotionsAsReadService', 'promotionsService.ts');
    return error;
  }
};
