import { createSvgIcon } from '@mui/material';
import React from 'react';

export const FlagIcon = createSvgIcon(
  <svg
    id="Controls_icon_Flag"
    data-name="Controls/icon/Flag"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2304"
      data-name="Rectangle 2304"
      width="38"
      height="38"
      fill="none"
      opacity="0"
    />
    <g id="Group_2881" data-name="Group 2881" transform="translate(3 1)">
      <path
        id="Path_3748"
        data-name="Path 3748"
        d="M48.626,24.416l-22.48-6.685V15.766a1.484,1.484,0,1,0-2.968,0V47.652a1.464,1.464,0,0,0,.279.824H18.581a.9.9,0,0,0,0,1.794H32.3a.9.9,0,0,0,0-1.794H25.867a1.455,1.455,0,0,0,.279-.824V37.124l22.651-9.9a1.5,1.5,0,0,0-.171-2.806Z"
        transform="translate(-17.691 -14.271)"
        fill="#75787b"
      />
    </g>
  </svg>,
  'FlagIcon',
);
