import React from 'react';
import { createSvgIcon } from '@mui/material';

export const BuildingIcon = createSvgIcon(
  <svg viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.0352 23.3053H25.6247V26.7158H29.0352M29.0352 16.4842H25.6247V19.8947H29.0352M32.4457 30.1263H18.8036V26.7158H22.2141V23.3053H18.8036V19.8947H22.2141V16.4842H18.8036V13.0737H32.4457M15.3931 9.66316H11.9826V6.25263H15.3931M15.3931 16.4842H11.9826V13.0737H15.3931M15.3931 23.3053H11.9826V19.8947H15.3931M15.3931 30.1263H11.9826V26.7158H15.3931M8.57203 9.66316H5.1615V6.25263H8.57203M8.57203 16.4842H5.1615V13.0737H8.57203M8.57203 23.3053H5.1615V19.8947H8.57203M8.57203 30.1263H5.1615V26.7158H8.57203M18.8036 9.66316V2.8421H1.75098V33.5368H35.8562V9.66316H18.8036Z"
      fill="#75787B"
    />
  </svg>,
  'BuildingIcon',
);
