import React, { useCallback, useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import ArrowDownIcon from 'assets/icons/Controls/ArrowDown';
import { PhoneNumberType } from 'enums/phoneNumberType';
import { formatPhoneNumber } from 'helpers/formatUSPhoneNumber';
import useOutsideClickHandler from 'helpers/useOutsideClickHandler';
import { defaultCountry } from 'interfaces/Props/Default/defaultCountry';
import { phoneNumberStyles } from './PhoneNumber.styles';
import SearchBar from './SearchBar';
import useScrollToValidation from 'utils/hooks/useScrollToValidation';
import { logger } from 'services/logging/appInsights';

const flagSvgs = require.context('assets/icons/Flags', true, /\.svg$/);
export default function PhoneNumber({ label, name, options }) {
  const {
    watch,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const classes = phoneNumberStyles();
  const formData = watch(name);
  const error = get(errors, `${name}`);

  const [focused, setFocus] = useState(false);
  const [countryDialingCode, setCountryDialingCode] = useState(
    formData?.countryDialingCode || defaultCountry.countryDialingCode,
  );
  const [countryCode, setCountryCode] = useState(defaultCountry.countryCode);

  //========= outside click event ============//
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClickHandler(false);
  //==========================================//

  useScrollToValidation({ name });

  const handleChange = (
    key: 'phoneNumber' | 'countryDialingCode',
    newValue,
  ) => {
    let phoneNumberType = 0;
    switch (name) {
      case 'mobilePhone':
        phoneNumberType = PhoneNumberType.CELLPHONE;
        break;
      case 'homePhone':
        phoneNumberType = PhoneNumberType.PERMANENT;
        break;
      case 'fax':
        phoneNumberType = PhoneNumberType.FAX;
        break;
    }
    if (key === 'phoneNumber') {
      setValue(`${name}.phoneNumber`, newValue);
      setValue(`${name}.phoneNumberType`, phoneNumberType);
      setValue(`${name}.countryDialingCode`, defaultCountry.countryDialingCode);
      setCountryDialingCode(formData?.countryDialingCode);
    } else {
      setValue(`${name}.countryDialingCode`, newValue);
      setCountryDialingCode(newValue);
    }
    setIsComponentVisible(false);
  };

  useEffect(() => {
    if (countryDialingCode === defaultCountry.countryDialingCode) {
      setCountryCode(defaultCountry.countryCode.toLowerCase());
    } else {
      const foundItem =
        options
          .find(item => item.countryDialingCode === countryDialingCode)
          ?.countryCode.toLowerCase() || '';
      if (foundItem) {
        setCountryCode(foundItem);
      }
    }
    setValue(`${name}.countryDialingCode`, countryDialingCode);
  }, [countryDialingCode, options]);

  const classNames = !!error ? classes.error : '';
  const getFlagSrc = useCallback(() => {
    try {
      return flagSvgs(`./${countryCode.toUpperCase()}.svg`);
    } catch (error) {
      logger.error(error, 'getFlagSrc', 'PhoneNumber.tsx');
      return '';
    }
  }, [countryCode]);

  return (
    <Box ref={ref} display="flex" sx={{ position: 'relative', width: '100%' }}>
      <Box
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        sx={{
          backgroundColor: 'system.backgroundGray',
          color: 'system.coolGray',
          borderBottom: '1px solid',
          borderColor: 'system.gray',
          cursor: 'pointer',
          borderTopLeftRadius: '4px',
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        p={2}
        minWidth="84px"
        className={classNames}
      >
        <img width="25px" height="15px" src={getFlagSrc()} alt="" />
        {ArrowDownIcon({
          color: 'system.coolGray',
          height: '13',
          width: '15',
        })}
      </Box>
      <TextField
        {...register(`${name}.phoneNumber`)}
        sx={{
          backgroundColor: 'grayscale.gray100',
          width: '100%',
        }}
        label={label}
        variant="filled"
        value={formData?.phoneNumber || ''}
        error={!!error}
        helperText={error ? error?.message : ''}
        name={`${name}.phoneNumber`}
        className={classes.errorLabel}
        InputProps={{
          sx: {
            borderTopLeftRadius: '0px',
          },
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ marginTop: ' 18px !important' }}
            >
              {formData?.phoneNumber ? `+${countryDialingCode}` : ''}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: formData?.phoneNumber || focused ? true : false,
        }}
        onChange={e =>
          handleChange('phoneNumber', formatPhoneNumber(e.target.value))
        }
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        ref={register(`${name}`).ref}
      />
      {isComponentVisible && (
        <Paper
          elevation={2}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            padding: 1,
            paddingBottom: 0,
            position: 'absolute',
            top: '54px',
            backgroundColor: 'system.white',
            borderRadius: '4px',
            zIndex: 999,
            maxHeight: 250,
            overflowY: 'scroll',
          }}
        >
          <SearchBar
            items={options ? options : []}
            onChange={({ countryDialingCode: newValue }) => {
              handleChange('countryDialingCode', newValue);
            }}
          />
        </Paper>
      )}
    </Box>
  );
}
