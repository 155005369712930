import React from 'react';
import { Box, Typography, SxProps } from '@mui/material';

export interface IIconTextProps {
  id: string;
  icon: any;
  text: string;
  sxProps?: SxProps;
}
export const IconText = (props: IIconTextProps) => {
  const { id, icon, text, sxProps } = props;

  return (
    <Box id={id} sx={{ display: 'flex', alignItems: 'center', ...sxProps }}>
      {icon}
      <Typography
        sx={{
          marginLeft: '6px',
          fontSize: '14',
          lineHeight: '21px',
          color: 'system.coolGray',
          ...sxProps?.['text'],
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
