import {
  DEFAULT_FILTER_TYPES,
  NEWEST_SORT_FILTER,
  PAY_SORT_FILTER,
  START_DATE_SORT_FILTER,
} from 'constants/jobFilter';
import { JobFilterType } from 'enums/jobFilterType';
import { JobSortType } from 'enums/jobSortType';
import { jobSortTypeText } from 'enums/jobSortTypeText';
import { IACSFilter } from 'interfaces/Jobs/IACSFilter';
import { IACSFilterType } from 'interfaces/Jobs/IACSFilterType';
import { IACSJobRequest } from 'interfaces/Jobs/IACSJobRequest';
import { IFilterRequest } from 'interfaces/Jobs/IJobsSearchRequest';

export const getModifiedFilterType = (
  filterItem?: IACSFilter,
  requestFilterTypes?: IACSFilter[],
  filterType?: JobFilterType,
  displayZeroCountItems?: boolean,
) => {
  let types: IACSFilterType[] = [];
  if (requestFilterTypes) {
    const profFilter = requestFilterTypes?.find(
      filter => filter.filterType === filterType,
    );

    if (profFilter?.values && profFilter?.values?.length > 0) {
      types =
        filterItem?.values
          ?.filter(
            f =>
              (f.count ?? 0) > 0 ||
              requestFilterTypes
                ?.map(x => x.values)
                ?.flat()
                ?.some(x => x.displayName === f.displayName) ||
              displayZeroCountItems,
          )
          ?.map(filter => ({
            type: `${filter.displayName} (${filter?.count ?? 0})`,
            isActive: profFilter?.values?.some(
              s => s.displayName === filter.displayName,
            ),
          })) ?? [];
    } else {
      types =
        filterItem?.values?.map(filter => ({
          type: `${filter.displayName} (${filter?.count ?? 0})`,
          isActive: false,
        })) ?? [];
    }
  } else {
    types =
      filterItem?.values?.map(filter => ({
        type: `${filter.displayName} (${filter?.count ?? 0})`,
        isActive: false,
      })) ?? [];
  }
  return types;
};

export const findSelectedItems = (
  filterType: IACSFilterType[],
  modifiedType: IACSFilterType[],
) => {
  return (
    filterType
      ?.filter(
        x =>
          x.isActive &&
          !modifiedType?.some(y =>
            y.type.includes(getTextFromFacetName(x.type) ?? ''),
          ),
      )
      .map(x => ({
        type: (getTextFromFacetName(x.type) ?? '') + ' (0)',
        isActive: x.isActive,
      })) ?? []
  );
};
export const getCityState = (
  geocodeResult,
): { city: string; state: string } => {
  const city = geocodeResult.address_components.find(a =>
    a.types.includes('locality'),
  )?.long_name;
  const state = geocodeResult.address_components.find(a =>
    a.types.includes('administrative_area_level_1'),
  )?.short_name;

  return { city, state };
};

export const generateRequest = (
  filterList,
  requestFilter,
  filterType: JobFilterType,
  reset?: boolean,
) => {
  const filter = requestFilter?.filterTypes?.find(
    f => f?.filterType === filterType,
  );
  let newFilter = requestFilter?.filterTypes;
  const otherFilter = requestFilter?.filterTypes?.filter(
    f => f?.filterType !== filterType,
  );
  if (filter && filterList?.length > 0) {
    newFilter = [
      ...(otherFilter ?? []),
      {
        filterType: filterType,
        values: filterList
          .filter(f => f.isActive)
          .map(f => ({
            displayName: getTextFromFacetName(f.type) ?? '',
            count: getCountFromFacetName(f.type) ?? 0,
          })),
      },
    ];
  }

  if (reset) {
    newFilter = [
      ...(otherFilter ?? []),
      { filterType: filterType, values: [] },
    ];
  }

  const newRequest = {
    ...requestFilter,
    pageNumber: '1',
    filterTypes: newFilter,
    currentFilterType: filterType,
  };

  return newRequest;
};

export const getCountFromFacetName = (input: string): string | null => {
  const match = input.match(/\(([^)]+)\)$/);
  return match ? match[1] : null;
};

export const getTextFromFacetName = (input: string): string | null => {
  const match = input.match(/^(.*)\s*\([^()]*\)$/);
  return match ? match[1].trim() : null;
};

export const getSortTypeName = (sortType: jobSortTypeText): string => {
  switch (sortType) {
    case jobSortTypeText.MAX_PAY_RATE:
      return PAY_SORT_FILTER;
    case jobSortTypeText.NEWEST:
      return NEWEST_SORT_FILTER;
    case jobSortTypeText.START_DATE:
      return START_DATE_SORT_FILTER;
  }
};

export const getSortTypeId = (sortType: jobSortTypeText): number => {
  switch (sortType) {
    case jobSortTypeText.MAX_PAY_RATE:
      return JobSortType.WeeklyPay;
    case jobSortTypeText.NEWEST:
      return JobSortType.MostRecentPost;
    case jobSortTypeText.START_DATE:
      return JobSortType.StartDate;
  }
};

export const modifyInitFilterWithDefaultFilter = defaultFilter => {
  let prefilter: any[] = DEFAULT_FILTER_TYPES;

  let exists = false;
  defaultFilter?.forEach(element => {
    prefilter = prefilter.map(x => {
      if (x.filterType === JobFilterType.COMMUNITIES) {
        exists = x.values.some(v => v.displayName === element?.community);
        return {
          ...x,
          values: exists
            ? [...x.values]
            : [...x.values, { displayName: element?.community }],
        };
      } else if (x.filterType === JobFilterType.DISCIPLINE) {
        exists = x.values.some(v => v.displayName === element?.disciplineName);
        return {
          ...x,
          values: exists
            ? [...x.values]
            : [...x.values, { displayName: element?.disciplineName }],
        };
      } else if (x.filterType === JobFilterType.SPECIALTY) {
        exists = x.values.some(v => v.displayName === element?.specialtyName);
        return {
          ...x,
          values: exists
            ? [...x.values]
            : [...x.values, { displayName: element?.specialtyName }],
        };
      } else {
        return x;
      }
    });
  });

  return prefilter;
};

export const formatFilterRequest = (filterTypes: IACSFilter[]) => {
  const filtersList: IFilterRequest[] = [];
  filterTypes?.forEach(filter => {
    if (filter?.values?.length > 0) {
      filtersList.push({
        filterType: filter?.filterType ?? '',
        values: filter?.values?.map(x => x?.displayName ?? '') ?? [],
      });
    }
  });

  return filtersList || [];
};

export function mergeFilterValues(defaultFilters, newFilters) {
  const filterMap = new Map();

  defaultFilters.forEach(filter => {
    filterMap.set(filter.filterType, { ...filter });
  });

  newFilters.forEach(newFilter => {
    if (filterMap.has(newFilter.filterType)) {
      filterMap.get(newFilter.filterType).values = newFilter.values.map(
        value => ({ displayName: value }),
      );
    }
  });

  return Array.from(filterMap.values());
}

export const formatPayRate = rate => {
  return rate?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const payRange = (minRate, maxRate) => {
  return `${formatPayRate(minRate)}${
    maxRate ? `-${formatPayRate(maxRate)}` : ''
  }`;
};

export const extractPathValueFromUrl = (url: string, startsWith: string) => {
  const pathSegments = url.split('/');
  const requiredSegment = pathSegments.find(segment =>
    segment.startsWith(startsWith),
  );
  return requiredSegment ? requiredSegment.split('=')[1] : null;
};

export const generateJobSearchUrl = (
  params: IACSJobRequest | undefined,
): string => {
  const baseUrl = window.location.origin;

  let jobSearchUrl = baseUrl + '/jobs';

  let filters: IFilterRequest[] = [];
  if (params?.filterTypes) {
    filters = formatFilterRequest(params?.filterTypes);
  }

  // Add community (if available)
  const communityFilter = filters?.find(
    filter => filter.filterType === 'Communities',
  );
  if (communityFilter && (communityFilter.values ?? [])?.length > 0) {
    jobSearchUrl += `/c/${encodeURIComponent(
      (communityFilter.values ?? [''])
        .map(value => encodeURIComponent(value))
        .join('&'),
    )}`;
  }

  // Add keyword search (if available)
  if (params?.keywordSearch) {
    jobSearchUrl += `/k/${encodeURIComponent(params.keywordSearch)}`;
  }

  // Add location search (if available)
  if (params?.locationSearch) {
    jobSearchUrl += `/l/${encodeURIComponent(params.locationSearch)}`;
  }

  if (params?.orderBy) {
    jobSearchUrl += `/sortOrder=${encodeURIComponent(params?.orderBy)}`;
  }

  // Add filters as query parameters
  const filterParams = filters
    ?.map(filter => {
      if (
        filter.filterType !== JobFilterType.COMMUNITIES &&
        (filter.values ?? []).length > 0
      ) {
        const filterValues = (filter.values ?? [])
          .map(encodeURIComponent)
          .join(',');
        return `${filter.filterType}:${filterValues}`;
      }
      return null;
    })
    .filter(filterString => filterString !== null)
    .join('&');

  if (filterParams) {
    jobSearchUrl += `/filters=${filterParams}`;
  }

  return jobSearchUrl;
};

export const parseJobSearchUrl = (
  userId: string,
  url: string,
): IACSJobRequest => {
  let jobSearchRequest: IACSJobRequest = {};

  // Setting the Default values
  jobSearchRequest.userId = userId;
  jobSearchRequest.pageNumber = '1';
  jobSearchRequest.pageSize = '40';

  // Splitting URL for communities, keyword and location
  const urlObj = new URL(url);
  const pathSegments = urlObj.pathname.split('/').filter(Boolean);

  // Extract communities, keyword, and location from the path
  let communities: string[] = [];
  pathSegments.forEach((segment, index) => {
    if (segment === 'c' && pathSegments[index + 1]) {
      const rawCommunities = decodeURIComponent(pathSegments[index + 1]);
      communities = rawCommunities.split('&').map(decodeURIComponent);
    } else if (segment === 'k' && pathSegments[index + 1]) {
      jobSearchRequest.keywordSearch = decodeURIComponent(
        pathSegments[index + 1],
      );
    } else if (segment === 'l' && pathSegments[index + 1]) {
      jobSearchRequest.locationSearch = decodeURIComponent(
        pathSegments[index + 1],
      );
    }
  });

  // Extract sortOrder
  const sortOrderValue = extractPathValueFromUrl(url, 'sortOrder=');
  if (sortOrderValue) {
    jobSearchRequest.orderBy = decodeURIComponent(sortOrderValue);
  }

  // Extract filters
  let filterString: string = '';
  const filterValues = extractPathValueFromUrl(url, 'filters=');
  if (filterValues) {
    filterString = decodeURIComponent(filterValues);
  }

  // Split the filters correctly
  const filterEntries = filterString
    .split('&')
    .filter(entry => entry.includes(':'));

  // Map through filters to parse them into the required structure
  const parsedFilters: { filterType: string; values: string[] }[] =
    filterEntries.map(filterEntry => {
      const [filterType, valuesString] = filterEntry.split(':');
      const values = valuesString
        .split(',')
        .map(value => decodeURIComponent(value));
      return { filterType, values };
    });

  // Add the community filter if it exists in the path to filters
  if (communities.length > 0) {
    parsedFilters.push({
      filterType: 'Communities',
      values: communities,
    });
  }

  // Formatting filters to API accepted type
  jobSearchRequest.filterTypes = mergeFilterValues(
    DEFAULT_FILTER_TYPES,
    parsedFilters,
  );

  return jobSearchRequest;
};
