import {
  ApplicationInsights,
  ICustomProperties,
} from '@microsoft/applicationinsights-web';
import ReactGA from 'react-ga4';

const globalProperties = {
  ENV: process.env.REACT_APP_PASSPORT_ENV,
  LogSource: 'PassportWeb', // Add LogSource so that metrics for web and mobile app could be captured
};

export interface IUser {
  userId: string;
  goldenRecordId: string;
  userStatusType: string;
}

export interface ILogger {
  error: (exception: any, functionName: string, fileName: string) => void;
  event: (event: string, customProperties?: ICustomProperties | null) => void;
}

export default class Logger implements ILogger {
  private appInsights: ApplicationInsights;
  private userProfile: IUser | {};

  constructor(appInsights: ApplicationInsights, userProfile?: IUser) {
    this.appInsights = appInsights;
    this.userProfile = userProfile || {};
  }

  setUserContext = (user: IUser) => {
    this.userProfile = user || {};
  };

  error = (exception: any, functionName: string, fileName: string) => {
    const properties = {
      functionName,
      fileName,
    };

    try {
      if (this.appInsights) {
        this.appInsights.trackException(
          {
            exception: exception,
          },
          {
            ...this.userProfile,
            ...properties,
            ...globalProperties,
          },
        );
      }
    } catch (error) {
      console.error('Failed to log error to App Insights:', error);
    }
  };

  event = (event: string, customProperties?: ICustomProperties | null) => {
    if (!customProperties) {
      this.appInsights.trackEvent({ name: event }, globalProperties);
      ReactGA.event(event, globalProperties);
    } else {
      this.appInsights.trackEvent(
        { name: event },
        { ...globalProperties, ...customProperties },
      );
      ReactGA.event(event, { ...globalProperties, ...customProperties });
    }
  };
}
