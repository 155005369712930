import React, { useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { IJobCardProps } from 'interfaces/Props/IJobCardProps';
import moment from 'moment';
import { trackEvent } from 'services/logging/appInsights';
import { theme } from 'styles/theme';

import { JobTag } from 'components/common/JobTag/JobTag';
import { BuildingIcon } from 'assets/icons/Building/BuildingIcon';
import { FlagIcon } from 'assets/icons/Flag/FlagIcon';
import { IconText } from 'components/common/IconText/IconText';
import { ClockIcon } from 'assets/icons/Clock/ClockIcon';
import CalendarIcon from 'assets/icons/Calendar/CalendarIcon';
import { payRange } from 'helpers/jobSearchHelper';
import { PinpointFilledIcon } from 'assets/icons/Pinpoint/PinpointFilledIcon';

export const RecommendedJobSearchCard: React.FC<IJobCardProps> = props => {
  const isStrike = props.topJob?.placementTypeId === 500;
  const placementType = props?.topJob?.placementType;
  const shiftType = props?.topJob?.shiftType;
  const duration = props?.topJob?.duration;
  const maxPayRate = props?.topJob?.maxPayRate;
  const minPayRate = props?.topJob?.minPayRate;

  const city = props?.topJob?.address?.city;
  const state = props?.topJob?.address?.state;

  const flagIcon = (
    <FlagIcon viewBox="0 0 18 18" sx={{ width: '18px', height: '18px' }} />
  );

  const clockIcon = (
    <ClockIcon viewBox="0 0 18 18" sx={{ width: '18px', height: '18px' }} />
  );

  const calendarIcon = (
    <CalendarIcon
      height="18px"
      width="18px"
      color={theme?.palette?.system?.coolGray}
    />
  );

  const pinPointIcon = (
    <PinpointFilledIcon
      viewBox="0 0 18 18"
      sx={{ width: '18px', height: '18px' }}
    />
  );

  const displayDateInfo = useMemo(() => {
    if (isStrike) {
      const postedDate = moment(props?.topJob?.postedDate || '');
      var days = moment().diff(postedDate, 'days');

      if (days === 1) {
        return `Posted ${days} Day ago`;
      } else if (days < 1) {
        days = moment().diff(postedDate, 'hours');
        return `Posted ${days} Hours ago`;
      } else {
        return `Posted ${days} Days ago`;
      }
    }
    return `${moment(props?.topJob?.startDate).format('MMM DD, YYYY')}`;
  }, [props?.topJob, isStrike]);

  const handleClick = () => {
    trackEvent('Job_Clicked');
    if (props.onClick) {
      props.onClick();
    }
  };

  const location = useCallback((city, state) => {
    return city && state ? `${city}, ${state}` : city ? city : state;
  }, []);

  return (
    <Grid container marginBottom={4}>
      <Grid item xs={12}>
        <Card
          id={props.id}
          key={props.topJob?.jobId}
          sx={{
            borderBottom: `1px solid ${theme.palette.system.border}`,
            padding: '0px 0px 24px 0px',
          }}
          onClick={handleClick}
        >
          <Grid
            container
            item
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            gap={4}
          >
            <Grid container item flexDirection="column" gap={2}>
              <Grid
                container
                item
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {placementType && (
                  <Grid item alignItems="center" display="flex">
                    <JobTag
                      id={`job-type`}
                      key={`job-type`}
                      tagText={placementType ?? ''}
                      isSelected={true}
                      sx={{ marginRight: '12px' }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Typography
                  data-testid="title"
                  sx={{
                    color: 'system.grayText',
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}
                >
                  {props?.topJob?.title}
                </Typography>
              </Grid>
              <Grid item flexWrap="nowrap">
                {!isStrike && props?.topJob?.facilityName && (
                  <Box sx={{ display: 'flex' }}>
                    <BuildingIcon sx={{ height: '18px ', width: '18px' }} />
                    <Typography
                      variant="body1"
                      color="system.coolGray"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      marginLeft="6px"
                      data-testid="facility-name"
                      sx={{ lineHeight: '21px' }}
                    >
                      {props.topJob.facilityName}
                    </Typography>
                  </Box>
                )}
              </Grid>

              <Grid
                item
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box
                  sx={{
                    width: { xs: '200px', md: '100%' },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {(city || state) && (
                    <Box id="location" marginRight={4} sx={{ display: 'flex' }}>
                      <IconText
                        id="city-state"
                        icon={pinPointIcon}
                        text={location(city, state)}
                      />
                    </Box>
                  )}
                  {shiftType && (
                    <Box
                      id="shift"
                      marginRight={4}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                      <IconText
                        id="shift-type"
                        icon={clockIcon}
                        text={shiftType}
                      />
                    </Box>
                  )}

                  {duration && (
                    <Box
                      id="duration"
                      marginRight={4}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                      <IconText
                        id="duration"
                        icon={calendarIcon}
                        text={`${duration} Weeks`}
                      />
                    </Box>
                  )}
                  {displayDateInfo && (
                    <Box
                      id="duration"
                      marginRight={4}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                      <IconText
                        id="display-date-info"
                        icon={flagIcon}
                        text={displayDateInfo}
                      />
                    </Box>
                  )}
                </Box>
                {!isStrike && (
                  <Box
                    justifyContent="right"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: { xs: 'auto', md: '300px' },
                    }}
                  >
                    {(props?.topJob?.maxPayRate ?? 0) > 0 ? (
                      <>
                        <Grid item>
                          <Typography
                            variant="h4"
                            color="system.grassGreen"
                            component="div"
                            justifyContent="flex-end"
                            fontWeight="600"
                            data-testid="max-pay-rate"
                            sx={{
                              fontSize: '18px',
                              lineHeight: '27px',
                            }}
                          >
                            {payRange(minPayRate, maxPayRate)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            component="span"
                            color="system.coolGray"
                            sx={{ marginLeft: '6px' }}
                          >
                            /wk*
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <Grid item>
                        <Typography
                          variant="body2"
                          component="span"
                          color="system.coolGray"
                        >
                          Contact for Details
                        </Typography>
                      </Grid>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
