import { PayloadAction } from '@reduxjs/toolkit';
import { ILoginResponse } from 'interfaces/Authentication/ILoginResponse';
import { ICookieAuthenticationState } from 'interfaces/Cookie/ICookieAuthenticationState';
import moment from 'moment';
import { decodeToken } from 'utils/tokenHelper';

const cookieNames = [
  'passport_auth_cookie_at',
  'passport_auth_cookie_rt',
  'passport_auth_cookie_ci',
];

class CookieService {
  private static logger: any;
  static setLogger(loggerInstance: any) {
    this.logger = loggerInstance;
  }

  static authenticateViaCookie = (): ICookieAuthenticationState | undefined => {
    const cookie = document.cookie;

    const accessTokenParts = cookie.split(`${'passport_auth_cookie_at'}=`);
    const accessTokenFromCookie = accessTokenParts[1]?.split(';').shift();

    const refreshTokenParts = cookie.split(`${'passport_auth_cookie_rt'}=`);
    const refreshTokenFromCookie = refreshTokenParts[1]?.split(';').shift();

    const sessionTokenParts = cookie.split(`${'passport_auth_cookie_st'}=`);
    const sessionTokenFromCookie = sessionTokenParts[1]?.split(';').shift();

    const targetUrlParts = cookie.split(`${'passport_auth_cookie_tu'}=`);
    const targetUrlFromCookie = targetUrlParts[1]?.split(';').shift();

    const correlationIdTokenParts = cookie.split(
      `${'passport_auth_cookie_ci'}=`,
    );
    const correlationIdFromCookie = correlationIdTokenParts[1]
      ?.split(';')
      .shift();

    const isSSOParts = cookie.split(`${'passport_auth_cookie_sso'}=`);
    const isSSOFromCookie = Boolean(isSSOParts[1]?.split(';').shift());

    if (accessTokenFromCookie) {
      try {
        const parsedCookie = decodeToken(accessTokenFromCookie);

        const cookieNotExpired = new Date(parsedCookie.exp * 1000) > new Date();

        if (cookieNotExpired) {
          return {
            accessExpired: false,
            isLoggedIn: true,
            loginResponse: {
              accessToken: accessTokenFromCookie,
              refreshToken: refreshTokenFromCookie ?? '',
              idToken: '',
              correlationId: correlationIdFromCookie ?? '',
              featureToggles: [],
              sessionToken: sessionTokenFromCookie ?? '',
              targetUrl: targetUrlFromCookie ?? '',
              isSSO: isSSOFromCookie,
            },
            userId: parsedCookie.userid,
          };
        }
      } catch (err) {
        if (this.logger) {
          this.logger.error(err, 'authenticateViaCookie', 'CookieService.ts');
        }
      }
    }

    return {
      accessExpired: true,
      isLoggedIn: false,
      loginResponse: {
        accessToken: '',
        refreshToken: refreshTokenFromCookie ?? '',
        idToken: '',
        correlationId: correlationIdFromCookie ?? '',
        featureToggles: [],
        sessionToken: sessionTokenFromCookie ?? '',
        targetUrl: targetUrlFromCookie ?? '',
        isSSO: false,
      },
      userId: '',
    };
  };

  static setCookies = (action: PayloadAction<ILoginResponse>) => {
    document.cookie = `passport_auth_cookie_at=${action.payload.accessToken}; path=/`;
    document.cookie = `passport_auth_cookie_rt=${action.payload.refreshToken}; path=/`;
    document.cookie = `passport_auth_cookie_ci=${action.payload.correlationId}; path=/`;
    document.cookie = `passport_auth_cookie_st=${action.payload.sessionToken}; path=/`;
    document.cookie = `passport_auth_cookie_tu=${action.payload.targetUrl}; path=/`;
    document.cookie = `passport_auth_cookie_sso=${action.payload.isSSO}; path=/`;
  };

  static clearSessionCookies = () => {
    document.cookie = `passport_auth_cookie_st=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `passport_auth_cookie_tu=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  };

  static setAccessToken = (accessToken: string) => {
    document.cookie = `passport_auth_cookie_at=${accessToken}; path=/`;
  };

  static setLaborDayPopupCookie = () => {
    document.cookie = `passport_display_cookie_ldp=; path=/; expires=${moment(
      '2022-09-06T00:00:00',
    ).toDate()}`;
  };

  static clearCookies = () => {
    cookieNames.forEach(name => {
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    });
  };
}

export { CookieService };
