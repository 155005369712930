import { Divider, Grid, Typography } from '@mui/material';
import { ContactCardImage } from 'components/ContactCard/ContactCardImage';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import { IContact } from 'interfaces/User/Contact/IContact';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import ContactDetails from '../PersonalInfoDetails/ContactDetails';
import PersonalDetails from '../PersonalInfoDetails/PersonalDetails';

const QuestionsDrawerTemplate = (props: { isStrike: boolean }) => {
  const userContacts = useAppSelector(state =>
    !props.isStrike
      ? state.userContact.userContacts.userContacts
      : [state.userContact.strikeContactState],
  ) as IContact[];
  const isMobileDevice = useMobileMediaQuery();

  const preferredRecruiterId = useAppSelector(
    state => state.userPreference.userPreference?.preferredRecruiter,
  ) as number | null;

  const preferredRecruiters = userContacts.filter(c => {
    if (!props.isStrike) {
      if (!preferredRecruiterId) {
        return c.employeeRoleId === AssignmentRoleId.RECRUITER;
      }
      return (
        c.employeeRoleId === AssignmentRoleId.RECRUITER &&
        c.employeeId === preferredRecruiterId
      );
    }
    return c.employeeRoleId === AssignmentRoleId.RECRUITER;
  });

  const recruiters = userContacts.filter(
    c => c.employeeRoleId === AssignmentRoleId.RECRUITER,
  );

  const showPreferredBadge =
    (recruiters.length > 1 || typeof preferredRecruiterId == 'number') &&
    !props.isStrike;

  return (
    <>
      <Typography color="system.grayText" variant="subtitle1" mb={2}>
        Contact Us for Assistance
      </Typography>
      {preferredRecruiters?.map((contactItem: IContact, index: number) => (
        <Grid container rowSpacing={2} key={index}>
          <Grid
            item
            container
            justifyContent="start"
            display="flex"
            flexDirection="row"
            mt={index === 0 ? 0 : 2}
          >
            <Grid item xs={2} sm={1} md={2} width={60} height={60}>
              <ContactCardImage
                id="contact-image"
                avatarVariant="text"
                image={contactItem?.contactImage?.url}
                avatarText={
                  contactItem?.firstName + ' ' + contactItem?.lastName
                }
              />
            </Grid>
            <Grid
              item
              container
              xs={10}
              justifyContent="start"
              alignContent="flex-start"
              mt="10px"
              sx={{
                pl: { xs: '24px', sm: '10px' },
              }}
            >
              <Grid item xs={12}>
                <PersonalDetails
                  name={contactItem.firstName + ' ' + contactItem.lastName}
                  designation={contactItem.employeeRole}
                  contact={contactItem}
                  showPreferredBadge={showPreferredBadge}
                  isStrike={props.isStrike}
                />
              </Grid>
              {!isMobileDevice && (
                <ContactDetails
                  emailAddress={contactItem.emailAddress}
                  phoneNumber={contactItem.phoneNumber}
                  smsNumber={contactItem.smsNumber}
                />
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="start"
              alignContent="flex-start"
            >
              {isMobileDevice && (
                <ContactDetails
                  emailAddress={contactItem.emailAddress}
                  phoneNumber={contactItem.phoneNumber}
                  smsNumber={contactItem.smsNumber}
                />
              )}
            </Grid>
          </Grid>

          {++index !== preferredRecruiters.length && (
            <Divider
              variant="fullWidth"
              sx={{
                mt: 4,
                mb: 2,
                width: '100%',
              }}
            />
          )}
        </Grid>
      ))}
    </>
  );
};

export default QuestionsDrawerTemplate;
