import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';

export const getImpactTrackerService = async (userId: string): Promise<any> => {
  try {
    return await http.get(`impact-tracker/${userId}`);
  } catch (error) {
    logger.error(error, 'getImpactTrackerService', 'impactTrackerService.ts');
    return error;
  }
};
