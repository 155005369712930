import React from 'react';
import moment from 'moment';
import { boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import signature from '../../assets/images/Signature.png';
import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { useAppSelector } from 'store/hooks';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { LinkType } from 'enums/linkConfig';
import { arbitrationAgreementStates } from 'enums/arbitrationAgreement';
import { useDispatch } from 'react-redux';
import { arbitrationAgreementSliceActions } from 'store/slices/arbitrationAgreement/arbitrationAgreementSlice';
import { IArbitrationAgreement } from 'interfaces/ArbitrationAgreement/ArbitrationAgreement';
import { logger } from 'services/logging/appInsights';
import { credentialActions } from 'store/slices/credentials/credentialSlice';

export const ArbitrationAgreement = props => {
  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setActionButtonDisabled,
    setDrawerStateAfterSubmit,
  } = props;

  const dispatch = useDispatch();
  const [isSigned, setIsSigned] = React.useState(false);
  const [fullLegalName, setFullLegalName] = React.useState('');
  const travelerId: number = useAppSelector(
    state => state.userProfile.travelerId,
  );

  // CurrentArbitrationAgreement values are hardcoded in the slice - expecting api connector story.
  const currentArbitrationAgreement: IArbitrationAgreement = useAppSelector(
    state => state.arbitrationAgreement.currentArbitrationAgreement,
  );

  const isMobileDevice = useMobileMediaQuery();

  const actionItem = useAppSelector(state => {
    return state.actionItem;
  });
  const actionItemId = useAppSelector(state => {
    return state.actionItem.selectedItem?.actionItem?.id;
  });

  const selectedActionItemformStackDataUrl = React.useMemo(() => {
    return (
      (actionItem.selectedItem?.actionItem?.links || []).find(
        link => link.typeId === Number(LinkType.MobileDeepLink),
      )?.url ?? ''
    );
  }, [actionItem?.selectedItem?.actionItem?.links]);

  //========== extract entity name from action item url ==============//
  function getEntityName(url) {
    try {
      const urlObj = new URL(url);
      return urlObj.searchParams.get('EntityName');
    } catch (error) {
      logger.error(error, 'getEntityName', 'ArbitrationAgreement.tsx');
      return null;
    }
  }

  const entityName = getEntityName(selectedActionItemformStackDataUrl);
  //=====================================================================//

  const htmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>HTML Inline Styles Example</title>
  <style>
    /* Additional styles */
    body {
      margin: 0;
      padding: 0;
    }
    html {
      width: 100%;
      height: 100%;
      overflow-y: scroll; /* Enable scrolling */
    }
    /* Set scrollbar width to zero */
    html::-webkit-scrollbar {
      width: 0;
    }
  </style>
  </head>
  <body>
  <div class="container">
      <p style="color: #003C69;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 16px;">Mutual Arbitration Agreement</p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    This MUTUAL ARBITRATION AGREEMENT (“Agreement”) is made between the employee or prospective employee receiving this Agreement (“Employee”) and ${entityName} (“COMPANY,” and collectively with the Employee, “Parties”).
    </p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    COMPANY and Employee mutually consent to the resolution by binding arbitration of all claims (common law or statutory) that COMPANY might have against Employee, or that Employee may have against COMPANY (or its past, present and future affiliates, parents, subsidiaries, related entities, partners, benefit plans, actual or alleged joint employers, fiduciaries, administrators, and current and former officers, directors, employees, agents, representatives, owners, partners, shareholders or affiliates of any of the above, and all successors or assigns of any of them), or against any customer to which Employee is assigned hereunder. Covered claims include but are not limited to all claims arising out of, in connection with, or relating to Employee’s employment with COMPANY, and/or any past or future employment of Employee by COMPANY, to the fullest extent allowable under applicable law. Nothing in this Agreement prevents Employee from filing a complaint, charge, or other communication with any federal, state or local governmental or law enforcement agency (and recovering relief awarded by such agency). Nothing in this Agreement requires arbitration of any claim that under the law (after application of Federal Arbitration Act preemption principles) cannot be made subject to a pre-dispute agreement to arbitrate claims.
    </p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    To the maximum extent permitted by law, arbitration shall occur on an individual basis only. The Parties waive the right to initiate, participate in, or recover through, any class or collective action. Any claim under California’s Private Attorneys General Act of 2004 (PAGA) shall be arbitrated on an individual basis only (including any derivative claim of the State of California and its Labor and Workforce Development Agency to recover a share of the Employee’s individual recovery). If a demand for arbitration or lawsuit contains both arbitrable and non-arbitrable claims, the non-arbitrable claims shall be stayed pending arbitration to the maximum extent permitted by law. To the extent required by law, if Employee seeks to assert any PAGA non-individual representative claim on behalf of aggrieved employees other than themself, any such PAGA non-individual representative claim must proceed in court and not in arbitration and shall be stayed while any individual PAGA claim is arbitrated pursuant to this Agreement.
    <span style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px; font-weight:700;">
    COMPANY and Employee waive the right to have a court or jury trial on any arbitrable claim, the right to bring a class or collective action against the other in court or in arbitration, and rights of appeal of arbitration awards except as applicable law provides for judicial review of arbitration proceedings.
    </span>
    </p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    COMPANY’S offer of at-will employment, and the promises by COMPANY and Employee to arbitrate differences, rather than litigate them before courts or other bodies, provide consideration for each other. The Parties agree that this constitutes actual, knowing, and bargained for consideration. The Parties acknowledge the right of Employee to: report any good faith allegation of unlawful employment practices to any appropriate federal, state, or local government agency; report any good faith allegation of criminal conduct to any appropriate federal, state, or local official; participate in a proceeding with any appropriate federal, state, or local government agency and recover any such relief that agency awards; make any truthful statements or disclosures required by law, regulation, or legal process; and request or receive confidential legal advice. Nothing in this Agreement precludes any Party from seeking from a court temporary equitable relief in aid of arbitration, before the merits of the case are decided by the arbitrator.
    </p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    The Federal Arbitration Act (FAA) shall govern this Agreement, or if for any reason the FAA does not apply, the arbitration law of the state in which Employee rendered services to COMPANY at the time the claim arises. The arbitration shall be conducted under the employment arbitration rules of Judicial Arbitration and Mediation Services (JAMS), which are available at jamsadr.com, or from COMPANY upon Employee’s request, to the extent such rules do not conflict with this Agreement, in which case the terms of the Agreement shall govern.
    </p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    Any dispute regarding the formation, enforceability, and validity of this Agreement shall be determined by a court of competent jurisdiction, and not by an Arbitrator. The arbitrator shall provide a written award stating its essential findings. The Arbitrator shall have the authority to award any relief authorized by law in connection with the asserted claims or disputes. Judgment on the Arbitrator’s award may be entered in any court having jurisdiction over the Parties.
    </p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    COMPANY will pay the Arbitrator’s fee and any other type of expense or cost that the Employee would not be required to bear if he or she were free to bring the dispute(s) or claim(s) in court as well as any other expense or cost that is unique to arbitration. The Arbitrator shall make an award of prevailing-party attorneys’ fees as required by applicable law.
    </p>
    <p style="color: #75787B;font: normal normal normal 16px/22px 'Open Sans', sans-serif; font-size: 14px;">
    If any term or portion of this Agreement shall be held to be unenforceable, the remainder of this Agreement shall not be affected and shall remain in full force and effect, as if the unenforceable term had not existed. The Parties understand and agree that this Agreement supersedes any and all prior representations and agreements between them regarding these subjects. This Agreement survives after the employment relationship terminates and can only be revoked or modified in writing signed by both Parties.
    </p>
  </div>
  </body>
  </html>
`;

  const encodedHtmlContent = encodeURIComponent(htmlContent);
  const htmlContentURI = `data:text/html;charset=utf-8,${encodedHtmlContent}`;

  const validation = object({
    signed: boolean(),
    fullLegalName: string(),
  });

  const methods = useForm<{
    signed: boolean;
    fullLegalName: string;
  }>({
    mode: 'all',
    defaultValues: {
      signed: false,
      fullLegalName: '',
    },
    resolver: yupResolver(validation),
  });

  const { handleSubmit } = methods;

  React.useEffect(() => {
    if (setActionButtonDisabled) {
      setActionButtonDisabled(!(isSigned === true && fullLegalName !== ''));
    }
  }, [fullLegalName, isSigned, setActionButtonDisabled]);

  const resetForm = () => {
    setIsSigned(false);
    setFullLegalName('');
  };

  const submit = React.useCallback(() => {
    const payload = {
      fullLegalName,
      actionItemId,
    };
    dispatch(
      arbitrationAgreementSliceActions.signArbitrationAgreement(payload),
    );

    // delay call to fetch signed arbitration agreement under Human Resource in Task Center
    setTimeout(() => {
      dispatch(credentialActions.getCredentialDetailAction(true));
    }, 6000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fullLegalName]);

  React.useEffect(() => {
    if (onSubmitProcess) {
      handleSubmit(submit)();
      resetForm();
      setOnSubmitProcess?.(false);
      setDrawerStateAfterSubmit();
    }
  }, [
    dispatch,
    handleSubmit,
    onSubmitProcess,
    setDrawerStateAfterSubmit,
    setOnSubmitProcess,
    submit,
  ]);

  const authorizedSignatureImgUrl = signature;
  const currentArbitrationActionItem = actionItem.selectedItem?.actionItem;

  const renderSignatureAndDetails = () => (
    <Grid pt={2}>
      <img
        height="75px"
        alt="authorized signatory signature"
        src={authorizedSignatureImgUrl}
      />

      <Typography
        pt={2}
        pb={0.5}
        variant="body1"
        color="system.coolGray"
        data-testid="contract-signatory-name"
      >
        {currentArbitrationAgreement?.agreement?.authorizedSignatoryName}
      </Typography>
      <Typography pt={0.5} variant="body1" color="system.coolGray">
        {currentArbitrationAgreement?.agreement?.authorizedSignatoryTitle}
      </Typography>
      <Typography pt={0.5} pb={4} variant="body1" color="system.coolGray">
        {currentArbitrationAgreement?.agreement?.authorizedSignatorySubtitle}
      </Typography>
    </Grid>
  );

  return (
    <>
      <Grid container mb={4}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {currentArbitrationActionItem?.stateTypeId ===
            arbitrationAgreementStates.OPEN &&
            currentArbitrationActionItem?.createDate && (
              <Typography
                variant="subtitle1"
                color="system.grayText"
                data-testid="arbitration-agreement-unsigneddate"
              >
                {moment(currentArbitrationActionItem?.createDate).format(
                  'MMM DD, YYYY',
                )}
              </Typography>
            )}

          {currentArbitrationActionItem?.stateTypeId !==
            arbitrationAgreementStates.OPEN &&
            currentArbitrationAgreement?.signedDate && (
              <Typography
                variant="subtitle1"
                color="system.grayText"
                data-testid="arbitration-agreement-signeddate"
              >
                {moment(currentArbitrationAgreement?.signedDate).format(
                  'MMM DD, YYYY',
                )}
              </Typography>
            )}

          <Box
            sx={{
              backgroundColor: 'system.midnightBlue',
              borderRadius: '25px',
              px: 2,
              py: 1,
            }}
          >
            <Typography
              variant="body1"
              color="system.white"
              fontWeight="600"
              textTransform="uppercase"
              data-testid="contract-status"
            >
              {currentArbitrationActionItem?.stateTypeId ===
              arbitrationAgreementStates.OPEN
                ? 'READY TO SIGN'
                : 'SIGNED'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {htmlContentURI && (
        <iframe
          width="100%"
          height="100%"
          title="ArbitrationAgreement"
          src={htmlContentURI}
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        ></iframe>
      )}
      <Grid>
        {currentArbitrationActionItem?.stateTypeId !==
        arbitrationAgreementStates.OPEN ? (
          <Grid mt={8}>
            <Grid item>
              <Typography
                variant="subtitle1"
                color="system.coolGray"
                fontWeight="700"
              >
                AGREED AND ACCEPTED
              </Typography>
            </Grid>
            <Grid pt={2} container>
              <Grid item xs={isMobileDevice ? 3.75 : 3}>
                <Typography variant="body1" color="system.coolGray">
                  Professional:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="system.coolGray"
                  data-testid="contract-signee"
                >
                  {currentArbitrationAgreement?.agreement?.signee}
                </Typography>
              </Grid>
            </Grid>
            <Grid pt={1} container>
              <Grid item xs={isMobileDevice ? 3.75 : 3}>
                <Typography variant="body1" color="system.coolGray">
                  Traveler ID:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="system.coolGray"
                  data-testid="contract-traveler-id"
                >
                  {travelerId}
                </Typography>
              </Grid>
            </Grid>
            <Grid pt={1} container>
              <Grid item xs={isMobileDevice ? 3.75 : 3}>
                <Typography variant="body1" color="system.coolGray">
                  Signed:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="system.coolGray"
                  data-testid="contract-signed-date"
                >
                  {moment(currentArbitrationAgreement?.signedDate).format(
                    'MMM DD, YYYY hh:mm A',
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid pt={1} container>
              <Grid item xs={isMobileDevice ? 3.75 : 3}>
                <Typography variant="body1" color="system.coolGray">
                  IP Address:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="system.coolGray"
                  data-testid="contract-signed-date"
                >
                  {currentArbitrationAgreement.ipAddress}
                </Typography>
              </Grid>
            </Grid>
            {renderSignatureAndDetails()}
          </Grid>
        ) : (
          <Grid paddingLeft={'5px'}>
            {renderSignatureAndDetails()}
            <Divider
              sx={{
                mb: 4,
                width: '100%',
                height: '1px',
              }}
            />
            <FormProvider {...methods}>
              <Grid
                container
                id="arbitration-agreement-checkbox"
                sx={{ cursor: 'pointer' }}
              >
                <Grid item xs={1}>
                  <Checkbox
                    onChange={() => {
                      setIsSigned(!isSigned);
                    }}
                    color="secondary"
                    checked={isSigned}
                    sx={{ padding: '0px' }}
                    name="signed"
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    variant="body1"
                    color="system.coolGray"
                    lineHeight="20px"
                  >
                    I verify that by placing my name below and checking this box
                    constitutes my digital signature for this document.
                  </Typography>
                </Grid>
              </Grid>
              <Grid mt={4} mb={4} item xs={12}>
                <ReactHookFormTextField
                  label="Full Legal Name"
                  name="fullLegalName"
                  value={fullLegalName}
                  onChange={e => setFullLegalName(e?.target?.value)}
                />
              </Grid>
            </FormProvider>
          </Grid>
        )}
      </Grid>
    </>
  );
};
