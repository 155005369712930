import { httpSuccess } from 'helpers/serviceHelper';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { promotionsActions } from 'store/slices/promotions/promotionsSlice';
import {
  getPromotionsService,
  markPromotionsAsReadService,
} from 'services/promotions/promotionsService';
import { RootState } from 'store/configureStore';
import { setPromotionNotifications } from 'store/slices/user/userProfile/userProfileSlice';
import { logger } from 'services/logging/appInsights';

const trackedPromotionsGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-promotions');

const trackedPromotionsMarkAsRead = (fn, ...args) =>
  trackPromise(fn(...args), 'mark-promotions-as-read');

const userId = (state: RootState) => state.auth.userId;

const promotions = (state: RootState) => state.promotions?.promotions?.items;

function* getPromotions(action) {
  try {
    if ((action.payload?.offset ?? 0) === 0) {
      yield put(promotionsActions.resetPromotions());
    }
    const currentUserId = yield select(userId);
    const response = yield call(trackedPromotionsGet, getPromotionsService, {
      userId: currentUserId,
      offset: action.payload?.offset,
      limit: action.payload?.limit,
    });
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(promotionsActions.setPromotions(data));
      const unreadCount = data.items.filter(p => !p.isRead).length;
      yield put(setPromotionNotifications({ unreadCount }));
    }
  } catch (error: any) {
    logger.error(error, 'getPromotions', 'promotionsSaga.ts');
  }
}

function* markPromotionsAsRead(action) {
  try {
    const currentUserId = yield select(userId);
    const promotionItems = yield select(promotions);
    const promotionsToMarkAsRead = promotionItems.map(promotion => ({
      promotionId: promotion.id,
    }));
    if (promotionsToMarkAsRead && promotionsToMarkAsRead.length > 0) {
      yield call(
        trackedPromotionsMarkAsRead,
        markPromotionsAsReadService,
        currentUserId,
        {
          promotions: promotionsToMarkAsRead,
        },
      );
    }
  } catch (error: any) {
    logger.error(error, 'markPromotionsAsRead', 'promotionsSaga.ts');
  }
}

export function* promotionsSaga() {
  yield all([takeLatest(promotionsActions.getPromotions.type, getPromotions)]);
  yield all([
    takeLatest(
      promotionsActions.markPromotionsAsRead.type,
      markPromotionsAsRead,
    ),
  ]);
}
