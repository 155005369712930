import { IActionItem } from 'interfaces/ActionItem/IActionItem';
import { IContract } from 'interfaces/Contracts/Contract';
import { ICredentialChecklist } from 'interfaces/Credential/ICredentialChecklist';
import { IContact } from 'interfaces/User/Contact/IContact';
import { IContactImage } from 'interfaces/User/Contact/IContactImage';
import { ICredentialSummary, IStrikeDetails } from './AssignmentDetail';

export interface AssignmentSummaryItem {
  id: string;
  activityStatus: string;
  activityStatusId: number;
  startDate: string;
  endDate: string;
  typeId?: number;
  type?: string;
  image: string;
  facilityName: string;
  credentialCloseDate?: string;
  credentialSummary: ICredentialSummary;
  credentials?: ICredentialChecklist[];
  address: IAssignmentAddress;
  contacts: IAssignmentContacts[];
  contracts: IContract[];
  assignmentActivityStatus?: string;
  placementId: number;
  isDismissed: boolean;
  strike: IStrikeDetails;
  isTimeEntryEligible: boolean;
  actionItems: IActionItem[];
  isAutoSubmitted: boolean | null;
}

export interface LocumsAssignmentSummaryItem {
  id: string;
  ltOrderId: string;
  assignmentStatus: string;
  facility: IFacility;
  address: IAssignmentAddress;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
}

export interface IFacility {
  facilityId: string;
  name: string;
  city: string;
  state: string;
}

export interface ExpenseTypeItem {
  startDate: string;
  endDate: string;
  shiftTypes: IExpenseType[];
}

export interface IExpenseType {
  id: string;
  name: string;
}

export interface IAssignmentContacts {
  emailAddress: string;
  employeeId: number;
  employeeRole: string;
  employeeRoleId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  smsNumber: string;
  contactImage?: IAssignmentContactDetail;
}

interface IAssignmentContactDetail {
  date: string;
  url: string;
}

interface IAssignmentAddress {
  address1: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
}

const toIAssignmentContactDetail = (
  input?: IContactImage,
): IAssignmentContactDetail => {
  return {
    url: input?.url ?? '',
    date: input?.date ? new Date(input?.date)?.toDateString() : '',
  };
};

export const toAssignmentContact = (input: IContact): IAssignmentContacts => {
  return {
    emailAddress: input.emailAddress ?? '',
    employeeId: input.employeeId ?? 0,
    employeeRole: input.employeeRole ?? '',
    employeeRoleId: input.employeeRoleId ?? 0,
    firstName: input.firstName ?? '',
    lastName: input.lastName ?? '',
    phoneNumber: input.phoneNumber ?? '',
    smsNumber: input.smsNumber ?? '',
    contactImage: input.contactImage
      ? toIAssignmentContactDetail(input.contactImage)
      : undefined,
  };
};
