import { httpSuccess } from 'helpers/serviceHelper';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getHomePageDataService } from 'services/home/homeService';
import { logger } from 'services/logging/appInsights';
import { RootState } from 'store/configureStore';
import { homeActions } from 'store/slices/home/homeSlice';

const trackedHomePageDataGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-home-page-data');

const userId = (state: RootState) => state.auth.userId;

function* getHomePageData(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedHomePageDataGet,
      getHomePageDataService,
      currentUserId,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(homeActions.setHomePageData(data));
    }
  } catch (error: any) {
    logger.error(error, 'getHomePageData', 'homeSaga.ts');
  }
}

export function* homeSaga() {
  yield all([takeLatest(homeActions.getHomePageData.type, getHomePageData)]);
}
