import { JobFilterType } from 'enums/jobFilterType';

export const EXCLUSIVE_TAG_FILTER = 'Exclusive';
export const COMPACT_STATE_TAG_FILTER = 'Compact State';
export const TELEHEALTH_TAG_FILTER = 'Telehealth';
export const CLINICAL_FELLOWSHIP_TAG_FILTER = 'Clinical Fellowship';
export const CRISIS_TAG_FILTER = 'Crisis';
export const RAPID_RESPONSE_TAG_FILTER = 'Rapid Response/Crisis';
export const SEASONAL_TAG_FILTER = 'Seasonal';
export const STRIKE_TAG_FILTER = 'Strike';
export const COVID_TAG_FILTER = 'Covid';

export const DEFAULT_FILTER_TYPES = [
  { filterType: JobFilterType.CITY, values: [] },
  { filterType: JobFilterType.STATE, values: [] },
  { filterType: JobFilterType.LOCATION, values: [] },
  { filterType: JobFilterType.SKILLSET, values: [] },
  { filterType: JobFilterType.DISCIPLINE, values: [] },
  { filterType: JobFilterType.SPECIALTY, values: [] },
  { filterType: JobFilterType.MODALITY, values: [] },
  { filterType: JobFilterType.START_DATE, values: [] },
  { filterType: JobFilterType.SHIFT_NORMALIZED, values: [] },
  { filterType: JobFilterType.DURATION_INT, values: [] },
  { filterType: JobFilterType.TAGS, values: [] },
  { filterType: JobFilterType.JOB_TYPE, values: [] },
  { filterType: JobFilterType.JOB_ID, values: [] },
  { filterType: JobFilterType.COMMUNITIES, values: [] },
  { filterType: JobFilterType.FACILITY_TYPE, values: [] },
  { filterType: JobFilterType.EXPERIENCE_LEVEL, values: [] },
  { filterType: JobFilterType.SOURCE_SYSTEM, values: [] },
];

// sort filter
export const PAY_SORT_FILTER = 'Pay';
export const NEWEST_SORT_FILTER = 'Newest';
export const START_DATE_SORT_FILTER = 'Start Date';

export const INDEPENDENT_TAG_FILTERS = [
  COMPACT_STATE_TAG_FILTER,
  EXCLUSIVE_TAG_FILTER,
  TELEHEALTH_TAG_FILTER,
  CLINICAL_FELLOWSHIP_TAG_FILTER,
];

// Confidential Location
export const CONFIDENTIAL_LOCATION = 'Confidential Location';

// Combo filters
export const REMOTE_TELEHEALTH = `${JobFilterType.TAGS}-remote-telehealth`;
export const COMPACT_STATES = `${JobFilterType.TAGS}-compact-states`;
export const SHORT_TERM_CRISIS_EMERGENCY_RESPONSE = `${JobFilterType.TAGS}-short-term-crisis-emergency-response`;
export const AMN_EXCLUSIVE_CONTRACTS = `${JobFilterType.TAGS}-amn-exclusive-contracts`;

// Contract Length Filters
export const CL_1_8_WEEKS: string = '1-8 weeks';
export const CL_9_12_WEEKS = '9-12 weeks';
export const CL_13_WEEKS = '13 weeks';
export const CL_14_26_WEEKS = '14-26 weeks';
export const CL_27_PLUS_WEEKS = '27+ weeks';

export const CONTRACT_LENGTH_FILTERS = [
  CL_1_8_WEEKS,
  CL_9_12_WEEKS,
  CL_13_WEEKS,
  CL_14_26_WEEKS,
  CL_27_PLUS_WEEKS,
];

// Start Date Filters
export const SD_LT_2_Weeks = '<2 Weeks';
export const SD_15_30_WEEKS = '15-30 days';
export const SD_31_60_WEEKS = '31-60 days';
export const SD_GT_60_WEEKS = '>60 days';

export const START_DATE_FILTERS = [
  SD_LT_2_Weeks,
  SD_15_30_WEEKS,
  SD_31_60_WEEKS,
  SD_GT_60_WEEKS,
];
