import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CheckIconFill } from 'assets/icons/Check/CheckIcon-fill';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { IApplyJobMessageProps } from 'interfaces/Props/IApplyJobMessageProps';
import React from 'react';
import { theme } from 'styles/theme';

export const ApplyJobStatusMessageContent = (props: IApplyJobMessageProps) => {
  const {
    id,
    description,
    message,
    iconSize = '24px',
    isStrike = false,
    messageSx,
  } = props;

  const isMobileDevice = useMobileMediaQuery();

  const isSignedUpText = props && !props.isExpanded && !isMobileDevice;

  return (
    <Box
      id={id}
      sx={{
        display: 'flex',
        flexDirection: isSignedUpText ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...messageSx?.['container'],
      }}
    >
      <CheckIconFill
        sx={{
          color: theme.palette.system.skyBlue,
          width: iconSize,
          height: iconSize,
          marginTop: { xs: 0, sm: isStrike ? 0 : '12px' },
        }}
      />
      <Typography
        sx={{
          color: theme.palette.system.midnightBlue,
          font: 'body1',
          fontSize: { xs: '18px', sm: '14px' },
          textAlign: 'center',
          padding: { xs: 0, sm: isStrike ? 0 : '0 4px' },
          fontWeight: { xs: 600, sm: 400 },
          lineHeight: { xs: '24px', sm: 'auto' },
          ...messageSx,
        }}
      >
        {message}
      </Typography>
      {description && (
        <Typography
          sx={{
            color: theme.palette.system.coolGray,
            font: 'body1',
            fontSize: '14px',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              width: '100px',
            },
            marginTop: { xs: '12px', sm: '6px' },
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};
