import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';

import { CertificationsUpdate } from 'components/ActionItem/CertificationsUpdate/CertificationsUpdate';
import { ExternalLinkDrawer } from 'components/ActionItem/Drawer/ExternalLinkDrawer';
import { LicenseUpdate } from 'components/ActionItem/LicenseUpdate/LicenseUpdate';
import { OngoingStrikeUpdate } from 'components/ActionItem/OngoingStrikeUpdate/OngoingStrikeUpdate';
import { ContactsDrawerTemplate } from 'components/AssignmentDetail/AssignmentContacts/ContactsDrawerTemplate';
import { defaultStrikeRecruiter } from 'components/AssignmentDetail/AssignmentContacts/constants';
import { ConfirmItineraryContent } from 'components/Assignments/ConfirmItinerary/ConfirmItineraryContent';
import { ItineraryWebView } from 'components/Assignments/ConfirmItinerary/ItineraryWebView';
import ContractDetailPanel from 'components/Contracts/ContractDetailPanel';
import { ContractsPanel } from 'components/Contracts/ContractsPanel';
import { AddCredential } from 'components/Credentials/AddCredential/AddCredential';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { JobDetailsMobile } from 'components/Jobs/JobDetails/JobDetailsMobile';
import { PayTransparencyForm } from 'components/PayTransparency/PayTransparencyForm';
import { QualificationItemPanel } from 'components/QualificationItemPanelListItem/QualificationItemPanel';
import { ScheduleEventInfoPanel } from 'components/ScheduleEventInfoPanel/ScheduleEventInfoPanel';
import { TOAST_MESSAGE } from 'constants/helperText';
import { actionItemTypeId } from 'enums/actionType';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import { AssignmentType } from 'enums/assignmentType';
import { DrawerButtonTypes } from 'enums/buttons';
import { ContractStatusIdStates } from 'enums/contracts';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { FeatureToggles } from 'enums/featureToggles';
import { DrawerHeaderType } from 'enums/headerTypes';
import { LinkType } from 'enums/linkConfig';
import { sortByDate, sortByDateDescending } from 'helpers/dateFormatter';
import { toAssignmentContact } from 'interfaces/Assignment/AssignmentSummaryItem';
import { IContract } from 'interfaces/Contracts/Contract';
import {
  categorizeAssignments,
  getAssignmentContacts,
} from 'pages/Assignments/assignmentHelpers';
import { CognitiveJobDetailsMobile } from 'pages/JobSearch/CognitiveJobSearch/CognitiveJobDetails/CognitiveJobDetailsMobile';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { contractSliceActions } from 'store/slices/contracts/contractSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { jobShareActions } from 'store/slices/jobs/jobShareSlice';
import useQueryParams from 'utils/hooks/useQueryParams';
import { ConfirmationPopup } from '../ConfirmationPopup/ConfirmationPopup';
import ErrorDialog from '../Dialog/ErrorDialog';
import { DrawerPanel } from '../Drawer/Drawer';
import { ArbitrationAgreement } from 'pages/ArbitrationAgreement/ArbitrationAgreement';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { theme } from 'styles/theme';
import { arbitrationAgreementStates } from 'enums/arbitrationAgreement';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';

export const TaskActionItemDrawer = ({
  selectedPlacementId,
  setSelectedPlacementId,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const isMobileDevice = useMobileMediaQuery();

  const contractsPanelRef = React.useRef(null) as React.MutableRefObject<{
    handleHeaderIconClick: () => void;
  } | null>;

  const isACSJobSearch = useFeatureToggle(FeatureToggles.ENABLE_ACS_JOB_SEARCH);
  const isEnableStrikeScheduledShifts = useFeatureToggle(
    FeatureToggles.ENABLE_STRIKE_SCHEDULED_SHIFTS,
  );

  const actionItemTypeIdQueryParam = query.get('actionItemTypeId');
  const placementIdQueryParam = query.get('placementId');
  const storePlacementId = useAppSelector(
    state => state.assignmentSummary.placementId,
  );

  const selectedActionItemRef = useRef<any>(null);

  const user = useAppSelector(state => {
    if (state.userProfile) {
      return state.userProfile;
    }
  });
  const actionItem = useAppSelector(state => {
    return state.actionItem;
  });
  const openTasks = useAppSelector(state => {
    return state.credential.openRequirements;
  });
  const contractDetailsId = useAppSelector(state => {
    return state.credential.contractDetailsId;
  });
  const userAssignments = useAppSelector(
    state => state.assignmentSummary.assignmentState.items || [],
  );
  const isOpen = useAppSelector(state => state.credential.isOpenModal);
  const strikeContacts = useAppSelector(state =>
    (state.userContact.userContacts.strikeContacts || [])
      .filter(item => item.employeeRoleId !== AssignmentRoleId.RECRUITER)
      .map(item => toAssignmentContact(item)),
  );
  const contacts = useAppSelector(state => state.userContact.userContacts);
  const drawerOpen = useAppSelector(state => state.credential.actionItemDrawer);
  const jobDetailsData = useAppSelector(
    state => state.jobsV2.selectedJobDetails,
  );
  const isContractSaveInProgress = useAppSelector(
    state => state.contracts.contractSaveInProgress,
  );

  const [isDirty, setIsDirty] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [assignmentItinerary, setAssignmentItinerary] = useState<string>('');
  const [actionButtonTextState, setActionButtonTextState] =
    useState<string>('Submit');

  const { promiseInProgress: trackedConfirmItinerary } = usePromiseTracker({
    area: 'confirm-itinerary',
    delay: 0,
  });

  const dispatchDrawerAction = action =>
    dispatch(credentialActions.setActionItemDrawer(action));

  const handleErrorDialogClose = () => {
    setDialogOpen(false);
  };

  const handleErrorDialogConfirm = () => {
    setDialogOpen(false);
    dispatchDrawerAction(DrawerContentNames.SHOW_TRAVEL_DETAILS);
  };

  const handleUrlReset = useCallback(() => {
    if (actionItemTypeIdQueryParam || placementIdQueryParam) {
      dispatch(jobShareActions.setOriginUrl(''));
      dispatch(actionItemActions.resetSelectedItem());
      history.replace(window.location.pathname.split('?')[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleInvalidUrl = useCallback(() => {
    dispatch(jobShareActions.setOriginUrl(''));
    history.replace(window.location.pathname.split('?')[0]);
    dispatch(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.InvalidShareLink,
      }),
    );
  }, [dispatch, history]);

  const setItineraryUrl = useCallback(
    (placementId: number) => {
      let itinerary = '';
      let idx = userAssignments.findIndex(
        assignment => assignment.placementId === placementId,
      );
      if (idx > -1) {
        itinerary = userAssignments[idx].strike?.travelToStrike?.itineraryLink;
      }
      setAssignmentItinerary(itinerary);
    },
    [userAssignments],
  );

  const openReadyToSignDrawer = useCallback(
    (c: IContract) => {
      dispatch(contractSliceActions.setContract(c));
      dispatch(contractSliceActions.getContract());
      if (c?.statusId === ContractStatusIdStates.SIGNED) {
        dispatchDrawerAction(DrawerContentNames.CONTRACT_SIGNED);
      } else if (c?.statusId === ContractStatusIdStates.READY_TO_SIGN) {
        dispatchDrawerAction(DrawerContentNames.CONTRACT_READY_TO_SIGN);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCloseFormStackDrawer = useCallback(() => {
    dispatchDrawerAction(DrawerContentNames.CLOSED_DRAWER);
    if (placementIdQueryParam !== null && actionItemTypeIdQueryParam !== null) {
      handleUrlReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId]);

  const handleToggleDrawer = useCallback(
    (e, open) => {
      if (
        open === false &&
        drawerOpen === DrawerContentNames.SHOW_TRAVEL_DETAILS
      ) {
        handleUrlReset();
      }
      open === false && dispatchDrawerAction(DrawerContentNames.CLOSED_DRAWER);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawerOpen],
  );

  const assignmentContacts = useMemo(() => {
    let selectedPlacement = userAssignments.findIndex(
      assignment => assignment.placementId === selectedPlacementId,
    );
    if (selectedPlacement < 0) {
      return { assignmentContact: [], defaultContact: [] };
    } else {
      const isStrike =
        userAssignments[selectedPlacement].typeId === AssignmentType.STRIKE;

      let assignmentContact =
        (!isStrike
          ? userAssignments[selectedPlacement]?.contacts
          : strikeContacts.concat(
              toAssignmentContact(defaultStrikeRecruiter),
            )) || [];

      return getAssignmentContacts(
        userAssignments[selectedPlacement].activityStatus,
        userAssignments[selectedPlacement].activityStatusId,
        assignmentContact,
        isStrike,
        contacts,
      );
    }
  }, [selectedPlacementId, userAssignments, contacts, strikeContacts]);

  const contracts = useMemo(() => {
    let index = userAssignments.findIndex(
      assignment => assignment.placementId === selectedPlacementId,
    );
    if (index > -1) {
      return userAssignments[index].contracts || [];
    } else {
      return [];
    }
  }, [userAssignments, selectedPlacementId]);

  const handleSetDrawerStateAfterSubmit = useCallback(() => {
    if (contracts.length > 1) {
      dispatchDrawerAction(DrawerContentNames.VIEW_CONTRACTS);
    } else {
      dispatchDrawerAction(DrawerContentNames.CLOSED_DRAWER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts.length]);

  const facilityName = useMemo(() => {
    let index = userAssignments.findIndex(
      assignment => assignment.placementId === selectedPlacementId,
    );
    if (index > -1) {
      return userAssignments[index].facilityName || 'Facility';
    } else {
      return 'Facility';
    }
  }, [userAssignments, selectedPlacementId]);

  const strikeAssignmentTravelConfirmed = useMemo(() => {
    let index = userAssignments.findIndex(
      assignment => assignment.placementId === selectedPlacementId,
    );
    if (index > -1) {
      return userAssignments[index]?.strike?.travelConfirmed || false;
    } else {
      return false;
    }
  }, [userAssignments, selectedPlacementId]);

  const scheduleShiftsInfos = useMemo(() => {
    let index = userAssignments.findIndex(
      assignment => assignment.placementId === selectedPlacementId,
    );
    if (index > -1) {
      let wavesInfo = userAssignments[index]?.strike?.waves;
      if (wavesInfo && wavesInfo?.length > 0) {
        let categorizeWaves = categorizeAssignments(wavesInfo);
        return {
          pastShifts: sortByDateDescending(
            categorizeWaves.pastShifts,
            'startDateTime',
          ),
          upcomingShifts: sortByDate(
            categorizeWaves.upcomingShifts,
            'startDateTime',
          ),
        };
      }
    }
    return {
      pastShifts: [],
      upcomingShifts: [],
    };
  }, [userAssignments, selectedPlacementId]);

  const selectedActionItemformStackDataUrl = useMemo(() => {
    return (
      (actionItem.selectedItem?.actionItem?.links || []).find(
        link => link.typeId === Number(LinkType.Formstack),
      )?.url ?? ''
    );
  }, [actionItem?.selectedItem?.actionItem?.links]);

  const externalLinkDrawerTitle = useMemo(() => {
    switch (actionItem?.selectedItem?.actionItem?.actionItemTypeId) {
      case actionItemTypeId.StrikeConfirmation:
        return 'Strike Confirmation';
      default:
        return actionItem.selectedItem?.actionItem?.title || '';
    }
  }, [
    actionItem.selectedItem?.actionItem?.actionItemTypeId,
    actionItem.selectedItem?.actionItem?.title,
  ]);

  // @TODO - create enum for contentName, actionButtonText, cancelButtonText properties
  const DrawerContent = useMemo(() => {
    let content = <></>;
    let contentName: string | null = '';
    let actionButtonText = '';
    let cancelButtonText = '';
    let previousDrawer: DrawerContentNames = DrawerContentNames.CLOSED_DRAWER;
    let goBackNotClose = false;
    let secondaryButtonType = DrawerButtonTypes.CANCEL;
    let headerType = DrawerHeaderType.Close;
    let contentStyles = {
      height: '100%',
      padding: 'inherit',
    };
    let isSubmitLoaderActive = false;
    let showFooter = true;
    let alertId = '';
    let primaryBtnDisabled = false;
    let backRightAdornment = <></>;
    let headerIconClick;

    switch (drawerOpen) {
      case DrawerContentNames.LICENSE_UPDATE:
        content = <LicenseUpdate setDrawerOpen={dispatchDrawerAction} />;
        contentName = 'Add Credential';
        actionButtonText = 'Submit for Approval';
        cancelButtonText = 'CANCEL';
        secondaryButtonType = DrawerButtonTypes.BACK;
        goBackNotClose = true;
        break;
      case DrawerContentNames.CERTIFICATIONS_UPDATE:
        content = <CertificationsUpdate setDrawerOpen={dispatchDrawerAction} />;
        contentName = 'Add Credential';
        actionButtonText = 'Submit for Approval';
        cancelButtonText = 'CANCEL';
        secondaryButtonType = DrawerButtonTypes.BACK;
        goBackNotClose = true;
        break;
      case DrawerContentNames.PAY_TRANSPARENCY:
        content = (
          <PayTransparencyForm
            setDrawerOpen={dispatchDrawerAction}
            gender={user?.gender}
            ethnicityType={user?.ethnicityTypeId}
          />
        );
        contentName = 'EEO Demographic Information';
        actionButtonText = 'SUBMIT';
        cancelButtonText = 'CANCEL';
        break;
      case DrawerContentNames.ADD_CREDENTIAL:
        if (
          actionItem?.selectedItem?.actionItem?.actionItemTypeId !==
            actionItemTypeId.CovidBoosterEligible &&
          actionItem?.selectedItem?.actionItem?.actionItemTypeId !==
            actionItemTypeId.Covid19VaccinationCard
        )
          dispatch(actionItemActions.resetSelectedItem());
        content = <AddCredential setDrawerOpen={dispatchDrawerAction} />;
        contentName = 'Add Credential';
        actionButtonText = 'Submit for Approval';
        cancelButtonText = 'CANCEL';
        break;
      case DrawerContentNames.SHOW_TRAVEL_DETAILS:
        content = (
          <ItineraryWebView
            url={assignmentItinerary}
            id="assignment-itinerary"
            setDrawerOpen={dispatchDrawerAction}
            height="100%"
            width="100%"
            travelConfirmed={false}
          />
        );
        contentName = 'Confirm Travel Details';
        actionButtonText = 'Continue to Confirmation';
        cancelButtonText = 'CANCEL';
        previousDrawer = DrawerContentNames.CLOSED_DRAWER;
        isSubmitLoaderActive = trackedConfirmItinerary;
        break;
      case DrawerContentNames.CONFIRM_TRAVEL_DETAILS:
        content = (
          <ConfirmItineraryContent
            setIsDirty={setIsDirty}
            placementId={selectedPlacementId}
            setDrawerOpen={dispatchDrawerAction}
            setActionButtonText={(name: string) => {
              setActionButtonTextState(name);
            }}
          />
        );
        contentName = 'Confirm Travel Details';
        actionButtonText = actionButtonTextState;
        cancelButtonText = 'BACK TO DETAILS';
        previousDrawer = DrawerContentNames.SHOW_TRAVEL_DETAILS;
        secondaryButtonType = DrawerButtonTypes.BACK;
        goBackNotClose = true;
        isSubmitLoaderActive = trackedConfirmItinerary;
        break;
      case DrawerContentNames.VIEW_CONTRACTS:
        content = (
          <ContractsPanel
            ref={contractsPanelRef}
            contracts={contracts}
            facilityName={facilityName}
            openReadyToSign={openReadyToSignDrawer}
            placementId={selectedPlacementId}
          />
        );
        backRightAdornment = (
          <DownloadIcon
            sx={{
              cursor: !isContractSaveInProgress ? 'pointer' : 'auto',
              marginTop: '5px',
            }}
            htmlColor={
              !isContractSaveInProgress
                ? theme.palette.system.skyBlue
                : theme.palette.system.coolGray
            }
            width="18px"
            height="18px"
          />
        );
        headerIconClick = () => {
          if (contractsPanelRef.current && !isContractSaveInProgress) {
            contractsPanelRef.current.handleHeaderIconClick();
          }
        };
        contentName = 'Contracts and Addendums';
        previousDrawer = DrawerContentNames.CLOSED_DRAWER;
        showFooter = false;
        alertId = 'contract-drawer';
        isSubmitLoaderActive = isContractSaveInProgress;
        break;
      case DrawerContentNames.VIEW_CONTACTS:
        content = (
          <ContactsDrawerTemplate
            assignmentContactDetails={assignmentContacts.assignmentContact}
            defaultContactDetails={assignmentContacts.defaultContact}
          />
        );
        contentName = 'My Contacts';
        showFooter = false;
        headerType = DrawerHeaderType.Close;
        previousDrawer = DrawerContentNames.CLOSED_DRAWER;
        break;
      case DrawerContentNames.CONTRACT_READY_TO_SIGN:
        content = (
          <ContractDetailPanel
            ref={contractsPanelRef}
            contracts={contracts}
            setDrawerStateAfterSubmit={handleSetDrawerStateAfterSubmit}
            placementId={selectedPlacementId}
            facilityName={facilityName}
          />
        );
        contentName = facilityName ?? 'Facility Name';
        actionButtonText = 'Submit';
        cancelButtonText = 'CANCEL';
        secondaryButtonType = DrawerButtonTypes.BACK;
        previousDrawer =
          contracts.length > 1
            ? DrawerContentNames.VIEW_CONTRACTS
            : DrawerContentNames.CLOSED_DRAWER;
        headerType = DrawerHeaderType.Close;
        goBackNotClose = contracts?.length > 1 ? true : false;
        alertId = 'contract-drawer';
        backRightAdornment = (
          <DownloadIcon
            sx={{
              cursor: !isContractSaveInProgress ? 'pointer' : 'auto',
              marginTop: '5px',
            }}
            htmlColor={
              !isContractSaveInProgress
                ? theme.palette.system.skyBlue
                : theme.palette.system.coolGray
            }
            width="18px"
            height="18px"
          />
        );
        headerIconClick = () => {
          if (contractsPanelRef.current && !isContractSaveInProgress) {
            contractsPanelRef.current.handleHeaderIconClick();
          }
        };
        isSubmitLoaderActive = isContractSaveInProgress;
        break;
      case DrawerContentNames.CONTRACT_SIGNED:
        content = (
          <ContractDetailPanel
            ref={contractsPanelRef}
            contracts={contracts}
            setDrawerStateAfterSubmit={handleSetDrawerStateAfterSubmit}
            placementId={selectedPlacementId}
            facilityName={facilityName}
          />
        );
        contentName = facilityName ?? 'Facility Name';
        showFooter = false;
        headerType = DrawerHeaderType.Close;
        previousDrawer =
          contracts.length > 1
            ? DrawerContentNames.VIEW_CONTRACTS
            : DrawerContentNames.CLOSED_DRAWER;
        goBackNotClose = contracts?.length > 1 ? true : false;
        alertId = 'contract-drawer';
        backRightAdornment = (
          <DownloadIcon
            sx={{
              cursor: !isContractSaveInProgress ? 'pointer' : 'auto',
              marginTop: '5px',
            }}
            htmlColor={
              !isContractSaveInProgress
                ? theme.palette.system.skyBlue
                : theme.palette.system.coolGray
            }
            width="18px"
            height="18px"
          />
        );
        headerIconClick = () => {
          if (contractsPanelRef.current && !isContractSaveInProgress) {
            contractsPanelRef.current.handleHeaderIconClick();
          }
        };
        isSubmitLoaderActive = isContractSaveInProgress;
        break;
      case DrawerContentNames.QUALIFICATION_ITEMS:
        content = (
          <QualificationItemPanel
            selectedPlacementId={selectedPlacementId}
            setDrawerOpen={dispatchDrawerAction}
          />
        );
        contentName = 'Items Needing Completion';
        showFooter = false;
        headerType = DrawerHeaderType.Close;
        previousDrawer = DrawerContentNames.CLOSED_DRAWER;
        break;
      case DrawerContentNames.FORMSTACK:
        contentName = 'Strike Confirmation';
        content = (
          <>
            <ExternalLinkDrawer
              title={externalLinkDrawerTitle}
              url={selectedActionItemformStackDataUrl}
              onClose={handleCloseFormStackDrawer}
            />
          </>
        );
        break;
      case DrawerContentNames.SCHEDULE_SHIFTS:
        content = (
          <ScheduleEventInfoPanel
            scheduleShiftsInfos={scheduleShiftsInfos}
            facilityName={facilityName}
            enableScheduledShifts={isEnableStrikeScheduledShifts ?? false}
          />
        );
        contentName = 'Scheduled Shifts';
        showFooter = false;
        headerType = DrawerHeaderType.Close;
        previousDrawer = DrawerContentNames.CLOSED_DRAWER;
        break;
      case DrawerContentNames.ONGOING_STRIKE_APPLY:
        content = <OngoingStrikeUpdate setDrawerOpen={dispatchDrawerAction} />;
        contentName = 'Apply for Strike';
        actionButtonText = 'Submit';
        cancelButtonText = 'CANCEL';
        headerType = DrawerHeaderType.Close;
        previousDrawer = DrawerContentNames.CLOSED_DRAWER;
        break;
      case DrawerContentNames.SHOW_JOB_DETAILS:
        content = isACSJobSearch ? (
          <CognitiveJobDetailsMobile
            jobDetailsData={jobDetailsData}
            isFromNonConsecutiveDrawer={true}
            onCloseClick={() =>
              dispatchDrawerAction(DrawerContentNames.ONGOING_STRIKE_APPLY)
            }
          />
        ) : (
          <JobDetailsMobile
            onCloseClick={() =>
              dispatchDrawerAction(DrawerContentNames.ONGOING_STRIKE_APPLY)
            }
            isFromNonConsecutiveDrawer={true}
          />
        );
        contentStyles.padding = '0px !important';
        contentName = null;
        previousDrawer = DrawerContentNames.ONGOING_STRIKE_APPLY;
        goBackNotClose = true;
        showFooter = false;
        break;
      case DrawerContentNames.ARBITRATION_AGREEMENT:
        content = (
          <ArbitrationAgreement
            setDrawerStateAfterSubmit={handleSetDrawerStateAfterSubmit}
          />
        );
        contentName = externalLinkDrawerTitle;
        goBackNotClose = false;
        showFooter =
          actionItem.selectedItem?.actionItem?.stateTypeId ===
          arbitrationAgreementStates.OPEN;
        actionButtonText = isMobileDevice ? 'Send Nomination' : 'Submit';
        cancelButtonText = 'CANCEL';
        break;
    }

    const handleSecondaryClick = () => {
      if (isDirty) {
        setDialogOpen(true);
      } else {
        if (drawerOpen === DrawerContentNames.SHOW_TRAVEL_DETAILS) {
          handleUrlReset();
        }
        dispatchDrawerAction(previousDrawer);
      }
    };

    return (
      <DrawerPanel
        open={contentName !== undefined && contentName !== ''}
        actionButtonText={actionButtonText}
        cancelButtonText={cancelButtonText}
        toggleDrawer={(e, open) => handleToggleDrawer(e, open)}
        contentName={contentName}
        secondaryButtonType={secondaryButtonType}
        previousDrawer={previousDrawer}
        goBackNotClose={goBackNotClose}
        headerType={headerType}
        contentStyles={contentStyles}
        isSubmitLoaderActive={isSubmitLoaderActive}
        secondaryOnClick={() => handleSecondaryClick()}
        drawerCloseValidation={handleSecondaryClick}
        alertId={alertId}
        showFooter={showFooter}
        primaryBtnDisabled={primaryBtnDisabled}
        backRightAdornment={backRightAdornment}
        backRightAdornmentClick={headerIconClick}
      >
        {content}
      </DrawerPanel>
    );
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    drawerOpen,
    user?.gender,
    user?.ethnicityTypeId,
    actionItem?.selectedItem?.actionItem?.actionItemTypeId,
    dispatch,
    assignmentItinerary,
    trackedConfirmItinerary,
    selectedPlacementId,
    actionButtonTextState,
    contracts,
    facilityName,
    openReadyToSignDrawer,
    assignmentContacts.assignmentContact,
    assignmentContacts.defaultContact,
    handleSetDrawerStateAfterSubmit,
    externalLinkDrawerTitle,
    selectedActionItemformStackDataUrl,
    handleCloseFormStackDrawer,
    scheduleShiftsInfos,
    isDirty,
    handleUrlReset,
    isACSJobSearch,
    isContractSaveInProgress,
  ]);

  useEffect(() => {
    // If the contractDetailsId exists, open the contract details drawer
    if (contractDetailsId) {
      const contract = contracts.find(c => c.contractId === contractDetailsId);
      if (contract) {
        openReadyToSignDrawer(contract);
        dispatch(credentialActions.setContractDetailsId(undefined));
      }
    }
  }, [contractDetailsId, selectedPlacementId, contracts]);

  useEffect(() => {
    if (!selectedPlacementId) {
      if (!storePlacementId) {
        // We want to store the selected action item in a ref so that we can use it in the formstack message handler
        const selectedItem = actionItem?.selectedItem;
        const placementId = selectedItem?.contract?.placementId;

        if (placementId) {
          setSelectedPlacementId(placementId);
          dispatch(assignmentActions.setPlacementId(placementId));
        }

        selectedActionItemRef.current = selectedItem;
      } else if (storePlacementId) {
        setSelectedPlacementId(storePlacementId);
      }
    }
  }, [actionItem?.selectedItem, storePlacementId]);

  useEffect(() => {
    // if params don't exist, do nothing
    if (actionItemTypeIdQueryParam === null || placementIdQueryParam === null) {
      return;
    }

    // if params exists but they aren't of the correct type, handle invalid url
    if (
      (actionItemTypeIdQueryParam !== null &&
        isNaN(Number(actionItemTypeIdQueryParam))) ||
      (placementIdQueryParam !== null && isNaN(Number(placementIdQueryParam)))
    ) {
      handleInvalidUrl();
      return;
    }

    // if params exist and are of the correct type and we have action items, then we can proceed
    if (
      actionItemTypeIdQueryParam !== null &&
      typeof actionItemTypeIdQueryParam !== 'number' &&
      placementIdQueryParam !== null &&
      typeof placementIdQueryParam !== 'number' &&
      openTasks &&
      openTasks.length > 0
    ) {
      let selectItem = openTasks.find(item => {
        return (
          item?.actionItem?.actionItemTypeId ===
            Number(actionItemTypeIdQueryParam) &&
          item?.actionItem?.placementId === Number(placementIdQueryParam)
        );
      });

      if (!selectItem) handleInvalidUrl();
      if (selectItem) {
        dispatch(actionItemActions.setSelectedItem(selectItem));
        switch (+actionItemTypeIdQueryParam) {
          case actionItemTypeId.ConfirmyourTravelItinerary:
            setSelectedPlacementId(+placementIdQueryParam);
            setItineraryUrl(+placementIdQueryParam);
            dispatchDrawerAction(DrawerContentNames.SHOW_TRAVEL_DETAILS);
            break;
          case actionItemTypeId.StrikeConfirmation:
            dispatchDrawerAction(DrawerContentNames.FORMSTACK);
            break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTasks, actionItemTypeIdQueryParam, placementIdQueryParam]);

  useEffect(() => {
    //This is formstack message event handler
    const formStackMessegeHandler = event => {
      if (event?.data === 'fs-form-submit') {
        handleUrlReset();
        setTimeout(() => {
          dispatchDrawerAction(DrawerContentNames.CLOSED_DRAWER);
        }, 3000);

        // we cannot use the actionItem.selectedItem.id because it's referencing the stale value
        dispatch(
          actionItemActions.removeActionItem({
            dismissals: { dismissals: [selectedActionItemRef.current.id] },
          }),
        );
      }
    };
    window.addEventListener('message', formStackMessegeHandler);
    return () => {
      window.removeEventListener('message', formStackMessegeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Listening to custom drawer close event when action item link is shared
    const drawerCloseHandler = event => {
      handleUrlReset();
    };
    window.addEventListener('drawer-close', drawerCloseHandler);
    return () => {
      window.removeEventListener('drawer-close', drawerCloseHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set itinerary url when action item is selected
  // This is for showComponentForDl fxn in TaskActionsManager
  // when the actionItemTypeId is ConfirmyourTravelItinerary
  useEffect(() => {
    if (
      actionItem &&
      actionItem?.selectedItem &&
      actionItem?.selectedItem?.actionItemTypeId ===
        actionItemTypeId.ConfirmyourTravelItinerary
    ) {
      setItineraryUrl(
        +(actionItem?.selectedItem?.actionItem?.placementId ?? 0),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionItem?.selectedItem]);

  return (
    <>
      {DrawerContent}
      <ConfirmationPopup
        title="Got it!"
        content="Credentials submitted will be reviewed and validated. Once validated, they will appear on your credentials list."
        isPanelOpen={isOpen}
        isHandleProceedEnabled={true}
        handleClose={() => {
          dispatch(credentialActions.setModalState(false));
        }}
        handleProceed={() => {
          dispatchDrawerAction(DrawerContentNames.ADD_CREDENTIAL);
          dispatch(credentialActions.setModalState(false));
        }}
        affirmative="Add Another"
        negative="ALL DONE"
        isTextCentered={true}
        isIcon={true}
      />
      <ErrorDialog
        title="Are you sure?"
        children="You have unsaved changes on this page, are you sure you want to discard them?"
        open={dialogOpen}
        handleClose={handleErrorDialogClose}
        dismissText="CANCEL"
        confirmText="DISCARD"
        handleConfirm={handleErrorDialogConfirm}
        paperStyle={{ maxWidth: '280px' }}
      />
    </>
  );
};
