export const apiLinksDictionary = {
  'AMNOne://AssignmentsPage': '/assignments',
  'AMNOne://AutoSubmitPreferencesPage': '/preferences?categoryId=500',
  'AMNOne://CommunityPageView': '/community',
  'AMNOne://ContactsPage': '/contacts',
  'AMNOne://CredentialCenter': '/task-center',
  'AMNOne://CredentialSubmissions': '/task-center?categoryId=9998',
  'AMNOne://CredentialWallet': '/task-center',
  'AMNOne://Education': '/profile',
  'AMNOne://HearFromYourCommunityPageView': '/hear-from-your-community',
  'AMNOne://JobPreferences': '/preferences?categoryId=100',
  'AMNOne://JobSearchPage': '/jobs',
  'AMNOne://JobSearchPage?sort=1': '/jobs', // Job Search (Start Date)
  'AMNOne://JobSearchPage?sort=2': '/jobs', // Job Search (Most Recent)
  'AMNOne://JobSearchPage?sort=3': '/jobs', // Job Search (Highest Pay)
  'AMNOne://JobSearchPage?sort=4': '/jobs',
  'AMNOne://StrikeJobSearchPage': '/jobs?filter=strike',
  'AMNOne://PersonalProfile': '/profile?update=personalInfo',
  'AMNOne://ProfilePageView?isSelected="IsSelected"': '/profile#impact-tracker',
  'AMNOne://ProfilePagePromotionsView?isPromotionsSelected="PromotionsSelected"':
    '/promotions',

  'AMNOne://Resources?categoryid=100': '/resources?categoryId=100', // Education
  'AMNOne://Resources?categoryid=200': '/resources?categoryId=200', // Employment Req
  'AMNOne://Resources?categoryid=300': '/resources?categoryId=300', // Benefits
  'AMNOne://Resources?categoryid=400': '/resources?categoryId=400', // Payroll, Tax, etc
  'AMNOne://Resources?categoryid=500': '/resources?categoryId=500', // Housing and Travel
  'AMNOne://Resources?categoryid=9999': '/resources?categoryId=600', // Submitted Forms
  'AMNOne://Resources?resourceid=direct-deposit-authorization':
    'direct-deposit-authorization',
  'AMNOne://Resources?resourceid=passport-pay-howto': 'passport-pay-howto',
  'AMNOne://Resources?resourceid=tax-home-declaration': 'tax-home-declaration',
  'AMNOne://Resources?resourceid=w-4-employee-s-withholding-certificate':
    'w-4-employee-s-withholding-certificate',
  'AMNOne://ResourcesPage': '/resources',

  'AMNOne://ShareAndRefer': '/refer-a-clinician',
  'AMNOne://ReferClinician': '/refer-a-clinician?showform=true',
  'AMNOne://SkillsChecklist': '/skills-checklists',
  'AMNOne://TimeAndPay?showpay=false': '/time-entry',
  'AMNOne://TimeAndPay?showpay=true': '/payroll',
  'AMNOne://WorkHistory': '/profile',
  'AMNOne://Reimbursements': '/reimbursements',
  'AMNOne://HelpCenterView': '/help-center',
  'AMNOne://CoBrowseScreenSharePageView': '/help-center/screen-share', //Screen share page
  'AMNOne://JobsPageView?filters=JobType:Strike':
    '/jobs?filters=JobType:Strike',
};

const mapApiLinkToRoutes = (link: string): string => {
  // get the query params from the link, if it exists
  const linkArr = link.split('?');
  let params;
  if (linkArr.length > 1) {
    params = new URLSearchParams(linkArr[1]);
  }

  // if the link matches the following, we need to add the id to the route
  switch (linkArr[0]) {
    case 'AMNOne://Blogs': // example: AMNOne://Blogs?Id={BlogId}
      return `/community/${params.get('Id')}`;
    case 'AMNOne://Job': // example: AMNOne://Job?JobId={jobId}
      return `/jobs/${params.get('JobId')}`;
    default:
      break;
  }

  // if not a parameterized deep link, defer to the dictionary
  return apiLinksDictionary[link];
};

export default mapApiLinkToRoutes;
