import { httpSuccess } from 'helpers/serviceHelper';
import { IErrorResponseData } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { trackPromise } from 'react-promise-tracker';
import {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  markNotificationAsDeleteService,
  markNotificationAsReadService,
  markNotificationAsUnReadService,
} from 'services/notifications/notificationsService';
import { notificationsActions } from 'store/slices/notifications/notificationsSlice';
import { NotificationCategory } from 'enums/notificationsCategory';
import moment from 'moment';
import { TOAST_MESSAGE } from 'constants/helperText';
import { logger } from 'services/logging/appInsights';

export const userIdSelector = (state: RootState) => state.auth.userId;
export const approverSelector = (state: RootState) =>
  state.timesheet.selectedTimesheetApprovers;

const trackedNotificationsData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-notifications');
const trackedUnreadNotificationCount = (fn, ...args) =>
  trackPromise(fn(...args), 'get-unread-notifications-count');

function* getNotificationsData(action) {
  try {
    const maxNotificationsDaysAgoLimit = 60;
    const userId = yield select(userIdSelector);
    const response = yield call(trackedNotificationsData, fetchNotifications, {
      userId,
      limit: action.payload?.limit,
      offset: action.payload?.offset,
    });
    const { data } = response;
    if (response && httpSuccess(response?.status)) {
      if (data) {
        let { items } = data;
        const { paging } = data;
        items = items.map(i =>
          i?.categoryId === NotificationCategory.General
            ? { ...i, category: 'Reminder' }
            : i,
        );
        const today = moment();
        items = items?.filter(notification => {
          const newDate = moment(notification.createDate).format('M/DD/YYYY');
          const diff = today.diff(newDate, 'days');
          return diff <= maxNotificationsDaysAgoLimit;
        });
        yield put(notificationsActions.setNotificationsList(items));
        yield put(
          notificationsActions.setTotalNotificationsCount(paging?.total),
        );
      } else {
        yield put(notificationsActions.setNotificationsList([]));
        yield put(notificationsActions.setTotalNotificationsCount(0));
      }
    } else if (response && !httpSuccess(response?.status)) {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
          alertbarRefId: 'notification',
        }),
      );
      yield put(
        notificationsActions.setError({
          errorCode: response.statusCode,
          errorData: {
            emailAddress: '',
          },
          errorDetails: '',
          errorMessage: TOAST_MESSAGE.SomethingWentWrongTryReloading,
          operationId: '',
          correlationId: '',
        }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(error, 'getNotificationsData', 'notificationsSaga.ts');
  }
}

function* getUnreadNotificationCountData() {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(
      trackedUnreadNotificationCount,
      fetchUnreadNotificationsCount,
      userId,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status)) {
      if (data) {
        yield put(notificationsActions.unSetError());
        yield put(
          notificationsActions.setUnreadNotificationsCount(data?.count),
        );
      } else {
        yield put(notificationsActions.setUnreadNotificationsCount(0));
      }
    } else if (response && !httpSuccess(response?.status)) {
      yield put(
        notificationsActions.setError({
          errorCode: response.statusCode,
          errorData: {
            emailAddress: '',
          },
          errorDetails: '',
          errorMessage: TOAST_MESSAGE.SomethingWentWrongTryReloading,
          operationId: '',
          correlationId: '',
        }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(
      error,
      'getUnreadNotificationCountData',
      'notificationsSaga.ts',
    );
  }
}

function* markNotificationAsReadSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const notifications = action?.payload?.notifications
      ?.filter?.(id => typeof id === 'string')
      .map(notificationId => ({ notificationId }));

    const markAllRead = action?.payload?.markAllRead;

    const response = yield call(
      markNotificationAsReadService,
      userId,
      notifications,
      markAllRead,
    );

    if (response && httpSuccess(response?.status)) {
      const { data } = response;

      if (data?.isUpdated) {
        yield put(notificationsActions.setNotificationAsRead(notifications));
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(error, 'markNotificationAsReadSaga', 'notificationsSaga.ts');
  }
}

function* markNotificationAsDeletedSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const notificationInfo = action?.payload
      ?.filter?.(id => typeof id === 'string')
      .map(notificationId => ({ notificationId }));

    const response = yield call(
      markNotificationAsDeleteService,
      userId,
      notificationInfo,
    );

    if (response && httpSuccess(response?.status)) {
      const { data } = response;

      if (data?.isUpdated) {
        yield put(notificationsActions.deleteNotification(notificationInfo));
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(
      error,
      'markNotificationAsDeletedSaga',
      'notificationsSaga.ts',
    );
  }
}

function* markNotificationAsUnReadSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const notifications = action?.payload
      ?.filter?.(id => typeof id === 'string')
      .map(notificationId => ({ notificationId }));

    const response = yield call(
      markNotificationAsUnReadService,
      userId,
      notifications,
    );

    if (response && httpSuccess(response?.status)) {
      const { data } = response;

      if (data?.isUpdated) {
        yield put(
          notificationsActions.setNotificationAsAllUnRead(notifications),
        );
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(error, 'markNotificationAsUnReadSaga', 'notificationsSaga.ts');
  }
}

export function* notificationsSaga() {
  yield all([
    takeLatest(
      notificationsActions.getNotificationsAction.type,
      getNotificationsData,
    ),
    takeLatest(
      notificationsActions.getUnreadNotificationsAction.type,
      getUnreadNotificationCountData,
    ),
    takeLatest(
      notificationsActions.markNotificationAsRead.type,
      markNotificationAsReadSaga,
    ),
    takeLatest(
      notificationsActions.markNotificationAsDelete.type,
      markNotificationAsDeletedSaga,
    ),
    takeLatest(
      notificationsActions.markNotificationAsUnRead.type,
      markNotificationAsUnReadSaga,
    ),
  ]);
}
