import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { CookieService } from 'services/cookie/cookieService';
import Logger from 'services/logging/logger';

/*
 * The following code snippet is used to initialize the Application Insights and ReactGA.
 * The code snippet is used to track events and errors in the application.
 */
const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS as string,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [
      '*.queue.core.windows.net',
      '*.onelogin.com',
      '*.amnhealthcare.com',
    ],
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

/* Initialize Logger with Application Insights */
const logger = new Logger(appInsights);

/* Initialize CookieService with Logger */
CookieService.setLogger(logger);

/* map trackEvent to logger.event */
const trackEvent = logger.event;

export { reactPlugin, appInsights, logger, trackEvent };
